import _ from "lodash";
import React, { useContext } from "react";
import { formatMetric } from "Common/utils/metrics";
import { diffPercentageMetricDef } from "ExtensionV2/components/MetricColumns";
import SimpleTooltip from "ExtensionV2/components/SimpleTooltip";
import { comparisonGreen, comparisonRed } from "ExtensionV2/styles/colors";
import { Table } from "semantic-ui-react";
import styled from "styled-components";
import {
  CompareMetricsLoadingContext,
  ShowFractionsContext
} from "../contexts";
import { MetricsTableCell } from "../columns";

const CompareText = styled.p<{ isLoading: boolean }>`
  font-style: italic;
  opacity: ${props => (props.isLoading ? 0.3 : 1)};
  font-size: small;
`;

const CompareNeutral = styled(CompareText)`
  opacity: 0.6;
`;

const CompareGood = styled(CompareText)`
  font-weight: 500;
  color: ${comparisonGreen};
`;

const CompareBad = styled(CompareText)`
  font-weight: 500;
  color: ${comparisonRed};
`;

export type CompareStyle =
  | typeof CompareGood
  | typeof CompareNeutral
  | typeof CompareBad;

export const lessIsBetter: Array<CompareStyle> = [
  CompareGood,
  CompareNeutral,
  CompareBad
];
export const moreIsBetter: Array<CompareStyle> = [
  CompareBad,
  CompareNeutral,
  CompareGood
];
export const trueNeutral: Array<CompareStyle> = [
  CompareNeutral,
  CompareNeutral,
  CompareNeutral
];

const StyledBasicMetricCell = styled(Table.Cell)`
  > div {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-end;

    p.previous {
      font-style: italic;
    }
  }
`;

export const MetricCell: MetricsTableCell = ({
  uniqKey,
  val,
  prev,
  formatFn,
  compareStyles
}: {
  uniqKey: string;
  val: unknown;
  prev?: unknown;
  formatFn?: (rawVal: unknown, showFraction: boolean) => string;
  compareStyles?: Array<CompareStyle>;
}) => {
  const showFractions = useContext(ShowFractionsContext);

  const formattedVal = formatFn
    ? formatFn(val, showFractions ?? false)
    : String(val);

  return (
    <StyledBasicMetricCell key={uniqKey}>
      <div>
        <p>{formattedVal}</p>
        <CompareAnnotation
          val={val}
          prev={prev}
          showFractions={showFractions}
          formatFn={formatFn}
          compareStyles={compareStyles}
        />
      </div>
    </StyledBasicMetricCell>
  );
};

const CompareAnnotation = ({
  val,
  prev,
  showFractions,
  formatFn,
  compareStyles
}: {
  val: unknown;
  prev: unknown;
  showFractions: boolean;
  formatFn?: (rawVal: unknown, showFraction: boolean) => string;
  compareStyles?: Array<CompareStyle>;
}) => {
  const compareMetricsLoading = useContext(CompareMetricsLoadingContext);

  if (!compareStyles || typeof prev !== "number" || typeof val !== "number") {
    return <></>;
  }

  const diff = val - prev;
  const percentChange = diff / prev;
  const formattedDiff = formatFn ? formatFn(diff, showFractions) : diff;
  const formattedPrev = formatFn ? formatFn(prev, showFractions) : prev;

  let diffString = "";
  let CompareComponent = CompareNeutral;

  if (_.isFinite(percentChange)) {
    diffString = `(${formatMetric(diffPercentageMetricDef, percentChange)})`;
    CompareComponent = compareStyles[Math.sign(percentChange) + 1];
  } else if (_.isNaN(percentChange)) {
    diffString = "(+0%)";
  } else {
    diffString = "(+)";
  }

  return (
    <SimpleTooltip
      tooltip={
        <div>
          Difference: {formattedDiff}
          <br />
          Previous: {formattedPrev}
        </div>
      }
    >
      <CompareComponent isLoading={compareMetricsLoading}>
        {diffString}
      </CompareComponent>
    </SimpleTooltip>
  );
};
