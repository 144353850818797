// source: proto/common/facebook.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

var proto_common_crypto_pb = require('../../proto/common/crypto_pb.js');
goog.object.extend(proto, proto_common_crypto_pb);
goog.exportSymbol('proto.common.Facebook', null, global);
goog.exportSymbol('proto.common.Facebook.API', null, global);
goog.exportSymbol('proto.common.Facebook.API.Ad', null, global);
goog.exportSymbol('proto.common.Facebook.API.AdAccount', null, global);
goog.exportSymbol('proto.common.Facebook.API.AdAccount.AdAccountStatus', null, global);
goog.exportSymbol('proto.common.Facebook.API.AdCreative', null, global);
goog.exportSymbol('proto.common.Facebook.API.AdCreative.CallToAction', null, global);
goog.exportSymbol('proto.common.Facebook.API.AdCreative.CallToActionValue', null, global);
goog.exportSymbol('proto.common.Facebook.API.AdCreative.VideoData', null, global);
goog.exportSymbol('proto.common.Facebook.API.AdEditFields', null, global);
goog.exportSymbol('proto.common.Facebook.API.AdEditMask', null, global);
goog.exportSymbol('proto.common.Facebook.API.AdLabel', null, global);
goog.exportSymbol('proto.common.Facebook.API.AdSet', null, global);
goog.exportSymbol('proto.common.Facebook.API.AdSetEditFields', null, global);
goog.exportSymbol('proto.common.Facebook.API.AdSetEditMask', null, global);
goog.exportSymbol('proto.common.Facebook.API.AssetFeedSpec', null, global);
goog.exportSymbol('proto.common.Facebook.API.AssetFeedSpec.AdFormat', null, global);
goog.exportSymbol('proto.common.Facebook.API.AssetFeedSpec.AdFormat.Option', null, global);
goog.exportSymbol('proto.common.Facebook.API.AssetFeedSpec.Body', null, global);
goog.exportSymbol('proto.common.Facebook.API.AssetFeedSpec.LinkUrl', null, global);
goog.exportSymbol('proto.common.Facebook.API.AssetFeedSpec.Title', null, global);
goog.exportSymbol('proto.common.Facebook.API.Campaign', null, global);
goog.exportSymbol('proto.common.Facebook.API.CampaignEditFields', null, global);
goog.exportSymbol('proto.common.Facebook.API.CampaignEditMask', null, global);
goog.exportSymbol('proto.common.Facebook.API.DatePreset', null, global);
goog.exportSymbol('proto.common.Facebook.API.DatePreset.Option', null, global);
goog.exportSymbol('proto.common.Facebook.API.Insight', null, global);
goog.exportSymbol('proto.common.Facebook.API.Insight.OutboundClicks', null, global);
goog.exportSymbol('proto.common.Facebook.API.InsightLevel', null, global);
goog.exportSymbol('proto.common.Facebook.API.InsightLevel.Option', null, global);
goog.exportSymbol('proto.common.Facebook.API.IssuesInfo', null, global);
goog.exportSymbol('proto.common.Facebook.API.LinkData', null, global);
goog.exportSymbol('proto.common.Facebook.API.ObjectStorySpec', null, global);
goog.exportSymbol('proto.common.Facebook.API.UpdateStatus', null, global);
goog.exportSymbol('proto.common.Facebook.API.UpdateStatus.Option', null, global);
goog.exportSymbol('proto.common.Facebook.Account', null, global);
goog.exportSymbol('proto.common.Facebook.Account.AuthorizedAdAccount', null, global);
goog.exportSymbol('proto.common.Facebook.AuthCode', null, global);
goog.exportSymbol('proto.common.Facebook.TokenValidation', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.Facebook = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.Facebook, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.Facebook.displayName = 'proto.common.Facebook';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.Facebook.AuthCode = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.Facebook.AuthCode, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.Facebook.AuthCode.displayName = 'proto.common.Facebook.AuthCode';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.Facebook.TokenValidation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.common.Facebook.TokenValidation.repeatedFields_, null);
};
goog.inherits(proto.common.Facebook.TokenValidation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.Facebook.TokenValidation.displayName = 'proto.common.Facebook.TokenValidation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.Facebook.Account = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.common.Facebook.Account.repeatedFields_, null);
};
goog.inherits(proto.common.Facebook.Account, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.Facebook.Account.displayName = 'proto.common.Facebook.Account';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.Facebook.Account.AuthorizedAdAccount = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.Facebook.Account.AuthorizedAdAccount, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.Facebook.Account.AuthorizedAdAccount.displayName = 'proto.common.Facebook.Account.AuthorizedAdAccount';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.Facebook.API = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.Facebook.API, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.Facebook.API.displayName = 'proto.common.Facebook.API';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.Facebook.API.AdAccount = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.Facebook.API.AdAccount, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.Facebook.API.AdAccount.displayName = 'proto.common.Facebook.API.AdAccount';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.Facebook.API.Campaign = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.common.Facebook.API.Campaign.repeatedFields_, null);
};
goog.inherits(proto.common.Facebook.API.Campaign, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.Facebook.API.Campaign.displayName = 'proto.common.Facebook.API.Campaign';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.Facebook.API.CampaignEditFields = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.Facebook.API.CampaignEditFields, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.Facebook.API.CampaignEditFields.displayName = 'proto.common.Facebook.API.CampaignEditFields';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.Facebook.API.CampaignEditMask = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.Facebook.API.CampaignEditMask, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.Facebook.API.CampaignEditMask.displayName = 'proto.common.Facebook.API.CampaignEditMask';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.Facebook.API.AdSet = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.common.Facebook.API.AdSet.repeatedFields_, null);
};
goog.inherits(proto.common.Facebook.API.AdSet, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.Facebook.API.AdSet.displayName = 'proto.common.Facebook.API.AdSet';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.Facebook.API.AdSetEditFields = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.Facebook.API.AdSetEditFields, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.Facebook.API.AdSetEditFields.displayName = 'proto.common.Facebook.API.AdSetEditFields';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.Facebook.API.AdSetEditMask = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.Facebook.API.AdSetEditMask, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.Facebook.API.AdSetEditMask.displayName = 'proto.common.Facebook.API.AdSetEditMask';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.Facebook.API.Ad = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.common.Facebook.API.Ad.repeatedFields_, null);
};
goog.inherits(proto.common.Facebook.API.Ad, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.Facebook.API.Ad.displayName = 'proto.common.Facebook.API.Ad';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.Facebook.API.AdEditFields = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.Facebook.API.AdEditFields, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.Facebook.API.AdEditFields.displayName = 'proto.common.Facebook.API.AdEditFields';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.Facebook.API.AdEditMask = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.Facebook.API.AdEditMask, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.Facebook.API.AdEditMask.displayName = 'proto.common.Facebook.API.AdEditMask';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.Facebook.API.AdCreative = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.common.Facebook.API.AdCreative.repeatedFields_, null);
};
goog.inherits(proto.common.Facebook.API.AdCreative, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.Facebook.API.AdCreative.displayName = 'proto.common.Facebook.API.AdCreative';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.Facebook.API.AdCreative.VideoData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.Facebook.API.AdCreative.VideoData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.Facebook.API.AdCreative.VideoData.displayName = 'proto.common.Facebook.API.AdCreative.VideoData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.Facebook.API.AdCreative.CallToAction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.Facebook.API.AdCreative.CallToAction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.Facebook.API.AdCreative.CallToAction.displayName = 'proto.common.Facebook.API.AdCreative.CallToAction';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.Facebook.API.AdCreative.CallToActionValue = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.Facebook.API.AdCreative.CallToActionValue, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.Facebook.API.AdCreative.CallToActionValue.displayName = 'proto.common.Facebook.API.AdCreative.CallToActionValue';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.Facebook.API.AdLabel = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.Facebook.API.AdLabel, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.Facebook.API.AdLabel.displayName = 'proto.common.Facebook.API.AdLabel';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.Facebook.API.IssuesInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.Facebook.API.IssuesInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.Facebook.API.IssuesInfo.displayName = 'proto.common.Facebook.API.IssuesInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.Facebook.API.Insight = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.common.Facebook.API.Insight.repeatedFields_, null);
};
goog.inherits(proto.common.Facebook.API.Insight, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.Facebook.API.Insight.displayName = 'proto.common.Facebook.API.Insight';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.Facebook.API.Insight.OutboundClicks = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.Facebook.API.Insight.OutboundClicks, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.Facebook.API.Insight.OutboundClicks.displayName = 'proto.common.Facebook.API.Insight.OutboundClicks';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.Facebook.API.InsightLevel = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.Facebook.API.InsightLevel, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.Facebook.API.InsightLevel.displayName = 'proto.common.Facebook.API.InsightLevel';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.Facebook.API.DatePreset = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.Facebook.API.DatePreset, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.Facebook.API.DatePreset.displayName = 'proto.common.Facebook.API.DatePreset';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.Facebook.API.AssetFeedSpec = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.common.Facebook.API.AssetFeedSpec.repeatedFields_, null);
};
goog.inherits(proto.common.Facebook.API.AssetFeedSpec, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.Facebook.API.AssetFeedSpec.displayName = 'proto.common.Facebook.API.AssetFeedSpec';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.Facebook.API.AssetFeedSpec.AdFormat = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.Facebook.API.AssetFeedSpec.AdFormat, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.Facebook.API.AssetFeedSpec.AdFormat.displayName = 'proto.common.Facebook.API.AssetFeedSpec.AdFormat';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.Facebook.API.AssetFeedSpec.Body = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.common.Facebook.API.AssetFeedSpec.Body.repeatedFields_, null);
};
goog.inherits(proto.common.Facebook.API.AssetFeedSpec.Body, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.Facebook.API.AssetFeedSpec.Body.displayName = 'proto.common.Facebook.API.AssetFeedSpec.Body';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.Facebook.API.AssetFeedSpec.LinkUrl = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.common.Facebook.API.AssetFeedSpec.LinkUrl.repeatedFields_, null);
};
goog.inherits(proto.common.Facebook.API.AssetFeedSpec.LinkUrl, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.Facebook.API.AssetFeedSpec.LinkUrl.displayName = 'proto.common.Facebook.API.AssetFeedSpec.LinkUrl';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.Facebook.API.AssetFeedSpec.Title = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.common.Facebook.API.AssetFeedSpec.Title.repeatedFields_, null);
};
goog.inherits(proto.common.Facebook.API.AssetFeedSpec.Title, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.Facebook.API.AssetFeedSpec.Title.displayName = 'proto.common.Facebook.API.AssetFeedSpec.Title';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.Facebook.API.ObjectStorySpec = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.Facebook.API.ObjectStorySpec, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.Facebook.API.ObjectStorySpec.displayName = 'proto.common.Facebook.API.ObjectStorySpec';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.Facebook.API.LinkData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.Facebook.API.LinkData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.Facebook.API.LinkData.displayName = 'proto.common.Facebook.API.LinkData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.Facebook.API.UpdateStatus = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.Facebook.API.UpdateStatus, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.Facebook.API.UpdateStatus.displayName = 'proto.common.Facebook.API.UpdateStatus';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.Facebook.prototype.toObject = function(opt_includeInstance) {
  return proto.common.Facebook.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.Facebook} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Facebook.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.Facebook}
 */
proto.common.Facebook.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.Facebook;
  return proto.common.Facebook.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.Facebook} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.Facebook}
 */
proto.common.Facebook.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.Facebook.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.Facebook.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.Facebook} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Facebook.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.Facebook.AuthCode.prototype.toObject = function(opt_includeInstance) {
  return proto.common.Facebook.AuthCode.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.Facebook.AuthCode} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Facebook.AuthCode.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.Facebook.AuthCode}
 */
proto.common.Facebook.AuthCode.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.Facebook.AuthCode;
  return proto.common.Facebook.AuthCode.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.Facebook.AuthCode} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.Facebook.AuthCode}
 */
proto.common.Facebook.AuthCode.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.Facebook.AuthCode.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.Facebook.AuthCode.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.Facebook.AuthCode} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Facebook.AuthCode.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string code = 1;
 * @return {string}
 */
proto.common.Facebook.AuthCode.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Facebook.AuthCode} returns this
 */
proto.common.Facebook.AuthCode.prototype.setCode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.common.Facebook.TokenValidation.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.Facebook.TokenValidation.prototype.toObject = function(opt_includeInstance) {
  return proto.common.Facebook.TokenValidation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.Facebook.TokenValidation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Facebook.TokenValidation.toObject = function(includeInstance, msg) {
  var f, obj = {
    valid: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    userId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    scopesList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    issuedAt: jspb.Message.getFieldWithDefault(msg, 4, 0),
    expiresAt: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.Facebook.TokenValidation}
 */
proto.common.Facebook.TokenValidation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.Facebook.TokenValidation;
  return proto.common.Facebook.TokenValidation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.Facebook.TokenValidation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.Facebook.TokenValidation}
 */
proto.common.Facebook.TokenValidation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setValid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addScopes(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setIssuedAt(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setExpiresAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.Facebook.TokenValidation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.Facebook.TokenValidation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.Facebook.TokenValidation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Facebook.TokenValidation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValid();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getScopesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
  f = message.getIssuedAt();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getExpiresAt();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
};


/**
 * optional bool valid = 1;
 * @return {boolean}
 */
proto.common.Facebook.TokenValidation.prototype.getValid = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.common.Facebook.TokenValidation} returns this
 */
proto.common.Facebook.TokenValidation.prototype.setValid = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string user_id = 2;
 * @return {string}
 */
proto.common.Facebook.TokenValidation.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Facebook.TokenValidation} returns this
 */
proto.common.Facebook.TokenValidation.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated string scopes = 3;
 * @return {!Array<string>}
 */
proto.common.Facebook.TokenValidation.prototype.getScopesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.common.Facebook.TokenValidation} returns this
 */
proto.common.Facebook.TokenValidation.prototype.setScopesList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.common.Facebook.TokenValidation} returns this
 */
proto.common.Facebook.TokenValidation.prototype.addScopes = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.common.Facebook.TokenValidation} returns this
 */
proto.common.Facebook.TokenValidation.prototype.clearScopesList = function() {
  return this.setScopesList([]);
};


/**
 * optional int64 issued_at = 4;
 * @return {number}
 */
proto.common.Facebook.TokenValidation.prototype.getIssuedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.common.Facebook.TokenValidation} returns this
 */
proto.common.Facebook.TokenValidation.prototype.setIssuedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int64 expires_at = 5;
 * @return {number}
 */
proto.common.Facebook.TokenValidation.prototype.getExpiresAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.common.Facebook.TokenValidation} returns this
 */
proto.common.Facebook.TokenValidation.prototype.setExpiresAt = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.common.Facebook.Account.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.Facebook.Account.prototype.toObject = function(opt_includeInstance) {
  return proto.common.Facebook.Account.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.Facebook.Account} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Facebook.Account.toObject = function(includeInstance, msg) {
  var f, obj = {
    authToken: (f = msg.getAuthToken()) && proto_common_crypto_pb.EncryptedData.toObject(includeInstance, f),
    authorizedAdAccountsList: jspb.Message.toObjectList(msg.getAuthorizedAdAccountsList(),
    proto.common.Facebook.Account.AuthorizedAdAccount.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.Facebook.Account}
 */
proto.common.Facebook.Account.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.Facebook.Account;
  return proto.common.Facebook.Account.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.Facebook.Account} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.Facebook.Account}
 */
proto.common.Facebook.Account.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_common_crypto_pb.EncryptedData;
      reader.readMessage(value,proto_common_crypto_pb.EncryptedData.deserializeBinaryFromReader);
      msg.setAuthToken(value);
      break;
    case 2:
      var value = new proto.common.Facebook.Account.AuthorizedAdAccount;
      reader.readMessage(value,proto.common.Facebook.Account.AuthorizedAdAccount.deserializeBinaryFromReader);
      msg.addAuthorizedAdAccounts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.Facebook.Account.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.Facebook.Account.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.Facebook.Account} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Facebook.Account.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuthToken();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_common_crypto_pb.EncryptedData.serializeBinaryToWriter
    );
  }
  f = message.getAuthorizedAdAccountsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.common.Facebook.Account.AuthorizedAdAccount.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.Facebook.Account.AuthorizedAdAccount.prototype.toObject = function(opt_includeInstance) {
  return proto.common.Facebook.Account.AuthorizedAdAccount.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.Facebook.Account.AuthorizedAdAccount} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Facebook.Account.AuthorizedAdAccount.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.Facebook.Account.AuthorizedAdAccount}
 */
proto.common.Facebook.Account.AuthorizedAdAccount.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.Facebook.Account.AuthorizedAdAccount;
  return proto.common.Facebook.Account.AuthorizedAdAccount.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.Facebook.Account.AuthorizedAdAccount} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.Facebook.Account.AuthorizedAdAccount}
 */
proto.common.Facebook.Account.AuthorizedAdAccount.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.Facebook.Account.AuthorizedAdAccount.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.Facebook.Account.AuthorizedAdAccount.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.Facebook.Account.AuthorizedAdAccount} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Facebook.Account.AuthorizedAdAccount.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.common.Facebook.Account.AuthorizedAdAccount.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Facebook.Account.AuthorizedAdAccount} returns this
 */
proto.common.Facebook.Account.AuthorizedAdAccount.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.common.Facebook.Account.AuthorizedAdAccount.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Facebook.Account.AuthorizedAdAccount} returns this
 */
proto.common.Facebook.Account.AuthorizedAdAccount.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional EncryptedData auth_token = 1;
 * @return {?proto.common.EncryptedData}
 */
proto.common.Facebook.Account.prototype.getAuthToken = function() {
  return /** @type{?proto.common.EncryptedData} */ (
    jspb.Message.getWrapperField(this, proto_common_crypto_pb.EncryptedData, 1));
};


/**
 * @param {?proto.common.EncryptedData|undefined} value
 * @return {!proto.common.Facebook.Account} returns this
*/
proto.common.Facebook.Account.prototype.setAuthToken = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.Facebook.Account} returns this
 */
proto.common.Facebook.Account.prototype.clearAuthToken = function() {
  return this.setAuthToken(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.Facebook.Account.prototype.hasAuthToken = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated AuthorizedAdAccount authorized_ad_accounts = 2;
 * @return {!Array<!proto.common.Facebook.Account.AuthorizedAdAccount>}
 */
proto.common.Facebook.Account.prototype.getAuthorizedAdAccountsList = function() {
  return /** @type{!Array<!proto.common.Facebook.Account.AuthorizedAdAccount>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.common.Facebook.Account.AuthorizedAdAccount, 2));
};


/**
 * @param {!Array<!proto.common.Facebook.Account.AuthorizedAdAccount>} value
 * @return {!proto.common.Facebook.Account} returns this
*/
proto.common.Facebook.Account.prototype.setAuthorizedAdAccountsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.common.Facebook.Account.AuthorizedAdAccount=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.Facebook.Account.AuthorizedAdAccount}
 */
proto.common.Facebook.Account.prototype.addAuthorizedAdAccounts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.common.Facebook.Account.AuthorizedAdAccount, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.common.Facebook.Account} returns this
 */
proto.common.Facebook.Account.prototype.clearAuthorizedAdAccountsList = function() {
  return this.setAuthorizedAdAccountsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.Facebook.API.prototype.toObject = function(opt_includeInstance) {
  return proto.common.Facebook.API.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.Facebook.API} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Facebook.API.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.Facebook.API}
 */
proto.common.Facebook.API.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.Facebook.API;
  return proto.common.Facebook.API.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.Facebook.API} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.Facebook.API}
 */
proto.common.Facebook.API.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.Facebook.API.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.Facebook.API.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.Facebook.API} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Facebook.API.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.Facebook.API.AdAccount.prototype.toObject = function(opt_includeInstance) {
  return proto.common.Facebook.API.AdAccount.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.Facebook.API.AdAccount} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Facebook.API.AdAccount.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    accountId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    accountStatus: jspb.Message.getFieldWithDefault(msg, 3, 0),
    age: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    amountSpent: jspb.Message.getFieldWithDefault(msg, 5, ""),
    balance: jspb.Message.getFieldWithDefault(msg, 6, ""),
    createdTime: jspb.Message.getFieldWithDefault(msg, 7, ""),
    currency: jspb.Message.getFieldWithDefault(msg, 8, ""),
    endAdvertiser: jspb.Message.getFieldWithDefault(msg, 9, ""),
    endAdvertiserName: jspb.Message.getFieldWithDefault(msg, 10, ""),
    name: jspb.Message.getFieldWithDefault(msg, 11, ""),
    spendCap: jspb.Message.getFieldWithDefault(msg, 12, ""),
    timezoneName: jspb.Message.getFieldWithDefault(msg, 13, ""),
    timezoneOffsetHoursUtc: jspb.Message.getFloatingPointFieldWithDefault(msg, 14, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.Facebook.API.AdAccount}
 */
proto.common.Facebook.API.AdAccount.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.Facebook.API.AdAccount;
  return proto.common.Facebook.API.AdAccount.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.Facebook.API.AdAccount} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.Facebook.API.AdAccount}
 */
proto.common.Facebook.API.AdAccount.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountId(value);
      break;
    case 3:
      var value = /** @type {!proto.common.Facebook.API.AdAccount.AdAccountStatus} */ (reader.readEnum());
      msg.setAccountStatus(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setAge(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setAmountSpent(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setBalance(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedTime(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrency(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setEndAdvertiser(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setEndAdvertiserName(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setSpendCap(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setTimezoneName(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTimezoneOffsetHoursUtc(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.Facebook.API.AdAccount.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.Facebook.API.AdAccount.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.Facebook.API.AdAccount} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Facebook.API.AdAccount.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAccountId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAccountStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getAge();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
  f = message.getAmountSpent();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getBalance();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getCreatedTime();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getCurrency();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getEndAdvertiser();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getEndAdvertiserName();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getSpendCap();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getTimezoneName();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getTimezoneOffsetHoursUtc();
  if (f !== 0.0) {
    writer.writeFloat(
      14,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.common.Facebook.API.AdAccount.AdAccountStatus = {
  UNKNOWN: 0,
  ACTIVE: 1,
  DISABLED: 2,
  UNSETTLED: 3,
  PENDING_RISK_REVIEW: 7,
  PENDING_SETTLEMENT: 8,
  IN_GRACE_PERIOD: 9,
  PENDING_CLOSURE: 100,
  CLOSED: 101,
  ANY_ACTIVE: 201,
  ANY_CLOSED: 202
};

/**
 * optional string id = 1;
 * @return {string}
 */
proto.common.Facebook.API.AdAccount.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Facebook.API.AdAccount} returns this
 */
proto.common.Facebook.API.AdAccount.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string account_id = 2;
 * @return {string}
 */
proto.common.Facebook.API.AdAccount.prototype.getAccountId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Facebook.API.AdAccount} returns this
 */
proto.common.Facebook.API.AdAccount.prototype.setAccountId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional AdAccountStatus account_status = 3;
 * @return {!proto.common.Facebook.API.AdAccount.AdAccountStatus}
 */
proto.common.Facebook.API.AdAccount.prototype.getAccountStatus = function() {
  return /** @type {!proto.common.Facebook.API.AdAccount.AdAccountStatus} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.common.Facebook.API.AdAccount.AdAccountStatus} value
 * @return {!proto.common.Facebook.API.AdAccount} returns this
 */
proto.common.Facebook.API.AdAccount.prototype.setAccountStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional float age = 4;
 * @return {number}
 */
proto.common.Facebook.API.AdAccount.prototype.getAge = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.common.Facebook.API.AdAccount} returns this
 */
proto.common.Facebook.API.AdAccount.prototype.setAge = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional string amount_spent = 5;
 * @return {string}
 */
proto.common.Facebook.API.AdAccount.prototype.getAmountSpent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Facebook.API.AdAccount} returns this
 */
proto.common.Facebook.API.AdAccount.prototype.setAmountSpent = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string balance = 6;
 * @return {string}
 */
proto.common.Facebook.API.AdAccount.prototype.getBalance = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Facebook.API.AdAccount} returns this
 */
proto.common.Facebook.API.AdAccount.prototype.setBalance = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string created_time = 7;
 * @return {string}
 */
proto.common.Facebook.API.AdAccount.prototype.getCreatedTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Facebook.API.AdAccount} returns this
 */
proto.common.Facebook.API.AdAccount.prototype.setCreatedTime = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string currency = 8;
 * @return {string}
 */
proto.common.Facebook.API.AdAccount.prototype.getCurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Facebook.API.AdAccount} returns this
 */
proto.common.Facebook.API.AdAccount.prototype.setCurrency = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string end_advertiser = 9;
 * @return {string}
 */
proto.common.Facebook.API.AdAccount.prototype.getEndAdvertiser = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Facebook.API.AdAccount} returns this
 */
proto.common.Facebook.API.AdAccount.prototype.setEndAdvertiser = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string end_advertiser_name = 10;
 * @return {string}
 */
proto.common.Facebook.API.AdAccount.prototype.getEndAdvertiserName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Facebook.API.AdAccount} returns this
 */
proto.common.Facebook.API.AdAccount.prototype.setEndAdvertiserName = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string name = 11;
 * @return {string}
 */
proto.common.Facebook.API.AdAccount.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Facebook.API.AdAccount} returns this
 */
proto.common.Facebook.API.AdAccount.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string spend_cap = 12;
 * @return {string}
 */
proto.common.Facebook.API.AdAccount.prototype.getSpendCap = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Facebook.API.AdAccount} returns this
 */
proto.common.Facebook.API.AdAccount.prototype.setSpendCap = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string timezone_name = 13;
 * @return {string}
 */
proto.common.Facebook.API.AdAccount.prototype.getTimezoneName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Facebook.API.AdAccount} returns this
 */
proto.common.Facebook.API.AdAccount.prototype.setTimezoneName = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional float timezone_offset_hours_utc = 14;
 * @return {number}
 */
proto.common.Facebook.API.AdAccount.prototype.getTimezoneOffsetHoursUtc = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 14, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.common.Facebook.API.AdAccount} returns this
 */
proto.common.Facebook.API.AdAccount.prototype.setTimezoneOffsetHoursUtc = function(value) {
  return jspb.Message.setProto3FloatField(this, 14, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.common.Facebook.API.Campaign.repeatedFields_ = [3,9];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.Facebook.API.Campaign.prototype.toObject = function(opt_includeInstance) {
  return proto.common.Facebook.API.Campaign.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.Facebook.API.Campaign} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Facebook.API.Campaign.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    accountId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    adLabelsList: jspb.Message.toObjectList(msg.getAdLabelsList(),
    proto.common.Facebook.API.AdLabel.toObject, includeInstance),
    bidStrategy: jspb.Message.getFieldWithDefault(msg, 4, ""),
    budgetRemaining: jspb.Message.getFieldWithDefault(msg, 5, ""),
    createdTime: jspb.Message.getFieldWithDefault(msg, 6, ""),
    dailyBudget: jspb.Message.getFieldWithDefault(msg, 7, ""),
    effectiveStatus: jspb.Message.getFieldWithDefault(msg, 8, ""),
    issuesList: jspb.Message.toObjectList(msg.getIssuesList(),
    proto.common.Facebook.API.IssuesInfo.toObject, includeInstance),
    lifetimeBudget: jspb.Message.getFieldWithDefault(msg, 10, ""),
    name: jspb.Message.getFieldWithDefault(msg, 11, ""),
    objective: jspb.Message.getFieldWithDefault(msg, 12, ""),
    spendCap: jspb.Message.getFieldWithDefault(msg, 13, ""),
    startTime: jspb.Message.getFieldWithDefault(msg, 14, ""),
    status: jspb.Message.getFieldWithDefault(msg, 15, ""),
    stopTime: jspb.Message.getFieldWithDefault(msg, 16, ""),
    updatedTime: jspb.Message.getFieldWithDefault(msg, 17, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.Facebook.API.Campaign}
 */
proto.common.Facebook.API.Campaign.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.Facebook.API.Campaign;
  return proto.common.Facebook.API.Campaign.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.Facebook.API.Campaign} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.Facebook.API.Campaign}
 */
proto.common.Facebook.API.Campaign.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountId(value);
      break;
    case 3:
      var value = new proto.common.Facebook.API.AdLabel;
      reader.readMessage(value,proto.common.Facebook.API.AdLabel.deserializeBinaryFromReader);
      msg.addAdLabels(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setBidStrategy(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setBudgetRemaining(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedTime(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setDailyBudget(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setEffectiveStatus(value);
      break;
    case 9:
      var value = new proto.common.Facebook.API.IssuesInfo;
      reader.readMessage(value,proto.common.Facebook.API.IssuesInfo.deserializeBinaryFromReader);
      msg.addIssues(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setLifetimeBudget(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setObjective(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setSpendCap(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setStartTime(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setStopTime(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setUpdatedTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.Facebook.API.Campaign.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.Facebook.API.Campaign.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.Facebook.API.Campaign} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Facebook.API.Campaign.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAccountId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAdLabelsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.common.Facebook.API.AdLabel.serializeBinaryToWriter
    );
  }
  f = message.getBidStrategy();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getBudgetRemaining();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCreatedTime();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getDailyBudget();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getEffectiveStatus();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getIssuesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      proto.common.Facebook.API.IssuesInfo.serializeBinaryToWriter
    );
  }
  f = message.getLifetimeBudget();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getObjective();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getSpendCap();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getStartTime();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getStopTime();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getUpdatedTime();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.common.Facebook.API.Campaign.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Facebook.API.Campaign} returns this
 */
proto.common.Facebook.API.Campaign.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string account_id = 2;
 * @return {string}
 */
proto.common.Facebook.API.Campaign.prototype.getAccountId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Facebook.API.Campaign} returns this
 */
proto.common.Facebook.API.Campaign.prototype.setAccountId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated AdLabel ad_labels = 3;
 * @return {!Array<!proto.common.Facebook.API.AdLabel>}
 */
proto.common.Facebook.API.Campaign.prototype.getAdLabelsList = function() {
  return /** @type{!Array<!proto.common.Facebook.API.AdLabel>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.common.Facebook.API.AdLabel, 3));
};


/**
 * @param {!Array<!proto.common.Facebook.API.AdLabel>} value
 * @return {!proto.common.Facebook.API.Campaign} returns this
*/
proto.common.Facebook.API.Campaign.prototype.setAdLabelsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.common.Facebook.API.AdLabel=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.Facebook.API.AdLabel}
 */
proto.common.Facebook.API.Campaign.prototype.addAdLabels = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.common.Facebook.API.AdLabel, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.common.Facebook.API.Campaign} returns this
 */
proto.common.Facebook.API.Campaign.prototype.clearAdLabelsList = function() {
  return this.setAdLabelsList([]);
};


/**
 * optional string bid_strategy = 4;
 * @return {string}
 */
proto.common.Facebook.API.Campaign.prototype.getBidStrategy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Facebook.API.Campaign} returns this
 */
proto.common.Facebook.API.Campaign.prototype.setBidStrategy = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string budget_remaining = 5;
 * @return {string}
 */
proto.common.Facebook.API.Campaign.prototype.getBudgetRemaining = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Facebook.API.Campaign} returns this
 */
proto.common.Facebook.API.Campaign.prototype.setBudgetRemaining = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string created_time = 6;
 * @return {string}
 */
proto.common.Facebook.API.Campaign.prototype.getCreatedTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Facebook.API.Campaign} returns this
 */
proto.common.Facebook.API.Campaign.prototype.setCreatedTime = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string daily_budget = 7;
 * @return {string}
 */
proto.common.Facebook.API.Campaign.prototype.getDailyBudget = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Facebook.API.Campaign} returns this
 */
proto.common.Facebook.API.Campaign.prototype.setDailyBudget = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string effective_status = 8;
 * @return {string}
 */
proto.common.Facebook.API.Campaign.prototype.getEffectiveStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Facebook.API.Campaign} returns this
 */
proto.common.Facebook.API.Campaign.prototype.setEffectiveStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * repeated IssuesInfo issues = 9;
 * @return {!Array<!proto.common.Facebook.API.IssuesInfo>}
 */
proto.common.Facebook.API.Campaign.prototype.getIssuesList = function() {
  return /** @type{!Array<!proto.common.Facebook.API.IssuesInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.common.Facebook.API.IssuesInfo, 9));
};


/**
 * @param {!Array<!proto.common.Facebook.API.IssuesInfo>} value
 * @return {!proto.common.Facebook.API.Campaign} returns this
*/
proto.common.Facebook.API.Campaign.prototype.setIssuesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.common.Facebook.API.IssuesInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.Facebook.API.IssuesInfo}
 */
proto.common.Facebook.API.Campaign.prototype.addIssues = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.common.Facebook.API.IssuesInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.common.Facebook.API.Campaign} returns this
 */
proto.common.Facebook.API.Campaign.prototype.clearIssuesList = function() {
  return this.setIssuesList([]);
};


/**
 * optional string lifetime_budget = 10;
 * @return {string}
 */
proto.common.Facebook.API.Campaign.prototype.getLifetimeBudget = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Facebook.API.Campaign} returns this
 */
proto.common.Facebook.API.Campaign.prototype.setLifetimeBudget = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string name = 11;
 * @return {string}
 */
proto.common.Facebook.API.Campaign.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Facebook.API.Campaign} returns this
 */
proto.common.Facebook.API.Campaign.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string objective = 12;
 * @return {string}
 */
proto.common.Facebook.API.Campaign.prototype.getObjective = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Facebook.API.Campaign} returns this
 */
proto.common.Facebook.API.Campaign.prototype.setObjective = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string spend_cap = 13;
 * @return {string}
 */
proto.common.Facebook.API.Campaign.prototype.getSpendCap = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Facebook.API.Campaign} returns this
 */
proto.common.Facebook.API.Campaign.prototype.setSpendCap = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string start_time = 14;
 * @return {string}
 */
proto.common.Facebook.API.Campaign.prototype.getStartTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Facebook.API.Campaign} returns this
 */
proto.common.Facebook.API.Campaign.prototype.setStartTime = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string status = 15;
 * @return {string}
 */
proto.common.Facebook.API.Campaign.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Facebook.API.Campaign} returns this
 */
proto.common.Facebook.API.Campaign.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string stop_time = 16;
 * @return {string}
 */
proto.common.Facebook.API.Campaign.prototype.getStopTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Facebook.API.Campaign} returns this
 */
proto.common.Facebook.API.Campaign.prototype.setStopTime = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional string updated_time = 17;
 * @return {string}
 */
proto.common.Facebook.API.Campaign.prototype.getUpdatedTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Facebook.API.Campaign} returns this
 */
proto.common.Facebook.API.Campaign.prototype.setUpdatedTime = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.Facebook.API.CampaignEditFields.prototype.toObject = function(opt_includeInstance) {
  return proto.common.Facebook.API.CampaignEditFields.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.Facebook.API.CampaignEditFields} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Facebook.API.CampaignEditFields.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: jspb.Message.getFieldWithDefault(msg, 1, 0),
    dailyBudget: jspb.Message.getFieldWithDefault(msg, 2, ""),
    lifetimeBudget: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.Facebook.API.CampaignEditFields}
 */
proto.common.Facebook.API.CampaignEditFields.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.Facebook.API.CampaignEditFields;
  return proto.common.Facebook.API.CampaignEditFields.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.Facebook.API.CampaignEditFields} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.Facebook.API.CampaignEditFields}
 */
proto.common.Facebook.API.CampaignEditFields.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.common.Facebook.API.UpdateStatus.Option} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDailyBudget(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLifetimeBudget(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.Facebook.API.CampaignEditFields.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.Facebook.API.CampaignEditFields.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.Facebook.API.CampaignEditFields} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Facebook.API.CampaignEditFields.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getDailyBudget();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLifetimeBudget();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional UpdateStatus.Option status = 1;
 * @return {!proto.common.Facebook.API.UpdateStatus.Option}
 */
proto.common.Facebook.API.CampaignEditFields.prototype.getStatus = function() {
  return /** @type {!proto.common.Facebook.API.UpdateStatus.Option} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.common.Facebook.API.UpdateStatus.Option} value
 * @return {!proto.common.Facebook.API.CampaignEditFields} returns this
 */
proto.common.Facebook.API.CampaignEditFields.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string daily_budget = 2;
 * @return {string}
 */
proto.common.Facebook.API.CampaignEditFields.prototype.getDailyBudget = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Facebook.API.CampaignEditFields} returns this
 */
proto.common.Facebook.API.CampaignEditFields.prototype.setDailyBudget = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string lifetime_budget = 3;
 * @return {string}
 */
proto.common.Facebook.API.CampaignEditFields.prototype.getLifetimeBudget = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Facebook.API.CampaignEditFields} returns this
 */
proto.common.Facebook.API.CampaignEditFields.prototype.setLifetimeBudget = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.Facebook.API.CampaignEditMask.prototype.toObject = function(opt_includeInstance) {
  return proto.common.Facebook.API.CampaignEditMask.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.Facebook.API.CampaignEditMask} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Facebook.API.CampaignEditMask.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    dailyBudget: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    lifetimeBudget: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.Facebook.API.CampaignEditMask}
 */
proto.common.Facebook.API.CampaignEditMask.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.Facebook.API.CampaignEditMask;
  return proto.common.Facebook.API.CampaignEditMask.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.Facebook.API.CampaignEditMask} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.Facebook.API.CampaignEditMask}
 */
proto.common.Facebook.API.CampaignEditMask.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setStatus(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDailyBudget(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setLifetimeBudget(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.Facebook.API.CampaignEditMask.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.Facebook.API.CampaignEditMask.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.Facebook.API.CampaignEditMask} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Facebook.API.CampaignEditMask.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getDailyBudget();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getLifetimeBudget();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional bool status = 1;
 * @return {boolean}
 */
proto.common.Facebook.API.CampaignEditMask.prototype.getStatus = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.common.Facebook.API.CampaignEditMask} returns this
 */
proto.common.Facebook.API.CampaignEditMask.prototype.setStatus = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional bool daily_budget = 2;
 * @return {boolean}
 */
proto.common.Facebook.API.CampaignEditMask.prototype.getDailyBudget = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.common.Facebook.API.CampaignEditMask} returns this
 */
proto.common.Facebook.API.CampaignEditMask.prototype.setDailyBudget = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional bool lifetime_budget = 3;
 * @return {boolean}
 */
proto.common.Facebook.API.CampaignEditMask.prototype.getLifetimeBudget = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.common.Facebook.API.CampaignEditMask} returns this
 */
proto.common.Facebook.API.CampaignEditMask.prototype.setLifetimeBudget = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.common.Facebook.API.AdSet.repeatedFields_ = [3,17];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.Facebook.API.AdSet.prototype.toObject = function(opt_includeInstance) {
  return proto.common.Facebook.API.AdSet.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.Facebook.API.AdSet} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Facebook.API.AdSet.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    accountId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    adLabelsList: jspb.Message.toObjectList(msg.getAdLabelsList(),
    proto.common.Facebook.API.AdLabel.toObject, includeInstance),
    bidAmount: jspb.Message.getFieldWithDefault(msg, 4, 0),
    bidInfoMap: (f = msg.getBidInfoMap()) ? f.toObject(includeInstance, undefined) : [],
    bidStrategy: jspb.Message.getFieldWithDefault(msg, 6, ""),
    billingEvent: jspb.Message.getFieldWithDefault(msg, 7, ""),
    budgetRemaining: jspb.Message.getFieldWithDefault(msg, 8, ""),
    campaignId: jspb.Message.getFieldWithDefault(msg, 9, ""),
    createdTime: jspb.Message.getFieldWithDefault(msg, 10, ""),
    dailyBudget: jspb.Message.getFieldWithDefault(msg, 11, ""),
    dailyMinSpendTarget: jspb.Message.getFieldWithDefault(msg, 12, ""),
    dailySpendCap: jspb.Message.getFieldWithDefault(msg, 13, ""),
    destinationType: jspb.Message.getFieldWithDefault(msg, 14, ""),
    effectiveStatus: jspb.Message.getFieldWithDefault(msg, 15, ""),
    endTime: jspb.Message.getFieldWithDefault(msg, 16, ""),
    issuesList: jspb.Message.toObjectList(msg.getIssuesList(),
    proto.common.Facebook.API.IssuesInfo.toObject, includeInstance),
    lifetimeBudget: jspb.Message.getFieldWithDefault(msg, 18, ""),
    lifetimeMinSpendTarget: jspb.Message.getFieldWithDefault(msg, 19, ""),
    lifetimeSpendCap: jspb.Message.getFieldWithDefault(msg, 20, ""),
    name: jspb.Message.getFieldWithDefault(msg, 21, ""),
    optimizationGoal: jspb.Message.getFieldWithDefault(msg, 22, ""),
    startTime: jspb.Message.getFieldWithDefault(msg, 23, ""),
    status: jspb.Message.getFieldWithDefault(msg, 24, ""),
    updatedTime: jspb.Message.getFieldWithDefault(msg, 25, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.Facebook.API.AdSet}
 */
proto.common.Facebook.API.AdSet.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.Facebook.API.AdSet;
  return proto.common.Facebook.API.AdSet.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.Facebook.API.AdSet} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.Facebook.API.AdSet}
 */
proto.common.Facebook.API.AdSet.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountId(value);
      break;
    case 3:
      var value = new proto.common.Facebook.API.AdLabel;
      reader.readMessage(value,proto.common.Facebook.API.AdLabel.deserializeBinaryFromReader);
      msg.addAdLabels(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBidAmount(value);
      break;
    case 5:
      var value = msg.getBidInfoMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readInt64, null, "", 0);
         });
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setBidStrategy(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setBillingEvent(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setBudgetRemaining(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setCampaignId(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedTime(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setDailyBudget(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setDailyMinSpendTarget(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setDailySpendCap(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setDestinationType(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setEffectiveStatus(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setEndTime(value);
      break;
    case 17:
      var value = new proto.common.Facebook.API.IssuesInfo;
      reader.readMessage(value,proto.common.Facebook.API.IssuesInfo.deserializeBinaryFromReader);
      msg.addIssues(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setLifetimeBudget(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setLifetimeMinSpendTarget(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setLifetimeSpendCap(value);
      break;
    case 21:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 22:
      var value = /** @type {string} */ (reader.readString());
      msg.setOptimizationGoal(value);
      break;
    case 23:
      var value = /** @type {string} */ (reader.readString());
      msg.setStartTime(value);
      break;
    case 24:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 25:
      var value = /** @type {string} */ (reader.readString());
      msg.setUpdatedTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.Facebook.API.AdSet.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.Facebook.API.AdSet.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.Facebook.API.AdSet} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Facebook.API.AdSet.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAccountId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAdLabelsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.common.Facebook.API.AdLabel.serializeBinaryToWriter
    );
  }
  f = message.getBidAmount();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getBidInfoMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(5, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeInt64);
  }
  f = message.getBidStrategy();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getBillingEvent();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getBudgetRemaining();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getCampaignId();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getCreatedTime();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getDailyBudget();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getDailyMinSpendTarget();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getDailySpendCap();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getDestinationType();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getEffectiveStatus();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getEndTime();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getIssuesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      17,
      f,
      proto.common.Facebook.API.IssuesInfo.serializeBinaryToWriter
    );
  }
  f = message.getLifetimeBudget();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getLifetimeMinSpendTarget();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getLifetimeSpendCap();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      21,
      f
    );
  }
  f = message.getOptimizationGoal();
  if (f.length > 0) {
    writer.writeString(
      22,
      f
    );
  }
  f = message.getStartTime();
  if (f.length > 0) {
    writer.writeString(
      23,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      24,
      f
    );
  }
  f = message.getUpdatedTime();
  if (f.length > 0) {
    writer.writeString(
      25,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.common.Facebook.API.AdSet.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Facebook.API.AdSet} returns this
 */
proto.common.Facebook.API.AdSet.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string account_id = 2;
 * @return {string}
 */
proto.common.Facebook.API.AdSet.prototype.getAccountId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Facebook.API.AdSet} returns this
 */
proto.common.Facebook.API.AdSet.prototype.setAccountId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated AdLabel ad_labels = 3;
 * @return {!Array<!proto.common.Facebook.API.AdLabel>}
 */
proto.common.Facebook.API.AdSet.prototype.getAdLabelsList = function() {
  return /** @type{!Array<!proto.common.Facebook.API.AdLabel>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.common.Facebook.API.AdLabel, 3));
};


/**
 * @param {!Array<!proto.common.Facebook.API.AdLabel>} value
 * @return {!proto.common.Facebook.API.AdSet} returns this
*/
proto.common.Facebook.API.AdSet.prototype.setAdLabelsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.common.Facebook.API.AdLabel=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.Facebook.API.AdLabel}
 */
proto.common.Facebook.API.AdSet.prototype.addAdLabels = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.common.Facebook.API.AdLabel, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.common.Facebook.API.AdSet} returns this
 */
proto.common.Facebook.API.AdSet.prototype.clearAdLabelsList = function() {
  return this.setAdLabelsList([]);
};


/**
 * optional int64 bid_amount = 4;
 * @return {number}
 */
proto.common.Facebook.API.AdSet.prototype.getBidAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.common.Facebook.API.AdSet} returns this
 */
proto.common.Facebook.API.AdSet.prototype.setBidAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * map<string, int64> bid_info = 5;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,number>}
 */
proto.common.Facebook.API.AdSet.prototype.getBidInfoMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,number>} */ (
      jspb.Message.getMapField(this, 5, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.common.Facebook.API.AdSet} returns this
 */
proto.common.Facebook.API.AdSet.prototype.clearBidInfoMap = function() {
  this.getBidInfoMap().clear();
  return this;};


/**
 * optional string bid_strategy = 6;
 * @return {string}
 */
proto.common.Facebook.API.AdSet.prototype.getBidStrategy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Facebook.API.AdSet} returns this
 */
proto.common.Facebook.API.AdSet.prototype.setBidStrategy = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string billing_event = 7;
 * @return {string}
 */
proto.common.Facebook.API.AdSet.prototype.getBillingEvent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Facebook.API.AdSet} returns this
 */
proto.common.Facebook.API.AdSet.prototype.setBillingEvent = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string budget_remaining = 8;
 * @return {string}
 */
proto.common.Facebook.API.AdSet.prototype.getBudgetRemaining = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Facebook.API.AdSet} returns this
 */
proto.common.Facebook.API.AdSet.prototype.setBudgetRemaining = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string campaign_id = 9;
 * @return {string}
 */
proto.common.Facebook.API.AdSet.prototype.getCampaignId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Facebook.API.AdSet} returns this
 */
proto.common.Facebook.API.AdSet.prototype.setCampaignId = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string created_time = 10;
 * @return {string}
 */
proto.common.Facebook.API.AdSet.prototype.getCreatedTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Facebook.API.AdSet} returns this
 */
proto.common.Facebook.API.AdSet.prototype.setCreatedTime = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string daily_budget = 11;
 * @return {string}
 */
proto.common.Facebook.API.AdSet.prototype.getDailyBudget = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Facebook.API.AdSet} returns this
 */
proto.common.Facebook.API.AdSet.prototype.setDailyBudget = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string daily_min_spend_target = 12;
 * @return {string}
 */
proto.common.Facebook.API.AdSet.prototype.getDailyMinSpendTarget = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Facebook.API.AdSet} returns this
 */
proto.common.Facebook.API.AdSet.prototype.setDailyMinSpendTarget = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string daily_spend_cap = 13;
 * @return {string}
 */
proto.common.Facebook.API.AdSet.prototype.getDailySpendCap = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Facebook.API.AdSet} returns this
 */
proto.common.Facebook.API.AdSet.prototype.setDailySpendCap = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string destination_type = 14;
 * @return {string}
 */
proto.common.Facebook.API.AdSet.prototype.getDestinationType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Facebook.API.AdSet} returns this
 */
proto.common.Facebook.API.AdSet.prototype.setDestinationType = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string effective_status = 15;
 * @return {string}
 */
proto.common.Facebook.API.AdSet.prototype.getEffectiveStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Facebook.API.AdSet} returns this
 */
proto.common.Facebook.API.AdSet.prototype.setEffectiveStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string end_time = 16;
 * @return {string}
 */
proto.common.Facebook.API.AdSet.prototype.getEndTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Facebook.API.AdSet} returns this
 */
proto.common.Facebook.API.AdSet.prototype.setEndTime = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * repeated IssuesInfo issues = 17;
 * @return {!Array<!proto.common.Facebook.API.IssuesInfo>}
 */
proto.common.Facebook.API.AdSet.prototype.getIssuesList = function() {
  return /** @type{!Array<!proto.common.Facebook.API.IssuesInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.common.Facebook.API.IssuesInfo, 17));
};


/**
 * @param {!Array<!proto.common.Facebook.API.IssuesInfo>} value
 * @return {!proto.common.Facebook.API.AdSet} returns this
*/
proto.common.Facebook.API.AdSet.prototype.setIssuesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 17, value);
};


/**
 * @param {!proto.common.Facebook.API.IssuesInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.Facebook.API.IssuesInfo}
 */
proto.common.Facebook.API.AdSet.prototype.addIssues = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 17, opt_value, proto.common.Facebook.API.IssuesInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.common.Facebook.API.AdSet} returns this
 */
proto.common.Facebook.API.AdSet.prototype.clearIssuesList = function() {
  return this.setIssuesList([]);
};


/**
 * optional string lifetime_budget = 18;
 * @return {string}
 */
proto.common.Facebook.API.AdSet.prototype.getLifetimeBudget = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Facebook.API.AdSet} returns this
 */
proto.common.Facebook.API.AdSet.prototype.setLifetimeBudget = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional string lifetime_min_spend_target = 19;
 * @return {string}
 */
proto.common.Facebook.API.AdSet.prototype.getLifetimeMinSpendTarget = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Facebook.API.AdSet} returns this
 */
proto.common.Facebook.API.AdSet.prototype.setLifetimeMinSpendTarget = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional string lifetime_spend_cap = 20;
 * @return {string}
 */
proto.common.Facebook.API.AdSet.prototype.getLifetimeSpendCap = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Facebook.API.AdSet} returns this
 */
proto.common.Facebook.API.AdSet.prototype.setLifetimeSpendCap = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional string name = 21;
 * @return {string}
 */
proto.common.Facebook.API.AdSet.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Facebook.API.AdSet} returns this
 */
proto.common.Facebook.API.AdSet.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 21, value);
};


/**
 * optional string optimization_goal = 22;
 * @return {string}
 */
proto.common.Facebook.API.AdSet.prototype.getOptimizationGoal = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 22, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Facebook.API.AdSet} returns this
 */
proto.common.Facebook.API.AdSet.prototype.setOptimizationGoal = function(value) {
  return jspb.Message.setProto3StringField(this, 22, value);
};


/**
 * optional string start_time = 23;
 * @return {string}
 */
proto.common.Facebook.API.AdSet.prototype.getStartTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 23, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Facebook.API.AdSet} returns this
 */
proto.common.Facebook.API.AdSet.prototype.setStartTime = function(value) {
  return jspb.Message.setProto3StringField(this, 23, value);
};


/**
 * optional string status = 24;
 * @return {string}
 */
proto.common.Facebook.API.AdSet.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 24, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Facebook.API.AdSet} returns this
 */
proto.common.Facebook.API.AdSet.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 24, value);
};


/**
 * optional string updated_time = 25;
 * @return {string}
 */
proto.common.Facebook.API.AdSet.prototype.getUpdatedTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 25, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Facebook.API.AdSet} returns this
 */
proto.common.Facebook.API.AdSet.prototype.setUpdatedTime = function(value) {
  return jspb.Message.setProto3StringField(this, 25, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.Facebook.API.AdSetEditFields.prototype.toObject = function(opt_includeInstance) {
  return proto.common.Facebook.API.AdSetEditFields.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.Facebook.API.AdSetEditFields} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Facebook.API.AdSetEditFields.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: jspb.Message.getFieldWithDefault(msg, 1, 0),
    dailyBudget: jspb.Message.getFieldWithDefault(msg, 2, ""),
    lifetimeBudget: jspb.Message.getFieldWithDefault(msg, 3, ""),
    bidAmount: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.Facebook.API.AdSetEditFields}
 */
proto.common.Facebook.API.AdSetEditFields.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.Facebook.API.AdSetEditFields;
  return proto.common.Facebook.API.AdSetEditFields.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.Facebook.API.AdSetEditFields} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.Facebook.API.AdSetEditFields}
 */
proto.common.Facebook.API.AdSetEditFields.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.common.Facebook.API.UpdateStatus.Option} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDailyBudget(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLifetimeBudget(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setBidAmount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.Facebook.API.AdSetEditFields.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.Facebook.API.AdSetEditFields.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.Facebook.API.AdSetEditFields} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Facebook.API.AdSetEditFields.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getDailyBudget();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLifetimeBudget();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getBidAmount();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional UpdateStatus.Option status = 1;
 * @return {!proto.common.Facebook.API.UpdateStatus.Option}
 */
proto.common.Facebook.API.AdSetEditFields.prototype.getStatus = function() {
  return /** @type {!proto.common.Facebook.API.UpdateStatus.Option} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.common.Facebook.API.UpdateStatus.Option} value
 * @return {!proto.common.Facebook.API.AdSetEditFields} returns this
 */
proto.common.Facebook.API.AdSetEditFields.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string daily_budget = 2;
 * @return {string}
 */
proto.common.Facebook.API.AdSetEditFields.prototype.getDailyBudget = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Facebook.API.AdSetEditFields} returns this
 */
proto.common.Facebook.API.AdSetEditFields.prototype.setDailyBudget = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string lifetime_budget = 3;
 * @return {string}
 */
proto.common.Facebook.API.AdSetEditFields.prototype.getLifetimeBudget = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Facebook.API.AdSetEditFields} returns this
 */
proto.common.Facebook.API.AdSetEditFields.prototype.setLifetimeBudget = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string bid_amount = 4;
 * @return {string}
 */
proto.common.Facebook.API.AdSetEditFields.prototype.getBidAmount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Facebook.API.AdSetEditFields} returns this
 */
proto.common.Facebook.API.AdSetEditFields.prototype.setBidAmount = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.Facebook.API.AdSetEditMask.prototype.toObject = function(opt_includeInstance) {
  return proto.common.Facebook.API.AdSetEditMask.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.Facebook.API.AdSetEditMask} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Facebook.API.AdSetEditMask.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    dailyBudget: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    lifetimeBudget: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    bidAmount: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.Facebook.API.AdSetEditMask}
 */
proto.common.Facebook.API.AdSetEditMask.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.Facebook.API.AdSetEditMask;
  return proto.common.Facebook.API.AdSetEditMask.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.Facebook.API.AdSetEditMask} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.Facebook.API.AdSetEditMask}
 */
proto.common.Facebook.API.AdSetEditMask.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setStatus(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDailyBudget(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setLifetimeBudget(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setBidAmount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.Facebook.API.AdSetEditMask.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.Facebook.API.AdSetEditMask.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.Facebook.API.AdSetEditMask} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Facebook.API.AdSetEditMask.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getDailyBudget();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getLifetimeBudget();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getBidAmount();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional bool status = 1;
 * @return {boolean}
 */
proto.common.Facebook.API.AdSetEditMask.prototype.getStatus = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.common.Facebook.API.AdSetEditMask} returns this
 */
proto.common.Facebook.API.AdSetEditMask.prototype.setStatus = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional bool daily_budget = 2;
 * @return {boolean}
 */
proto.common.Facebook.API.AdSetEditMask.prototype.getDailyBudget = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.common.Facebook.API.AdSetEditMask} returns this
 */
proto.common.Facebook.API.AdSetEditMask.prototype.setDailyBudget = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional bool lifetime_budget = 3;
 * @return {boolean}
 */
proto.common.Facebook.API.AdSetEditMask.prototype.getLifetimeBudget = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.common.Facebook.API.AdSetEditMask} returns this
 */
proto.common.Facebook.API.AdSetEditMask.prototype.setLifetimeBudget = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional bool bid_amount = 4;
 * @return {boolean}
 */
proto.common.Facebook.API.AdSetEditMask.prototype.getBidAmount = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.common.Facebook.API.AdSetEditMask} returns this
 */
proto.common.Facebook.API.AdSetEditMask.prototype.setBidAmount = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.common.Facebook.API.Ad.repeatedFields_ = [4,10];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.Facebook.API.Ad.prototype.toObject = function(opt_includeInstance) {
  return proto.common.Facebook.API.Ad.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.Facebook.API.Ad} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Facebook.API.Ad.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    accountId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    adActiveTime: jspb.Message.getFieldWithDefault(msg, 3, ""),
    adLabelsList: jspb.Message.toObjectList(msg.getAdLabelsList(),
    proto.common.Facebook.API.AdLabel.toObject, includeInstance),
    adSetId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    bidAmount: jspb.Message.getFieldWithDefault(msg, 6, 0),
    campaignId: jspb.Message.getFieldWithDefault(msg, 7, ""),
    createdTime: jspb.Message.getFieldWithDefault(msg, 8, ""),
    effectiveStatus: jspb.Message.getFieldWithDefault(msg, 9, ""),
    issuesList: jspb.Message.toObjectList(msg.getIssuesList(),
    proto.common.Facebook.API.IssuesInfo.toObject, includeInstance),
    name: jspb.Message.getFieldWithDefault(msg, 11, ""),
    status: jspb.Message.getFieldWithDefault(msg, 12, ""),
    updatedTime: jspb.Message.getFieldWithDefault(msg, 13, ""),
    adCreative: (f = msg.getAdCreative()) && proto.common.Facebook.API.AdCreative.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.Facebook.API.Ad}
 */
proto.common.Facebook.API.Ad.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.Facebook.API.Ad;
  return proto.common.Facebook.API.Ad.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.Facebook.API.Ad} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.Facebook.API.Ad}
 */
proto.common.Facebook.API.Ad.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdActiveTime(value);
      break;
    case 4:
      var value = new proto.common.Facebook.API.AdLabel;
      reader.readMessage(value,proto.common.Facebook.API.AdLabel.deserializeBinaryFromReader);
      msg.addAdLabels(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdSetId(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBidAmount(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setCampaignId(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedTime(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setEffectiveStatus(value);
      break;
    case 10:
      var value = new proto.common.Facebook.API.IssuesInfo;
      reader.readMessage(value,proto.common.Facebook.API.IssuesInfo.deserializeBinaryFromReader);
      msg.addIssues(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setUpdatedTime(value);
      break;
    case 14:
      var value = new proto.common.Facebook.API.AdCreative;
      reader.readMessage(value,proto.common.Facebook.API.AdCreative.deserializeBinaryFromReader);
      msg.setAdCreative(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.Facebook.API.Ad.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.Facebook.API.Ad.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.Facebook.API.Ad} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Facebook.API.Ad.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAccountId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAdActiveTime();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAdLabelsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.common.Facebook.API.AdLabel.serializeBinaryToWriter
    );
  }
  f = message.getAdSetId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getBidAmount();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getCampaignId();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getCreatedTime();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getEffectiveStatus();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getIssuesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      proto.common.Facebook.API.IssuesInfo.serializeBinaryToWriter
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getUpdatedTime();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getAdCreative();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      proto.common.Facebook.API.AdCreative.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.common.Facebook.API.Ad.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Facebook.API.Ad} returns this
 */
proto.common.Facebook.API.Ad.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string account_id = 2;
 * @return {string}
 */
proto.common.Facebook.API.Ad.prototype.getAccountId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Facebook.API.Ad} returns this
 */
proto.common.Facebook.API.Ad.prototype.setAccountId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string ad_active_time = 3;
 * @return {string}
 */
proto.common.Facebook.API.Ad.prototype.getAdActiveTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Facebook.API.Ad} returns this
 */
proto.common.Facebook.API.Ad.prototype.setAdActiveTime = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated AdLabel ad_labels = 4;
 * @return {!Array<!proto.common.Facebook.API.AdLabel>}
 */
proto.common.Facebook.API.Ad.prototype.getAdLabelsList = function() {
  return /** @type{!Array<!proto.common.Facebook.API.AdLabel>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.common.Facebook.API.AdLabel, 4));
};


/**
 * @param {!Array<!proto.common.Facebook.API.AdLabel>} value
 * @return {!proto.common.Facebook.API.Ad} returns this
*/
proto.common.Facebook.API.Ad.prototype.setAdLabelsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.common.Facebook.API.AdLabel=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.Facebook.API.AdLabel}
 */
proto.common.Facebook.API.Ad.prototype.addAdLabels = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.common.Facebook.API.AdLabel, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.common.Facebook.API.Ad} returns this
 */
proto.common.Facebook.API.Ad.prototype.clearAdLabelsList = function() {
  return this.setAdLabelsList([]);
};


/**
 * optional string ad_set_id = 5;
 * @return {string}
 */
proto.common.Facebook.API.Ad.prototype.getAdSetId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Facebook.API.Ad} returns this
 */
proto.common.Facebook.API.Ad.prototype.setAdSetId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional int64 bid_amount = 6;
 * @return {number}
 */
proto.common.Facebook.API.Ad.prototype.getBidAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.common.Facebook.API.Ad} returns this
 */
proto.common.Facebook.API.Ad.prototype.setBidAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional string campaign_id = 7;
 * @return {string}
 */
proto.common.Facebook.API.Ad.prototype.getCampaignId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Facebook.API.Ad} returns this
 */
proto.common.Facebook.API.Ad.prototype.setCampaignId = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string created_time = 8;
 * @return {string}
 */
proto.common.Facebook.API.Ad.prototype.getCreatedTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Facebook.API.Ad} returns this
 */
proto.common.Facebook.API.Ad.prototype.setCreatedTime = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string effective_status = 9;
 * @return {string}
 */
proto.common.Facebook.API.Ad.prototype.getEffectiveStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Facebook.API.Ad} returns this
 */
proto.common.Facebook.API.Ad.prototype.setEffectiveStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * repeated IssuesInfo issues = 10;
 * @return {!Array<!proto.common.Facebook.API.IssuesInfo>}
 */
proto.common.Facebook.API.Ad.prototype.getIssuesList = function() {
  return /** @type{!Array<!proto.common.Facebook.API.IssuesInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.common.Facebook.API.IssuesInfo, 10));
};


/**
 * @param {!Array<!proto.common.Facebook.API.IssuesInfo>} value
 * @return {!proto.common.Facebook.API.Ad} returns this
*/
proto.common.Facebook.API.Ad.prototype.setIssuesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.common.Facebook.API.IssuesInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.Facebook.API.IssuesInfo}
 */
proto.common.Facebook.API.Ad.prototype.addIssues = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.common.Facebook.API.IssuesInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.common.Facebook.API.Ad} returns this
 */
proto.common.Facebook.API.Ad.prototype.clearIssuesList = function() {
  return this.setIssuesList([]);
};


/**
 * optional string name = 11;
 * @return {string}
 */
proto.common.Facebook.API.Ad.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Facebook.API.Ad} returns this
 */
proto.common.Facebook.API.Ad.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string status = 12;
 * @return {string}
 */
proto.common.Facebook.API.Ad.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Facebook.API.Ad} returns this
 */
proto.common.Facebook.API.Ad.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string updated_time = 13;
 * @return {string}
 */
proto.common.Facebook.API.Ad.prototype.getUpdatedTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Facebook.API.Ad} returns this
 */
proto.common.Facebook.API.Ad.prototype.setUpdatedTime = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional AdCreative ad_creative = 14;
 * @return {?proto.common.Facebook.API.AdCreative}
 */
proto.common.Facebook.API.Ad.prototype.getAdCreative = function() {
  return /** @type{?proto.common.Facebook.API.AdCreative} */ (
    jspb.Message.getWrapperField(this, proto.common.Facebook.API.AdCreative, 14));
};


/**
 * @param {?proto.common.Facebook.API.AdCreative|undefined} value
 * @return {!proto.common.Facebook.API.Ad} returns this
*/
proto.common.Facebook.API.Ad.prototype.setAdCreative = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.Facebook.API.Ad} returns this
 */
proto.common.Facebook.API.Ad.prototype.clearAdCreative = function() {
  return this.setAdCreative(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.Facebook.API.Ad.prototype.hasAdCreative = function() {
  return jspb.Message.getField(this, 14) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.Facebook.API.AdEditFields.prototype.toObject = function(opt_includeInstance) {
  return proto.common.Facebook.API.AdEditFields.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.Facebook.API.AdEditFields} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Facebook.API.AdEditFields.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.Facebook.API.AdEditFields}
 */
proto.common.Facebook.API.AdEditFields.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.Facebook.API.AdEditFields;
  return proto.common.Facebook.API.AdEditFields.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.Facebook.API.AdEditFields} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.Facebook.API.AdEditFields}
 */
proto.common.Facebook.API.AdEditFields.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.common.Facebook.API.UpdateStatus.Option} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.Facebook.API.AdEditFields.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.Facebook.API.AdEditFields.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.Facebook.API.AdEditFields} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Facebook.API.AdEditFields.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
};


/**
 * optional UpdateStatus.Option status = 1;
 * @return {!proto.common.Facebook.API.UpdateStatus.Option}
 */
proto.common.Facebook.API.AdEditFields.prototype.getStatus = function() {
  return /** @type {!proto.common.Facebook.API.UpdateStatus.Option} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.common.Facebook.API.UpdateStatus.Option} value
 * @return {!proto.common.Facebook.API.AdEditFields} returns this
 */
proto.common.Facebook.API.AdEditFields.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.Facebook.API.AdEditMask.prototype.toObject = function(opt_includeInstance) {
  return proto.common.Facebook.API.AdEditMask.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.Facebook.API.AdEditMask} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Facebook.API.AdEditMask.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.Facebook.API.AdEditMask}
 */
proto.common.Facebook.API.AdEditMask.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.Facebook.API.AdEditMask;
  return proto.common.Facebook.API.AdEditMask.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.Facebook.API.AdEditMask} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.Facebook.API.AdEditMask}
 */
proto.common.Facebook.API.AdEditMask.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.Facebook.API.AdEditMask.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.Facebook.API.AdEditMask.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.Facebook.API.AdEditMask} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Facebook.API.AdEditMask.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool status = 1;
 * @return {boolean}
 */
proto.common.Facebook.API.AdEditMask.prototype.getStatus = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.common.Facebook.API.AdEditMask} returns this
 */
proto.common.Facebook.API.AdEditMask.prototype.setStatus = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.common.Facebook.API.AdCreative.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.Facebook.API.AdCreative.prototype.toObject = function(opt_includeInstance) {
  return proto.common.Facebook.API.AdCreative.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.Facebook.API.AdCreative} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Facebook.API.AdCreative.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    adLabelsList: jspb.Message.toObjectList(msg.getAdLabelsList(),
    proto.common.Facebook.API.AdLabel.toObject, includeInstance),
    body: jspb.Message.getFieldWithDefault(msg, 3, ""),
    callToActionType: jspb.Message.getFieldWithDefault(msg, 4, ""),
    imageUrl: jspb.Message.getFieldWithDefault(msg, 5, ""),
    linkUrl: jspb.Message.getFieldWithDefault(msg, 6, ""),
    name: jspb.Message.getFieldWithDefault(msg, 7, ""),
    objectStoryId: jspb.Message.getFieldWithDefault(msg, 8, ""),
    objectUrl: jspb.Message.getFieldWithDefault(msg, 9, ""),
    status: jspb.Message.getFieldWithDefault(msg, 10, ""),
    templateUrl: jspb.Message.getFieldWithDefault(msg, 11, ""),
    title: jspb.Message.getFieldWithDefault(msg, 12, ""),
    urlTags: jspb.Message.getFieldWithDefault(msg, 13, ""),
    assetFeedSpec: (f = msg.getAssetFeedSpec()) && proto.common.Facebook.API.AssetFeedSpec.toObject(includeInstance, f),
    objectStorySpec: (f = msg.getObjectStorySpec()) && proto.common.Facebook.API.ObjectStorySpec.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.Facebook.API.AdCreative}
 */
proto.common.Facebook.API.AdCreative.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.Facebook.API.AdCreative;
  return proto.common.Facebook.API.AdCreative.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.Facebook.API.AdCreative} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.Facebook.API.AdCreative}
 */
proto.common.Facebook.API.AdCreative.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new proto.common.Facebook.API.AdLabel;
      reader.readMessage(value,proto.common.Facebook.API.AdLabel.deserializeBinaryFromReader);
      msg.addAdLabels(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBody(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCallToActionType(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setImageUrl(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setLinkUrl(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setObjectStoryId(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setObjectUrl(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setTemplateUrl(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrlTags(value);
      break;
    case 14:
      var value = new proto.common.Facebook.API.AssetFeedSpec;
      reader.readMessage(value,proto.common.Facebook.API.AssetFeedSpec.deserializeBinaryFromReader);
      msg.setAssetFeedSpec(value);
      break;
    case 15:
      var value = new proto.common.Facebook.API.ObjectStorySpec;
      reader.readMessage(value,proto.common.Facebook.API.ObjectStorySpec.deserializeBinaryFromReader);
      msg.setObjectStorySpec(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.Facebook.API.AdCreative.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.Facebook.API.AdCreative.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.Facebook.API.AdCreative} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Facebook.API.AdCreative.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAdLabelsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.common.Facebook.API.AdLabel.serializeBinaryToWriter
    );
  }
  f = message.getBody();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCallToActionType();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getImageUrl();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getLinkUrl();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getObjectStoryId();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getObjectUrl();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getTemplateUrl();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getUrlTags();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getAssetFeedSpec();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      proto.common.Facebook.API.AssetFeedSpec.serializeBinaryToWriter
    );
  }
  f = message.getObjectStorySpec();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      proto.common.Facebook.API.ObjectStorySpec.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.Facebook.API.AdCreative.VideoData.prototype.toObject = function(opt_includeInstance) {
  return proto.common.Facebook.API.AdCreative.VideoData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.Facebook.API.AdCreative.VideoData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Facebook.API.AdCreative.VideoData.toObject = function(includeInstance, msg) {
  var f, obj = {
    callToAction: (f = msg.getCallToAction()) && proto.common.Facebook.API.AdCreative.CallToAction.toObject(includeInstance, f),
    linkDescription: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.Facebook.API.AdCreative.VideoData}
 */
proto.common.Facebook.API.AdCreative.VideoData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.Facebook.API.AdCreative.VideoData;
  return proto.common.Facebook.API.AdCreative.VideoData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.Facebook.API.AdCreative.VideoData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.Facebook.API.AdCreative.VideoData}
 */
proto.common.Facebook.API.AdCreative.VideoData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.common.Facebook.API.AdCreative.CallToAction;
      reader.readMessage(value,proto.common.Facebook.API.AdCreative.CallToAction.deserializeBinaryFromReader);
      msg.setCallToAction(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLinkDescription(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.Facebook.API.AdCreative.VideoData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.Facebook.API.AdCreative.VideoData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.Facebook.API.AdCreative.VideoData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Facebook.API.AdCreative.VideoData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCallToAction();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.common.Facebook.API.AdCreative.CallToAction.serializeBinaryToWriter
    );
  }
  f = message.getLinkDescription();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional CallToAction call_to_action = 1;
 * @return {?proto.common.Facebook.API.AdCreative.CallToAction}
 */
proto.common.Facebook.API.AdCreative.VideoData.prototype.getCallToAction = function() {
  return /** @type{?proto.common.Facebook.API.AdCreative.CallToAction} */ (
    jspb.Message.getWrapperField(this, proto.common.Facebook.API.AdCreative.CallToAction, 1));
};


/**
 * @param {?proto.common.Facebook.API.AdCreative.CallToAction|undefined} value
 * @return {!proto.common.Facebook.API.AdCreative.VideoData} returns this
*/
proto.common.Facebook.API.AdCreative.VideoData.prototype.setCallToAction = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.Facebook.API.AdCreative.VideoData} returns this
 */
proto.common.Facebook.API.AdCreative.VideoData.prototype.clearCallToAction = function() {
  return this.setCallToAction(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.Facebook.API.AdCreative.VideoData.prototype.hasCallToAction = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string link_description = 2;
 * @return {string}
 */
proto.common.Facebook.API.AdCreative.VideoData.prototype.getLinkDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Facebook.API.AdCreative.VideoData} returns this
 */
proto.common.Facebook.API.AdCreative.VideoData.prototype.setLinkDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.Facebook.API.AdCreative.CallToAction.prototype.toObject = function(opt_includeInstance) {
  return proto.common.Facebook.API.AdCreative.CallToAction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.Facebook.API.AdCreative.CallToAction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Facebook.API.AdCreative.CallToAction.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: (f = msg.getValue()) && proto.common.Facebook.API.AdCreative.CallToActionValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.Facebook.API.AdCreative.CallToAction}
 */
proto.common.Facebook.API.AdCreative.CallToAction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.Facebook.API.AdCreative.CallToAction;
  return proto.common.Facebook.API.AdCreative.CallToAction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.Facebook.API.AdCreative.CallToAction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.Facebook.API.AdCreative.CallToAction}
 */
proto.common.Facebook.API.AdCreative.CallToAction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 2:
      var value = new proto.common.Facebook.API.AdCreative.CallToActionValue;
      reader.readMessage(value,proto.common.Facebook.API.AdCreative.CallToActionValue.deserializeBinaryFromReader);
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.Facebook.API.AdCreative.CallToAction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.Facebook.API.AdCreative.CallToAction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.Facebook.API.AdCreative.CallToAction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Facebook.API.AdCreative.CallToAction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.common.Facebook.API.AdCreative.CallToActionValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional string type = 1;
 * @return {string}
 */
proto.common.Facebook.API.AdCreative.CallToAction.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Facebook.API.AdCreative.CallToAction} returns this
 */
proto.common.Facebook.API.AdCreative.CallToAction.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional CallToActionValue value = 2;
 * @return {?proto.common.Facebook.API.AdCreative.CallToActionValue}
 */
proto.common.Facebook.API.AdCreative.CallToAction.prototype.getValue = function() {
  return /** @type{?proto.common.Facebook.API.AdCreative.CallToActionValue} */ (
    jspb.Message.getWrapperField(this, proto.common.Facebook.API.AdCreative.CallToActionValue, 2));
};


/**
 * @param {?proto.common.Facebook.API.AdCreative.CallToActionValue|undefined} value
 * @return {!proto.common.Facebook.API.AdCreative.CallToAction} returns this
*/
proto.common.Facebook.API.AdCreative.CallToAction.prototype.setValue = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.Facebook.API.AdCreative.CallToAction} returns this
 */
proto.common.Facebook.API.AdCreative.CallToAction.prototype.clearValue = function() {
  return this.setValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.Facebook.API.AdCreative.CallToAction.prototype.hasValue = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.Facebook.API.AdCreative.CallToActionValue.prototype.toObject = function(opt_includeInstance) {
  return proto.common.Facebook.API.AdCreative.CallToActionValue.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.Facebook.API.AdCreative.CallToActionValue} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Facebook.API.AdCreative.CallToActionValue.toObject = function(includeInstance, msg) {
  var f, obj = {
    appDestination: jspb.Message.getFieldWithDefault(msg, 1, ""),
    appLink: jspb.Message.getFieldWithDefault(msg, 2, ""),
    application: jspb.Message.getFieldWithDefault(msg, 3, ""),
    eventId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    leadGenFormId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    link: jspb.Message.getFieldWithDefault(msg, 6, ""),
    linkCaption: jspb.Message.getFieldWithDefault(msg, 7, ""),
    linkFormat: jspb.Message.getFieldWithDefault(msg, 8, ""),
    page: jspb.Message.getFieldWithDefault(msg, 9, ""),
    productLink: jspb.Message.getFieldWithDefault(msg, 10, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.Facebook.API.AdCreative.CallToActionValue}
 */
proto.common.Facebook.API.AdCreative.CallToActionValue.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.Facebook.API.AdCreative.CallToActionValue;
  return proto.common.Facebook.API.AdCreative.CallToActionValue.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.Facebook.API.AdCreative.CallToActionValue} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.Facebook.API.AdCreative.CallToActionValue}
 */
proto.common.Facebook.API.AdCreative.CallToActionValue.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAppDestination(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAppLink(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setApplication(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setEventId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setLeadGenFormId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setLink(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setLinkCaption(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setLinkFormat(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setPage(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductLink(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.Facebook.API.AdCreative.CallToActionValue.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.Facebook.API.AdCreative.CallToActionValue.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.Facebook.API.AdCreative.CallToActionValue} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Facebook.API.AdCreative.CallToActionValue.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAppDestination();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAppLink();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getApplication();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getEventId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getLeadGenFormId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getLink();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getLinkCaption();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getLinkFormat();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getPage();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getProductLink();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
};


/**
 * optional string app_destination = 1;
 * @return {string}
 */
proto.common.Facebook.API.AdCreative.CallToActionValue.prototype.getAppDestination = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Facebook.API.AdCreative.CallToActionValue} returns this
 */
proto.common.Facebook.API.AdCreative.CallToActionValue.prototype.setAppDestination = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string app_link = 2;
 * @return {string}
 */
proto.common.Facebook.API.AdCreative.CallToActionValue.prototype.getAppLink = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Facebook.API.AdCreative.CallToActionValue} returns this
 */
proto.common.Facebook.API.AdCreative.CallToActionValue.prototype.setAppLink = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string application = 3;
 * @return {string}
 */
proto.common.Facebook.API.AdCreative.CallToActionValue.prototype.getApplication = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Facebook.API.AdCreative.CallToActionValue} returns this
 */
proto.common.Facebook.API.AdCreative.CallToActionValue.prototype.setApplication = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string event_id = 4;
 * @return {string}
 */
proto.common.Facebook.API.AdCreative.CallToActionValue.prototype.getEventId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Facebook.API.AdCreative.CallToActionValue} returns this
 */
proto.common.Facebook.API.AdCreative.CallToActionValue.prototype.setEventId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string lead_gen_form_id = 5;
 * @return {string}
 */
proto.common.Facebook.API.AdCreative.CallToActionValue.prototype.getLeadGenFormId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Facebook.API.AdCreative.CallToActionValue} returns this
 */
proto.common.Facebook.API.AdCreative.CallToActionValue.prototype.setLeadGenFormId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string link = 6;
 * @return {string}
 */
proto.common.Facebook.API.AdCreative.CallToActionValue.prototype.getLink = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Facebook.API.AdCreative.CallToActionValue} returns this
 */
proto.common.Facebook.API.AdCreative.CallToActionValue.prototype.setLink = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string link_caption = 7;
 * @return {string}
 */
proto.common.Facebook.API.AdCreative.CallToActionValue.prototype.getLinkCaption = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Facebook.API.AdCreative.CallToActionValue} returns this
 */
proto.common.Facebook.API.AdCreative.CallToActionValue.prototype.setLinkCaption = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string link_format = 8;
 * @return {string}
 */
proto.common.Facebook.API.AdCreative.CallToActionValue.prototype.getLinkFormat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Facebook.API.AdCreative.CallToActionValue} returns this
 */
proto.common.Facebook.API.AdCreative.CallToActionValue.prototype.setLinkFormat = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string page = 9;
 * @return {string}
 */
proto.common.Facebook.API.AdCreative.CallToActionValue.prototype.getPage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Facebook.API.AdCreative.CallToActionValue} returns this
 */
proto.common.Facebook.API.AdCreative.CallToActionValue.prototype.setPage = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string product_link = 10;
 * @return {string}
 */
proto.common.Facebook.API.AdCreative.CallToActionValue.prototype.getProductLink = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Facebook.API.AdCreative.CallToActionValue} returns this
 */
proto.common.Facebook.API.AdCreative.CallToActionValue.prototype.setProductLink = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.common.Facebook.API.AdCreative.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Facebook.API.AdCreative} returns this
 */
proto.common.Facebook.API.AdCreative.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated AdLabel ad_labels = 2;
 * @return {!Array<!proto.common.Facebook.API.AdLabel>}
 */
proto.common.Facebook.API.AdCreative.prototype.getAdLabelsList = function() {
  return /** @type{!Array<!proto.common.Facebook.API.AdLabel>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.common.Facebook.API.AdLabel, 2));
};


/**
 * @param {!Array<!proto.common.Facebook.API.AdLabel>} value
 * @return {!proto.common.Facebook.API.AdCreative} returns this
*/
proto.common.Facebook.API.AdCreative.prototype.setAdLabelsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.common.Facebook.API.AdLabel=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.Facebook.API.AdLabel}
 */
proto.common.Facebook.API.AdCreative.prototype.addAdLabels = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.common.Facebook.API.AdLabel, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.common.Facebook.API.AdCreative} returns this
 */
proto.common.Facebook.API.AdCreative.prototype.clearAdLabelsList = function() {
  return this.setAdLabelsList([]);
};


/**
 * optional string body = 3;
 * @return {string}
 */
proto.common.Facebook.API.AdCreative.prototype.getBody = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Facebook.API.AdCreative} returns this
 */
proto.common.Facebook.API.AdCreative.prototype.setBody = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string call_to_action_type = 4;
 * @return {string}
 */
proto.common.Facebook.API.AdCreative.prototype.getCallToActionType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Facebook.API.AdCreative} returns this
 */
proto.common.Facebook.API.AdCreative.prototype.setCallToActionType = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string image_url = 5;
 * @return {string}
 */
proto.common.Facebook.API.AdCreative.prototype.getImageUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Facebook.API.AdCreative} returns this
 */
proto.common.Facebook.API.AdCreative.prototype.setImageUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string link_url = 6;
 * @return {string}
 */
proto.common.Facebook.API.AdCreative.prototype.getLinkUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Facebook.API.AdCreative} returns this
 */
proto.common.Facebook.API.AdCreative.prototype.setLinkUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string name = 7;
 * @return {string}
 */
proto.common.Facebook.API.AdCreative.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Facebook.API.AdCreative} returns this
 */
proto.common.Facebook.API.AdCreative.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string object_story_id = 8;
 * @return {string}
 */
proto.common.Facebook.API.AdCreative.prototype.getObjectStoryId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Facebook.API.AdCreative} returns this
 */
proto.common.Facebook.API.AdCreative.prototype.setObjectStoryId = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string object_url = 9;
 * @return {string}
 */
proto.common.Facebook.API.AdCreative.prototype.getObjectUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Facebook.API.AdCreative} returns this
 */
proto.common.Facebook.API.AdCreative.prototype.setObjectUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string status = 10;
 * @return {string}
 */
proto.common.Facebook.API.AdCreative.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Facebook.API.AdCreative} returns this
 */
proto.common.Facebook.API.AdCreative.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string template_url = 11;
 * @return {string}
 */
proto.common.Facebook.API.AdCreative.prototype.getTemplateUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Facebook.API.AdCreative} returns this
 */
proto.common.Facebook.API.AdCreative.prototype.setTemplateUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string title = 12;
 * @return {string}
 */
proto.common.Facebook.API.AdCreative.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Facebook.API.AdCreative} returns this
 */
proto.common.Facebook.API.AdCreative.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string url_tags = 13;
 * @return {string}
 */
proto.common.Facebook.API.AdCreative.prototype.getUrlTags = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Facebook.API.AdCreative} returns this
 */
proto.common.Facebook.API.AdCreative.prototype.setUrlTags = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional AssetFeedSpec asset_feed_spec = 14;
 * @return {?proto.common.Facebook.API.AssetFeedSpec}
 */
proto.common.Facebook.API.AdCreative.prototype.getAssetFeedSpec = function() {
  return /** @type{?proto.common.Facebook.API.AssetFeedSpec} */ (
    jspb.Message.getWrapperField(this, proto.common.Facebook.API.AssetFeedSpec, 14));
};


/**
 * @param {?proto.common.Facebook.API.AssetFeedSpec|undefined} value
 * @return {!proto.common.Facebook.API.AdCreative} returns this
*/
proto.common.Facebook.API.AdCreative.prototype.setAssetFeedSpec = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.Facebook.API.AdCreative} returns this
 */
proto.common.Facebook.API.AdCreative.prototype.clearAssetFeedSpec = function() {
  return this.setAssetFeedSpec(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.Facebook.API.AdCreative.prototype.hasAssetFeedSpec = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional ObjectStorySpec object_story_spec = 15;
 * @return {?proto.common.Facebook.API.ObjectStorySpec}
 */
proto.common.Facebook.API.AdCreative.prototype.getObjectStorySpec = function() {
  return /** @type{?proto.common.Facebook.API.ObjectStorySpec} */ (
    jspb.Message.getWrapperField(this, proto.common.Facebook.API.ObjectStorySpec, 15));
};


/**
 * @param {?proto.common.Facebook.API.ObjectStorySpec|undefined} value
 * @return {!proto.common.Facebook.API.AdCreative} returns this
*/
proto.common.Facebook.API.AdCreative.prototype.setObjectStorySpec = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.Facebook.API.AdCreative} returns this
 */
proto.common.Facebook.API.AdCreative.prototype.clearObjectStorySpec = function() {
  return this.setObjectStorySpec(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.Facebook.API.AdCreative.prototype.hasObjectStorySpec = function() {
  return jspb.Message.getField(this, 15) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.Facebook.API.AdLabel.prototype.toObject = function(opt_includeInstance) {
  return proto.common.Facebook.API.AdLabel.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.Facebook.API.AdLabel} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Facebook.API.AdLabel.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.Facebook.API.AdLabel}
 */
proto.common.Facebook.API.AdLabel.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.Facebook.API.AdLabel;
  return proto.common.Facebook.API.AdLabel.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.Facebook.API.AdLabel} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.Facebook.API.AdLabel}
 */
proto.common.Facebook.API.AdLabel.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.Facebook.API.AdLabel.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.Facebook.API.AdLabel.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.Facebook.API.AdLabel} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Facebook.API.AdLabel.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.common.Facebook.API.AdLabel.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Facebook.API.AdLabel} returns this
 */
proto.common.Facebook.API.AdLabel.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.common.Facebook.API.AdLabel.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Facebook.API.AdLabel} returns this
 */
proto.common.Facebook.API.AdLabel.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.Facebook.API.IssuesInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.common.Facebook.API.IssuesInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.Facebook.API.IssuesInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Facebook.API.IssuesInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    error: jspb.Message.getFieldWithDefault(msg, 1, ""),
    message: jspb.Message.getFieldWithDefault(msg, 2, ""),
    summary: jspb.Message.getFieldWithDefault(msg, 3, ""),
    type: jspb.Message.getFieldWithDefault(msg, 4, ""),
    level: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.Facebook.API.IssuesInfo}
 */
proto.common.Facebook.API.IssuesInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.Facebook.API.IssuesInfo;
  return proto.common.Facebook.API.IssuesInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.Facebook.API.IssuesInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.Facebook.API.IssuesInfo}
 */
proto.common.Facebook.API.IssuesInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSummary(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setLevel(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.Facebook.API.IssuesInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.Facebook.API.IssuesInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.Facebook.API.IssuesInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Facebook.API.IssuesInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getError();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSummary();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getLevel();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string error = 1;
 * @return {string}
 */
proto.common.Facebook.API.IssuesInfo.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Facebook.API.IssuesInfo} returns this
 */
proto.common.Facebook.API.IssuesInfo.prototype.setError = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string message = 2;
 * @return {string}
 */
proto.common.Facebook.API.IssuesInfo.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Facebook.API.IssuesInfo} returns this
 */
proto.common.Facebook.API.IssuesInfo.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string summary = 3;
 * @return {string}
 */
proto.common.Facebook.API.IssuesInfo.prototype.getSummary = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Facebook.API.IssuesInfo} returns this
 */
proto.common.Facebook.API.IssuesInfo.prototype.setSummary = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string type = 4;
 * @return {string}
 */
proto.common.Facebook.API.IssuesInfo.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Facebook.API.IssuesInfo} returns this
 */
proto.common.Facebook.API.IssuesInfo.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string level = 5;
 * @return {string}
 */
proto.common.Facebook.API.IssuesInfo.prototype.getLevel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Facebook.API.IssuesInfo} returns this
 */
proto.common.Facebook.API.IssuesInfo.prototype.setLevel = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.common.Facebook.API.Insight.repeatedFields_ = [10];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.Facebook.API.Insight.prototype.toObject = function(opt_includeInstance) {
  return proto.common.Facebook.API.Insight.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.Facebook.API.Insight} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Facebook.API.Insight.toObject = function(includeInstance, msg) {
  var f, obj = {
    clicks: jspb.Message.getFieldWithDefault(msg, 1, ""),
    impressions: jspb.Message.getFieldWithDefault(msg, 2, ""),
    spend: jspb.Message.getFieldWithDefault(msg, 3, ""),
    dateStop: jspb.Message.getFieldWithDefault(msg, 4, ""),
    dateStart: jspb.Message.getFieldWithDefault(msg, 5, ""),
    adId: jspb.Message.getFieldWithDefault(msg, 6, ""),
    adSetId: jspb.Message.getFieldWithDefault(msg, 7, ""),
    campaignId: jspb.Message.getFieldWithDefault(msg, 8, ""),
    accountId: jspb.Message.getFieldWithDefault(msg, 9, ""),
    outboundClicksList: jspb.Message.toObjectList(msg.getOutboundClicksList(),
    proto.common.Facebook.API.Insight.OutboundClicks.toObject, includeInstance),
    accountCurrency: jspb.Message.getFieldWithDefault(msg, 11, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.Facebook.API.Insight}
 */
proto.common.Facebook.API.Insight.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.Facebook.API.Insight;
  return proto.common.Facebook.API.Insight.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.Facebook.API.Insight} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.Facebook.API.Insight}
 */
proto.common.Facebook.API.Insight.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setClicks(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setImpressions(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSpend(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDateStop(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDateStart(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdId(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdSetId(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setCampaignId(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountId(value);
      break;
    case 10:
      var value = new proto.common.Facebook.API.Insight.OutboundClicks;
      reader.readMessage(value,proto.common.Facebook.API.Insight.OutboundClicks.deserializeBinaryFromReader);
      msg.addOutboundClicks(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountCurrency(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.Facebook.API.Insight.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.Facebook.API.Insight.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.Facebook.API.Insight} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Facebook.API.Insight.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getClicks();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getImpressions();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSpend();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDateStop();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDateStart();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getAdId();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getAdSetId();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getCampaignId();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getAccountId();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getOutboundClicksList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      proto.common.Facebook.API.Insight.OutboundClicks.serializeBinaryToWriter
    );
  }
  f = message.getAccountCurrency();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.Facebook.API.Insight.OutboundClicks.prototype.toObject = function(opt_includeInstance) {
  return proto.common.Facebook.API.Insight.OutboundClicks.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.Facebook.API.Insight.OutboundClicks} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Facebook.API.Insight.OutboundClicks.toObject = function(includeInstance, msg) {
  var f, obj = {
    actionType: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.Facebook.API.Insight.OutboundClicks}
 */
proto.common.Facebook.API.Insight.OutboundClicks.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.Facebook.API.Insight.OutboundClicks;
  return proto.common.Facebook.API.Insight.OutboundClicks.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.Facebook.API.Insight.OutboundClicks} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.Facebook.API.Insight.OutboundClicks}
 */
proto.common.Facebook.API.Insight.OutboundClicks.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setActionType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.Facebook.API.Insight.OutboundClicks.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.Facebook.API.Insight.OutboundClicks.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.Facebook.API.Insight.OutboundClicks} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Facebook.API.Insight.OutboundClicks.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getActionType();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string action_type = 1;
 * @return {string}
 */
proto.common.Facebook.API.Insight.OutboundClicks.prototype.getActionType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Facebook.API.Insight.OutboundClicks} returns this
 */
proto.common.Facebook.API.Insight.OutboundClicks.prototype.setActionType = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string value = 2;
 * @return {string}
 */
proto.common.Facebook.API.Insight.OutboundClicks.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Facebook.API.Insight.OutboundClicks} returns this
 */
proto.common.Facebook.API.Insight.OutboundClicks.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string clicks = 1;
 * @return {string}
 */
proto.common.Facebook.API.Insight.prototype.getClicks = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Facebook.API.Insight} returns this
 */
proto.common.Facebook.API.Insight.prototype.setClicks = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string impressions = 2;
 * @return {string}
 */
proto.common.Facebook.API.Insight.prototype.getImpressions = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Facebook.API.Insight} returns this
 */
proto.common.Facebook.API.Insight.prototype.setImpressions = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string spend = 3;
 * @return {string}
 */
proto.common.Facebook.API.Insight.prototype.getSpend = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Facebook.API.Insight} returns this
 */
proto.common.Facebook.API.Insight.prototype.setSpend = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string date_stop = 4;
 * @return {string}
 */
proto.common.Facebook.API.Insight.prototype.getDateStop = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Facebook.API.Insight} returns this
 */
proto.common.Facebook.API.Insight.prototype.setDateStop = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string date_start = 5;
 * @return {string}
 */
proto.common.Facebook.API.Insight.prototype.getDateStart = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Facebook.API.Insight} returns this
 */
proto.common.Facebook.API.Insight.prototype.setDateStart = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string ad_id = 6;
 * @return {string}
 */
proto.common.Facebook.API.Insight.prototype.getAdId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Facebook.API.Insight} returns this
 */
proto.common.Facebook.API.Insight.prototype.setAdId = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string ad_set_id = 7;
 * @return {string}
 */
proto.common.Facebook.API.Insight.prototype.getAdSetId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Facebook.API.Insight} returns this
 */
proto.common.Facebook.API.Insight.prototype.setAdSetId = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string campaign_id = 8;
 * @return {string}
 */
proto.common.Facebook.API.Insight.prototype.getCampaignId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Facebook.API.Insight} returns this
 */
proto.common.Facebook.API.Insight.prototype.setCampaignId = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string account_id = 9;
 * @return {string}
 */
proto.common.Facebook.API.Insight.prototype.getAccountId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Facebook.API.Insight} returns this
 */
proto.common.Facebook.API.Insight.prototype.setAccountId = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * repeated OutboundClicks outbound_clicks = 10;
 * @return {!Array<!proto.common.Facebook.API.Insight.OutboundClicks>}
 */
proto.common.Facebook.API.Insight.prototype.getOutboundClicksList = function() {
  return /** @type{!Array<!proto.common.Facebook.API.Insight.OutboundClicks>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.common.Facebook.API.Insight.OutboundClicks, 10));
};


/**
 * @param {!Array<!proto.common.Facebook.API.Insight.OutboundClicks>} value
 * @return {!proto.common.Facebook.API.Insight} returns this
*/
proto.common.Facebook.API.Insight.prototype.setOutboundClicksList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.common.Facebook.API.Insight.OutboundClicks=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.Facebook.API.Insight.OutboundClicks}
 */
proto.common.Facebook.API.Insight.prototype.addOutboundClicks = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.common.Facebook.API.Insight.OutboundClicks, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.common.Facebook.API.Insight} returns this
 */
proto.common.Facebook.API.Insight.prototype.clearOutboundClicksList = function() {
  return this.setOutboundClicksList([]);
};


/**
 * optional string account_currency = 11;
 * @return {string}
 */
proto.common.Facebook.API.Insight.prototype.getAccountCurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Facebook.API.Insight} returns this
 */
proto.common.Facebook.API.Insight.prototype.setAccountCurrency = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.Facebook.API.InsightLevel.prototype.toObject = function(opt_includeInstance) {
  return proto.common.Facebook.API.InsightLevel.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.Facebook.API.InsightLevel} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Facebook.API.InsightLevel.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.Facebook.API.InsightLevel}
 */
proto.common.Facebook.API.InsightLevel.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.Facebook.API.InsightLevel;
  return proto.common.Facebook.API.InsightLevel.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.Facebook.API.InsightLevel} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.Facebook.API.InsightLevel}
 */
proto.common.Facebook.API.InsightLevel.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.Facebook.API.InsightLevel.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.Facebook.API.InsightLevel.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.Facebook.API.InsightLevel} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Facebook.API.InsightLevel.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


/**
 * @enum {number}
 */
proto.common.Facebook.API.InsightLevel.Option = {
  UNSPECIFIED: 0,
  AD: 1,
  ADSET: 2,
  CAMPAIGN: 3,
  ACCOUNT: 4
};




if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.Facebook.API.DatePreset.prototype.toObject = function(opt_includeInstance) {
  return proto.common.Facebook.API.DatePreset.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.Facebook.API.DatePreset} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Facebook.API.DatePreset.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.Facebook.API.DatePreset}
 */
proto.common.Facebook.API.DatePreset.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.Facebook.API.DatePreset;
  return proto.common.Facebook.API.DatePreset.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.Facebook.API.DatePreset} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.Facebook.API.DatePreset}
 */
proto.common.Facebook.API.DatePreset.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.Facebook.API.DatePreset.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.Facebook.API.DatePreset.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.Facebook.API.DatePreset} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Facebook.API.DatePreset.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


/**
 * @enum {number}
 */
proto.common.Facebook.API.DatePreset.Option = {
  UNSPECIFIED: 0,
  TODAY: 1,
  YESTERDAY: 2,
  THIS_MONTH: 3,
  LAST_MONTH: 4,
  THIS_QUARTER: 5,
  MAXIMUM: 6,
  DATA_MAXIMUM: 7,
  LAST_3D: 8,
  LAST_7D: 9,
  LAST_14D: 10,
  LAST_28D: 11,
  LAST_30D: 12,
  LAST_90D: 13,
  LAST_WEEK_MON_SUN: 14,
  LAST_WEEK_SUN_SAT: 15,
  LAST_QUARTER: 16,
  LAST_YEAR: 17,
  THIS_WEEK_MON_TODAY: 18,
  THIS_WEEK_SUN_TODAY: 19,
  THIS_YEAR: 20
};


/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.common.Facebook.API.AssetFeedSpec.repeatedFields_ = [1,2,3,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.Facebook.API.AssetFeedSpec.prototype.toObject = function(opt_includeInstance) {
  return proto.common.Facebook.API.AssetFeedSpec.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.Facebook.API.AssetFeedSpec} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Facebook.API.AssetFeedSpec.toObject = function(includeInstance, msg) {
  var f, obj = {
    adFormatsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    bodiesList: jspb.Message.toObjectList(msg.getBodiesList(),
    proto.common.Facebook.API.AssetFeedSpec.Body.toObject, includeInstance),
    linkUrlsList: jspb.Message.toObjectList(msg.getLinkUrlsList(),
    proto.common.Facebook.API.AssetFeedSpec.LinkUrl.toObject, includeInstance),
    titlesList: jspb.Message.toObjectList(msg.getTitlesList(),
    proto.common.Facebook.API.AssetFeedSpec.Title.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.Facebook.API.AssetFeedSpec}
 */
proto.common.Facebook.API.AssetFeedSpec.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.Facebook.API.AssetFeedSpec;
  return proto.common.Facebook.API.AssetFeedSpec.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.Facebook.API.AssetFeedSpec} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.Facebook.API.AssetFeedSpec}
 */
proto.common.Facebook.API.AssetFeedSpec.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<!proto.common.Facebook.API.AssetFeedSpec.AdFormat.Option>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addAdFormats(values[i]);
      }
      break;
    case 2:
      var value = new proto.common.Facebook.API.AssetFeedSpec.Body;
      reader.readMessage(value,proto.common.Facebook.API.AssetFeedSpec.Body.deserializeBinaryFromReader);
      msg.addBodies(value);
      break;
    case 3:
      var value = new proto.common.Facebook.API.AssetFeedSpec.LinkUrl;
      reader.readMessage(value,proto.common.Facebook.API.AssetFeedSpec.LinkUrl.deserializeBinaryFromReader);
      msg.addLinkUrls(value);
      break;
    case 4:
      var value = new proto.common.Facebook.API.AssetFeedSpec.Title;
      reader.readMessage(value,proto.common.Facebook.API.AssetFeedSpec.Title.deserializeBinaryFromReader);
      msg.addTitles(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.Facebook.API.AssetFeedSpec.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.Facebook.API.AssetFeedSpec.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.Facebook.API.AssetFeedSpec} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Facebook.API.AssetFeedSpec.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAdFormatsList();
  if (f.length > 0) {
    writer.writePackedEnum(
      1,
      f
    );
  }
  f = message.getBodiesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.common.Facebook.API.AssetFeedSpec.Body.serializeBinaryToWriter
    );
  }
  f = message.getLinkUrlsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.common.Facebook.API.AssetFeedSpec.LinkUrl.serializeBinaryToWriter
    );
  }
  f = message.getTitlesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.common.Facebook.API.AssetFeedSpec.Title.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.Facebook.API.AssetFeedSpec.AdFormat.prototype.toObject = function(opt_includeInstance) {
  return proto.common.Facebook.API.AssetFeedSpec.AdFormat.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.Facebook.API.AssetFeedSpec.AdFormat} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Facebook.API.AssetFeedSpec.AdFormat.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.Facebook.API.AssetFeedSpec.AdFormat}
 */
proto.common.Facebook.API.AssetFeedSpec.AdFormat.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.Facebook.API.AssetFeedSpec.AdFormat;
  return proto.common.Facebook.API.AssetFeedSpec.AdFormat.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.Facebook.API.AssetFeedSpec.AdFormat} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.Facebook.API.AssetFeedSpec.AdFormat}
 */
proto.common.Facebook.API.AssetFeedSpec.AdFormat.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.Facebook.API.AssetFeedSpec.AdFormat.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.Facebook.API.AssetFeedSpec.AdFormat.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.Facebook.API.AssetFeedSpec.AdFormat} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Facebook.API.AssetFeedSpec.AdFormat.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


/**
 * @enum {number}
 */
proto.common.Facebook.API.AssetFeedSpec.AdFormat.Option = {
  UNKNOWN: 0,
  SINGLE_IMAGE: 1,
  CAROUSEL: 2,
  SINGLE_VIDEO: 3,
  AUTOMATIC_FORMAT: 4
};


/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.common.Facebook.API.AssetFeedSpec.Body.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.Facebook.API.AssetFeedSpec.Body.prototype.toObject = function(opt_includeInstance) {
  return proto.common.Facebook.API.AssetFeedSpec.Body.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.Facebook.API.AssetFeedSpec.Body} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Facebook.API.AssetFeedSpec.Body.toObject = function(includeInstance, msg) {
  var f, obj = {
    adLabelsList: jspb.Message.toObjectList(msg.getAdLabelsList(),
    proto.common.Facebook.API.AdLabel.toObject, includeInstance),
    text: jspb.Message.getFieldWithDefault(msg, 2, ""),
    urlTags: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.Facebook.API.AssetFeedSpec.Body}
 */
proto.common.Facebook.API.AssetFeedSpec.Body.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.Facebook.API.AssetFeedSpec.Body;
  return proto.common.Facebook.API.AssetFeedSpec.Body.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.Facebook.API.AssetFeedSpec.Body} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.Facebook.API.AssetFeedSpec.Body}
 */
proto.common.Facebook.API.AssetFeedSpec.Body.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.common.Facebook.API.AdLabel;
      reader.readMessage(value,proto.common.Facebook.API.AdLabel.deserializeBinaryFromReader);
      msg.addAdLabels(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setText(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrlTags(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.Facebook.API.AssetFeedSpec.Body.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.Facebook.API.AssetFeedSpec.Body.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.Facebook.API.AssetFeedSpec.Body} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Facebook.API.AssetFeedSpec.Body.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAdLabelsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.common.Facebook.API.AdLabel.serializeBinaryToWriter
    );
  }
  f = message.getText();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUrlTags();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * repeated AdLabel ad_labels = 1;
 * @return {!Array<!proto.common.Facebook.API.AdLabel>}
 */
proto.common.Facebook.API.AssetFeedSpec.Body.prototype.getAdLabelsList = function() {
  return /** @type{!Array<!proto.common.Facebook.API.AdLabel>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.common.Facebook.API.AdLabel, 1));
};


/**
 * @param {!Array<!proto.common.Facebook.API.AdLabel>} value
 * @return {!proto.common.Facebook.API.AssetFeedSpec.Body} returns this
*/
proto.common.Facebook.API.AssetFeedSpec.Body.prototype.setAdLabelsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.common.Facebook.API.AdLabel=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.Facebook.API.AdLabel}
 */
proto.common.Facebook.API.AssetFeedSpec.Body.prototype.addAdLabels = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.common.Facebook.API.AdLabel, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.common.Facebook.API.AssetFeedSpec.Body} returns this
 */
proto.common.Facebook.API.AssetFeedSpec.Body.prototype.clearAdLabelsList = function() {
  return this.setAdLabelsList([]);
};


/**
 * optional string text = 2;
 * @return {string}
 */
proto.common.Facebook.API.AssetFeedSpec.Body.prototype.getText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Facebook.API.AssetFeedSpec.Body} returns this
 */
proto.common.Facebook.API.AssetFeedSpec.Body.prototype.setText = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string url_tags = 3;
 * @return {string}
 */
proto.common.Facebook.API.AssetFeedSpec.Body.prototype.getUrlTags = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Facebook.API.AssetFeedSpec.Body} returns this
 */
proto.common.Facebook.API.AssetFeedSpec.Body.prototype.setUrlTags = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.common.Facebook.API.AssetFeedSpec.LinkUrl.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.Facebook.API.AssetFeedSpec.LinkUrl.prototype.toObject = function(opt_includeInstance) {
  return proto.common.Facebook.API.AssetFeedSpec.LinkUrl.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.Facebook.API.AssetFeedSpec.LinkUrl} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Facebook.API.AssetFeedSpec.LinkUrl.toObject = function(includeInstance, msg) {
  var f, obj = {
    adLabelsList: jspb.Message.toObjectList(msg.getAdLabelsList(),
    proto.common.Facebook.API.AdLabel.toObject, includeInstance),
    carouselSeeMoreUrl: jspb.Message.getFieldWithDefault(msg, 2, ""),
    deeplinkUrl: jspb.Message.getFieldWithDefault(msg, 3, ""),
    displayUrl: jspb.Message.getFieldWithDefault(msg, 4, ""),
    urlTags: jspb.Message.getFieldWithDefault(msg, 5, ""),
    websiteUrl: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.Facebook.API.AssetFeedSpec.LinkUrl}
 */
proto.common.Facebook.API.AssetFeedSpec.LinkUrl.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.Facebook.API.AssetFeedSpec.LinkUrl;
  return proto.common.Facebook.API.AssetFeedSpec.LinkUrl.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.Facebook.API.AssetFeedSpec.LinkUrl} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.Facebook.API.AssetFeedSpec.LinkUrl}
 */
proto.common.Facebook.API.AssetFeedSpec.LinkUrl.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.common.Facebook.API.AdLabel;
      reader.readMessage(value,proto.common.Facebook.API.AdLabel.deserializeBinaryFromReader);
      msg.addAdLabels(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCarouselSeeMoreUrl(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeeplinkUrl(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayUrl(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrlTags(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setWebsiteUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.Facebook.API.AssetFeedSpec.LinkUrl.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.Facebook.API.AssetFeedSpec.LinkUrl.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.Facebook.API.AssetFeedSpec.LinkUrl} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Facebook.API.AssetFeedSpec.LinkUrl.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAdLabelsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.common.Facebook.API.AdLabel.serializeBinaryToWriter
    );
  }
  f = message.getCarouselSeeMoreUrl();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDeeplinkUrl();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDisplayUrl();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getUrlTags();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getWebsiteUrl();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * repeated AdLabel ad_labels = 1;
 * @return {!Array<!proto.common.Facebook.API.AdLabel>}
 */
proto.common.Facebook.API.AssetFeedSpec.LinkUrl.prototype.getAdLabelsList = function() {
  return /** @type{!Array<!proto.common.Facebook.API.AdLabel>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.common.Facebook.API.AdLabel, 1));
};


/**
 * @param {!Array<!proto.common.Facebook.API.AdLabel>} value
 * @return {!proto.common.Facebook.API.AssetFeedSpec.LinkUrl} returns this
*/
proto.common.Facebook.API.AssetFeedSpec.LinkUrl.prototype.setAdLabelsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.common.Facebook.API.AdLabel=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.Facebook.API.AdLabel}
 */
proto.common.Facebook.API.AssetFeedSpec.LinkUrl.prototype.addAdLabels = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.common.Facebook.API.AdLabel, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.common.Facebook.API.AssetFeedSpec.LinkUrl} returns this
 */
proto.common.Facebook.API.AssetFeedSpec.LinkUrl.prototype.clearAdLabelsList = function() {
  return this.setAdLabelsList([]);
};


/**
 * optional string carousel_see_more_url = 2;
 * @return {string}
 */
proto.common.Facebook.API.AssetFeedSpec.LinkUrl.prototype.getCarouselSeeMoreUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Facebook.API.AssetFeedSpec.LinkUrl} returns this
 */
proto.common.Facebook.API.AssetFeedSpec.LinkUrl.prototype.setCarouselSeeMoreUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string deeplink_url = 3;
 * @return {string}
 */
proto.common.Facebook.API.AssetFeedSpec.LinkUrl.prototype.getDeeplinkUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Facebook.API.AssetFeedSpec.LinkUrl} returns this
 */
proto.common.Facebook.API.AssetFeedSpec.LinkUrl.prototype.setDeeplinkUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string display_url = 4;
 * @return {string}
 */
proto.common.Facebook.API.AssetFeedSpec.LinkUrl.prototype.getDisplayUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Facebook.API.AssetFeedSpec.LinkUrl} returns this
 */
proto.common.Facebook.API.AssetFeedSpec.LinkUrl.prototype.setDisplayUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string url_tags = 5;
 * @return {string}
 */
proto.common.Facebook.API.AssetFeedSpec.LinkUrl.prototype.getUrlTags = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Facebook.API.AssetFeedSpec.LinkUrl} returns this
 */
proto.common.Facebook.API.AssetFeedSpec.LinkUrl.prototype.setUrlTags = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string website_url = 6;
 * @return {string}
 */
proto.common.Facebook.API.AssetFeedSpec.LinkUrl.prototype.getWebsiteUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Facebook.API.AssetFeedSpec.LinkUrl} returns this
 */
proto.common.Facebook.API.AssetFeedSpec.LinkUrl.prototype.setWebsiteUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.common.Facebook.API.AssetFeedSpec.Title.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.Facebook.API.AssetFeedSpec.Title.prototype.toObject = function(opt_includeInstance) {
  return proto.common.Facebook.API.AssetFeedSpec.Title.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.Facebook.API.AssetFeedSpec.Title} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Facebook.API.AssetFeedSpec.Title.toObject = function(includeInstance, msg) {
  var f, obj = {
    adLabelsList: jspb.Message.toObjectList(msg.getAdLabelsList(),
    proto.common.Facebook.API.AdLabel.toObject, includeInstance),
    text: jspb.Message.getFieldWithDefault(msg, 2, ""),
    urlTags: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.Facebook.API.AssetFeedSpec.Title}
 */
proto.common.Facebook.API.AssetFeedSpec.Title.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.Facebook.API.AssetFeedSpec.Title;
  return proto.common.Facebook.API.AssetFeedSpec.Title.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.Facebook.API.AssetFeedSpec.Title} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.Facebook.API.AssetFeedSpec.Title}
 */
proto.common.Facebook.API.AssetFeedSpec.Title.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.common.Facebook.API.AdLabel;
      reader.readMessage(value,proto.common.Facebook.API.AdLabel.deserializeBinaryFromReader);
      msg.addAdLabels(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setText(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrlTags(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.Facebook.API.AssetFeedSpec.Title.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.Facebook.API.AssetFeedSpec.Title.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.Facebook.API.AssetFeedSpec.Title} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Facebook.API.AssetFeedSpec.Title.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAdLabelsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.common.Facebook.API.AdLabel.serializeBinaryToWriter
    );
  }
  f = message.getText();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUrlTags();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * repeated AdLabel ad_labels = 1;
 * @return {!Array<!proto.common.Facebook.API.AdLabel>}
 */
proto.common.Facebook.API.AssetFeedSpec.Title.prototype.getAdLabelsList = function() {
  return /** @type{!Array<!proto.common.Facebook.API.AdLabel>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.common.Facebook.API.AdLabel, 1));
};


/**
 * @param {!Array<!proto.common.Facebook.API.AdLabel>} value
 * @return {!proto.common.Facebook.API.AssetFeedSpec.Title} returns this
*/
proto.common.Facebook.API.AssetFeedSpec.Title.prototype.setAdLabelsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.common.Facebook.API.AdLabel=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.Facebook.API.AdLabel}
 */
proto.common.Facebook.API.AssetFeedSpec.Title.prototype.addAdLabels = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.common.Facebook.API.AdLabel, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.common.Facebook.API.AssetFeedSpec.Title} returns this
 */
proto.common.Facebook.API.AssetFeedSpec.Title.prototype.clearAdLabelsList = function() {
  return this.setAdLabelsList([]);
};


/**
 * optional string text = 2;
 * @return {string}
 */
proto.common.Facebook.API.AssetFeedSpec.Title.prototype.getText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Facebook.API.AssetFeedSpec.Title} returns this
 */
proto.common.Facebook.API.AssetFeedSpec.Title.prototype.setText = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string url_tags = 3;
 * @return {string}
 */
proto.common.Facebook.API.AssetFeedSpec.Title.prototype.getUrlTags = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Facebook.API.AssetFeedSpec.Title} returns this
 */
proto.common.Facebook.API.AssetFeedSpec.Title.prototype.setUrlTags = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated AdFormat.Option ad_formats = 1;
 * @return {!Array<!proto.common.Facebook.API.AssetFeedSpec.AdFormat.Option>}
 */
proto.common.Facebook.API.AssetFeedSpec.prototype.getAdFormatsList = function() {
  return /** @type {!Array<!proto.common.Facebook.API.AssetFeedSpec.AdFormat.Option>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<!proto.common.Facebook.API.AssetFeedSpec.AdFormat.Option>} value
 * @return {!proto.common.Facebook.API.AssetFeedSpec} returns this
 */
proto.common.Facebook.API.AssetFeedSpec.prototype.setAdFormatsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {!proto.common.Facebook.API.AssetFeedSpec.AdFormat.Option} value
 * @param {number=} opt_index
 * @return {!proto.common.Facebook.API.AssetFeedSpec} returns this
 */
proto.common.Facebook.API.AssetFeedSpec.prototype.addAdFormats = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.common.Facebook.API.AssetFeedSpec} returns this
 */
proto.common.Facebook.API.AssetFeedSpec.prototype.clearAdFormatsList = function() {
  return this.setAdFormatsList([]);
};


/**
 * repeated Body bodies = 2;
 * @return {!Array<!proto.common.Facebook.API.AssetFeedSpec.Body>}
 */
proto.common.Facebook.API.AssetFeedSpec.prototype.getBodiesList = function() {
  return /** @type{!Array<!proto.common.Facebook.API.AssetFeedSpec.Body>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.common.Facebook.API.AssetFeedSpec.Body, 2));
};


/**
 * @param {!Array<!proto.common.Facebook.API.AssetFeedSpec.Body>} value
 * @return {!proto.common.Facebook.API.AssetFeedSpec} returns this
*/
proto.common.Facebook.API.AssetFeedSpec.prototype.setBodiesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.common.Facebook.API.AssetFeedSpec.Body=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.Facebook.API.AssetFeedSpec.Body}
 */
proto.common.Facebook.API.AssetFeedSpec.prototype.addBodies = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.common.Facebook.API.AssetFeedSpec.Body, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.common.Facebook.API.AssetFeedSpec} returns this
 */
proto.common.Facebook.API.AssetFeedSpec.prototype.clearBodiesList = function() {
  return this.setBodiesList([]);
};


/**
 * repeated LinkUrl link_urls = 3;
 * @return {!Array<!proto.common.Facebook.API.AssetFeedSpec.LinkUrl>}
 */
proto.common.Facebook.API.AssetFeedSpec.prototype.getLinkUrlsList = function() {
  return /** @type{!Array<!proto.common.Facebook.API.AssetFeedSpec.LinkUrl>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.common.Facebook.API.AssetFeedSpec.LinkUrl, 3));
};


/**
 * @param {!Array<!proto.common.Facebook.API.AssetFeedSpec.LinkUrl>} value
 * @return {!proto.common.Facebook.API.AssetFeedSpec} returns this
*/
proto.common.Facebook.API.AssetFeedSpec.prototype.setLinkUrlsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.common.Facebook.API.AssetFeedSpec.LinkUrl=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.Facebook.API.AssetFeedSpec.LinkUrl}
 */
proto.common.Facebook.API.AssetFeedSpec.prototype.addLinkUrls = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.common.Facebook.API.AssetFeedSpec.LinkUrl, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.common.Facebook.API.AssetFeedSpec} returns this
 */
proto.common.Facebook.API.AssetFeedSpec.prototype.clearLinkUrlsList = function() {
  return this.setLinkUrlsList([]);
};


/**
 * repeated Title titles = 4;
 * @return {!Array<!proto.common.Facebook.API.AssetFeedSpec.Title>}
 */
proto.common.Facebook.API.AssetFeedSpec.prototype.getTitlesList = function() {
  return /** @type{!Array<!proto.common.Facebook.API.AssetFeedSpec.Title>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.common.Facebook.API.AssetFeedSpec.Title, 4));
};


/**
 * @param {!Array<!proto.common.Facebook.API.AssetFeedSpec.Title>} value
 * @return {!proto.common.Facebook.API.AssetFeedSpec} returns this
*/
proto.common.Facebook.API.AssetFeedSpec.prototype.setTitlesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.common.Facebook.API.AssetFeedSpec.Title=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.Facebook.API.AssetFeedSpec.Title}
 */
proto.common.Facebook.API.AssetFeedSpec.prototype.addTitles = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.common.Facebook.API.AssetFeedSpec.Title, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.common.Facebook.API.AssetFeedSpec} returns this
 */
proto.common.Facebook.API.AssetFeedSpec.prototype.clearTitlesList = function() {
  return this.setTitlesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.Facebook.API.ObjectStorySpec.prototype.toObject = function(opt_includeInstance) {
  return proto.common.Facebook.API.ObjectStorySpec.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.Facebook.API.ObjectStorySpec} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Facebook.API.ObjectStorySpec.toObject = function(includeInstance, msg) {
  var f, obj = {
    linkData: (f = msg.getLinkData()) && proto.common.Facebook.API.LinkData.toObject(includeInstance, f),
    templateData: (f = msg.getTemplateData()) && proto.common.Facebook.API.LinkData.toObject(includeInstance, f),
    videoData: (f = msg.getVideoData()) && proto.common.Facebook.API.AdCreative.VideoData.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.Facebook.API.ObjectStorySpec}
 */
proto.common.Facebook.API.ObjectStorySpec.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.Facebook.API.ObjectStorySpec;
  return proto.common.Facebook.API.ObjectStorySpec.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.Facebook.API.ObjectStorySpec} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.Facebook.API.ObjectStorySpec}
 */
proto.common.Facebook.API.ObjectStorySpec.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.common.Facebook.API.LinkData;
      reader.readMessage(value,proto.common.Facebook.API.LinkData.deserializeBinaryFromReader);
      msg.setLinkData(value);
      break;
    case 2:
      var value = new proto.common.Facebook.API.LinkData;
      reader.readMessage(value,proto.common.Facebook.API.LinkData.deserializeBinaryFromReader);
      msg.setTemplateData(value);
      break;
    case 3:
      var value = new proto.common.Facebook.API.AdCreative.VideoData;
      reader.readMessage(value,proto.common.Facebook.API.AdCreative.VideoData.deserializeBinaryFromReader);
      msg.setVideoData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.Facebook.API.ObjectStorySpec.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.Facebook.API.ObjectStorySpec.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.Facebook.API.ObjectStorySpec} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Facebook.API.ObjectStorySpec.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLinkData();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.common.Facebook.API.LinkData.serializeBinaryToWriter
    );
  }
  f = message.getTemplateData();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.common.Facebook.API.LinkData.serializeBinaryToWriter
    );
  }
  f = message.getVideoData();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.common.Facebook.API.AdCreative.VideoData.serializeBinaryToWriter
    );
  }
};


/**
 * optional LinkData link_data = 1;
 * @return {?proto.common.Facebook.API.LinkData}
 */
proto.common.Facebook.API.ObjectStorySpec.prototype.getLinkData = function() {
  return /** @type{?proto.common.Facebook.API.LinkData} */ (
    jspb.Message.getWrapperField(this, proto.common.Facebook.API.LinkData, 1));
};


/**
 * @param {?proto.common.Facebook.API.LinkData|undefined} value
 * @return {!proto.common.Facebook.API.ObjectStorySpec} returns this
*/
proto.common.Facebook.API.ObjectStorySpec.prototype.setLinkData = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.Facebook.API.ObjectStorySpec} returns this
 */
proto.common.Facebook.API.ObjectStorySpec.prototype.clearLinkData = function() {
  return this.setLinkData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.Facebook.API.ObjectStorySpec.prototype.hasLinkData = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional LinkData template_data = 2;
 * @return {?proto.common.Facebook.API.LinkData}
 */
proto.common.Facebook.API.ObjectStorySpec.prototype.getTemplateData = function() {
  return /** @type{?proto.common.Facebook.API.LinkData} */ (
    jspb.Message.getWrapperField(this, proto.common.Facebook.API.LinkData, 2));
};


/**
 * @param {?proto.common.Facebook.API.LinkData|undefined} value
 * @return {!proto.common.Facebook.API.ObjectStorySpec} returns this
*/
proto.common.Facebook.API.ObjectStorySpec.prototype.setTemplateData = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.Facebook.API.ObjectStorySpec} returns this
 */
proto.common.Facebook.API.ObjectStorySpec.prototype.clearTemplateData = function() {
  return this.setTemplateData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.Facebook.API.ObjectStorySpec.prototype.hasTemplateData = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional AdCreative.VideoData video_data = 3;
 * @return {?proto.common.Facebook.API.AdCreative.VideoData}
 */
proto.common.Facebook.API.ObjectStorySpec.prototype.getVideoData = function() {
  return /** @type{?proto.common.Facebook.API.AdCreative.VideoData} */ (
    jspb.Message.getWrapperField(this, proto.common.Facebook.API.AdCreative.VideoData, 3));
};


/**
 * @param {?proto.common.Facebook.API.AdCreative.VideoData|undefined} value
 * @return {!proto.common.Facebook.API.ObjectStorySpec} returns this
*/
proto.common.Facebook.API.ObjectStorySpec.prototype.setVideoData = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.Facebook.API.ObjectStorySpec} returns this
 */
proto.common.Facebook.API.ObjectStorySpec.prototype.clearVideoData = function() {
  return this.setVideoData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.Facebook.API.ObjectStorySpec.prototype.hasVideoData = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.Facebook.API.LinkData.prototype.toObject = function(opt_includeInstance) {
  return proto.common.Facebook.API.LinkData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.Facebook.API.LinkData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Facebook.API.LinkData.toObject = function(includeInstance, msg) {
  var f, obj = {
    link: jspb.Message.getFieldWithDefault(msg, 1, ""),
    message: jspb.Message.getFieldWithDefault(msg, 2, ""),
    name: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.Facebook.API.LinkData}
 */
proto.common.Facebook.API.LinkData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.Facebook.API.LinkData;
  return proto.common.Facebook.API.LinkData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.Facebook.API.LinkData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.Facebook.API.LinkData}
 */
proto.common.Facebook.API.LinkData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLink(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.Facebook.API.LinkData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.Facebook.API.LinkData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.Facebook.API.LinkData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Facebook.API.LinkData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLink();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string link = 1;
 * @return {string}
 */
proto.common.Facebook.API.LinkData.prototype.getLink = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Facebook.API.LinkData} returns this
 */
proto.common.Facebook.API.LinkData.prototype.setLink = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string message = 2;
 * @return {string}
 */
proto.common.Facebook.API.LinkData.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Facebook.API.LinkData} returns this
 */
proto.common.Facebook.API.LinkData.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.common.Facebook.API.LinkData.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Facebook.API.LinkData} returns this
 */
proto.common.Facebook.API.LinkData.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.Facebook.API.UpdateStatus.prototype.toObject = function(opt_includeInstance) {
  return proto.common.Facebook.API.UpdateStatus.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.Facebook.API.UpdateStatus} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Facebook.API.UpdateStatus.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.Facebook.API.UpdateStatus}
 */
proto.common.Facebook.API.UpdateStatus.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.Facebook.API.UpdateStatus;
  return proto.common.Facebook.API.UpdateStatus.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.Facebook.API.UpdateStatus} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.Facebook.API.UpdateStatus}
 */
proto.common.Facebook.API.UpdateStatus.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.Facebook.API.UpdateStatus.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.Facebook.API.UpdateStatus.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.Facebook.API.UpdateStatus} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Facebook.API.UpdateStatus.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


/**
 * @enum {number}
 */
proto.common.Facebook.API.UpdateStatus.Option = {
  PAUSED: 0,
  ACTIVE: 1
};

goog.object.extend(exports, proto.common);
