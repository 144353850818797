import { formatMetric } from "Common/utils/metrics";
import { getCurrencyMetricDef } from "Common/utils/money";
import {
  FormattedValue,
  fractionalNumberMetricDef,
  oneDecimalPercentageMetricDef,
  roundedNumberMetricDef,
  roundedPercentageMetricDef
} from "ExtensionV2/components/MetricColumns";
import {
  MetricCell,
  CompareStyle,
  lessIsBetter,
  moreIsBetter,
  trueNeutral
} from "./cells/MetricCell";
import { SimpleCell } from "./cells/SimpleCell";
import { FilterArguments, NumberFilterUI, StringFilterUI } from "./filters";
import { numberFilterFn, stringFilterFn } from "./filters";
import { numberSort, SortOption, stringSort } from "./sorting";

export type MetricsTableCell = (props: {
  uniqKey: string;
  val: unknown;
  prev?: unknown;
  formatFn?: (rawVal: unknown, showFraction: boolean) => string;
  compareStyles?: Array<CompareStyle>;
}) => JSX.Element;

export class Column<TData> {
  // The key of the data in the row object, ex: "addToCartClicks"
  readonly dataName;
  // The name of the column to display in the table, ex: "Add to Cart Clicks"
  readonly displayName;
  // A short explainer about the column the will be displayed in the column header under the display name.
  readonly displaySubtitle;
  // A longer explainer that shows up when the user hovers over the column.
  readonly tooltip;
  // The default sort direction.
  readonly defaultSortDirection;
  // When to the previous date range is a positive or negative change good or bad?
  readonly compareStyle;
  // how to calculate the value of the total row
  // the UI to collect the filter arguments
  readonly FilterUI:
    | ((
        args: FilterArguments,
        setArgs: (args: FilterArguments) => void
      ) => JSX.Element)
    | undefined;
  // The function that will calculate the total value for the column.
  readonly totalFn;

  private _sortFn;
  private _filterFn;
  private _formatFn;
  private _tableCell;

  constructor(args: {
    dataName: keyof TData;
    displayName: string;
    displaySubtitle: string;
    tooltip: string;
    defaultSortDirection: SortOption;
    compareStyle?: Array<CompareStyle>;
    totalFn?: (rows: TData[]) => number;
    formatFn: (rawVal: unknown, showFraction: boolean) => string;
    sortFn: (a: unknown, b: unknown, direction: SortOption) => number;
    filterFn: <T>(row: T, dataName: keyof T, args: FilterArguments) => boolean;
    filterUI?: (
      args: FilterArguments,
      setArgs: (args: FilterArguments) => void
    ) => JSX.Element;
    tableCell: MetricsTableCell;
  }) {
    this.dataName = args.dataName;
    this.displayName = args.displayName;
    this.displaySubtitle = args.displaySubtitle;
    this.tooltip = args.tooltip;
    this.defaultSortDirection = args.defaultSortDirection;
    this.compareStyle = args.compareStyle;
    this.FilterUI = args.filterUI;
    this.totalFn = args.totalFn;

    this._tableCell = args.tableCell;
    this._sortFn = args.sortFn;
    this._filterFn = args.filterFn;
    this._formatFn = args.formatFn;
  }

  compare(rowA: TData, rowB: TData, direction: SortOption): number {
    return this._sortFn(rowA[this.dataName], rowB[this.dataName], direction);
  }

  filter(row: TData, filterArgs: FilterArguments): boolean {
    return this._filterFn(row, this.dataName, filterArgs);
  }

  format(row: TData, showFractions: boolean): FormattedValue {
    return this._formatFn(row[this.dataName], showFractions);
  }

  renderCell({
    uniqKey,
    val,
    prev
  }: {
    uniqKey: string;
    val: unknown;
    prev?: unknown;
  }): JSX.Element {
    return this._tableCell({
      uniqKey,
      val,
      prev,
      formatFn: this._formatFn,
      compareStyles: this.compareStyle
    });
  }
}

const calculateAverageTotal = <TData,>(
  dataName: keyof TData
): ((rows: TData[]) => number) => {
  return rows => {
    return (
      rows.reduce((acc, row) => acc + Number(row[dataName]), 0) / rows.length
    );
  };
};

const calculateSumTotal = <TData,>(
  dataName: keyof TData
): ((rows: TData[]) => number) => {
  return rows => {
    return rows.reduce((acc, row) => acc + Number(row[dataName]), 0);
  };
};

const calculateRatioTotal = <TData,>(
  numerator: keyof TData,
  denominator: keyof TData
): ((rows: TData[]) => number) => {
  return rows => {
    return (
      rows.reduce((acc, row) => acc + Number(row[numerator]), 0) /
      rows.reduce((acc, row) => acc + Number(row[denominator]), 0)
    );
  };
};

export const COL_ATC = new Column<{ addToCartClicks: number }>({
  dataName: "addToCartClicks",
  displayName: "Add Cart",
  displaySubtitle: "",
  tooltip: "",
  defaultSortDirection: "asc",
  compareStyle: moreIsBetter,
  totalFn: calculateSumTotal("addToCartClicks"),
  sortFn: numberSort,
  filterFn: numberFilterFn,
  formatFn: formatSimpleMetric,
  tableCell: MetricCell,
  filterUI: NumberFilterUI
});

export const COL_ATC_RATE = new Column<{
  addToCartRate: number;
  addToCartClicks: number;
  facebookOutboundClicks: number;
}>({
  dataName: "addToCartRate",
  displayName: "Add Cart Rate",
  displaySubtitle: "",
  tooltip: "",
  defaultSortDirection: "asc",
  compareStyle: moreIsBetter,
  totalFn: calculateRatioTotal("addToCartClicks", "facebookOutboundClicks"),
  sortFn: numberSort,
  filterFn: numberFilterFn,
  formatFn: formatPercent,
  tableCell: MetricCell,
  filterUI: NumberFilterUI
});

export const COL_CONVERSIONS = new Column<{ conversions: number }>({
  dataName: "conversions",
  displayName: "Conversions",
  displaySubtitle: "",
  tooltip: "",
  defaultSortDirection: "asc",
  compareStyle: moreIsBetter,
  totalFn: calculateSumTotal("conversions"),
  sortFn: numberSort,
  filterFn: numberFilterFn,
  formatFn: formatSimpleMetric,
  tableCell: MetricCell,
  filterUI: NumberFilterUI
});

export const COL_CONVERSION_RATE = new Column<{
  conversionRate: number;
  conversions: number;
  facebookOutboundClicks: number;
}>({
  dataName: "conversionRate",
  displayName: "Conversion Rate",
  displaySubtitle: "",
  tooltip: "",
  defaultSortDirection: "asc",
  compareStyle: moreIsBetter,
  totalFn: calculateRatioTotal("conversions", "facebookOutboundClicks"),
  sortFn: numberSort,
  filterFn: numberFilterFn,
  formatFn: formatPercent,
  tableCell: MetricCell,
  filterUI: NumberFilterUI
});

export const COL_CONVERSION_VALUE = new Column<{ conversionValue: number }>({
  dataName: "conversionValue",
  displayName: "Conversion Value",
  displaySubtitle: "",
  tooltip: "",
  defaultSortDirection: "asc",
  compareStyle: moreIsBetter,
  totalFn: calculateSumTotal("conversionValue"),
  sortFn: numberSort,
  filterFn: numberFilterFn,
  formatFn: formatCurrency,
  tableCell: MetricCell,
  filterUI: NumberFilterUI
});

export const COL_COST_PER_ADD_TO_CART = new Column<{
  costPerAddToCart: number;
}>({
  dataName: "costPerAddToCart",
  displayName: "Cost Per Add To Cart",
  displaySubtitle: "",
  tooltip: "",
  defaultSortDirection: "asc",
  compareStyle: lessIsBetter,
  totalFn: calculateAverageTotal("costPerAddToCart"),
  sortFn: numberSort,
  filterFn: numberFilterFn,
  formatFn: formatCurrency,
  tableCell: MetricCell,
  filterUI: NumberFilterUI
});

export const COL_COST_PER_DETAIL_PAGE_VIEW = new Column<{
  costPerDetailPageView: number;
}>({
  dataName: "costPerDetailPageView",
  displayName: "Cost Per Detail Page View",
  displaySubtitle: "",
  tooltip: "",
  defaultSortDirection: "asc",
  compareStyle: lessIsBetter,
  totalFn: calculateAverageTotal("costPerDetailPageView"),
  sortFn: numberSort,
  filterFn: numberFilterFn,
  formatFn: formatCurrency,
  tableCell: MetricCell,
  filterUI: NumberFilterUI
});

export const COL_COST_PER_MILLE = new Column<{ costPerMille: number }>({
  dataName: "costPerMille",
  displayName: "Cost Per Mille",
  displaySubtitle: "",
  tooltip: "",
  defaultSortDirection: "asc",
  compareStyle: lessIsBetter,
  totalFn: calculateAverageTotal("costPerMille"),
  sortFn: numberSort,
  filterFn: numberFilterFn,
  formatFn: formatCurrency,
  tableCell: MetricCell,
  filterUI: NumberFilterUI
});

export const COL_DETAIL_PAGE_VIEW_RATE = new Column<{
  detailPageViewRate: number;
  detailPageViews: number;
  facebookOutboundClicks: number;
}>({
  dataName: "detailPageViewRate",
  displayName: "Detail Page View Rate",
  displaySubtitle: "",
  tooltip: "",
  defaultSortDirection: "asc",
  compareStyle: moreIsBetter,
  totalFn: calculateRatioTotal("detailPageViews", "facebookOutboundClicks"),
  sortFn: numberSort,
  filterFn: numberFilterFn,
  formatFn: formatPercent,
  tableCell: MetricCell,
  filterUI: NumberFilterUI
});

export const COL_DETAIL_PAGE_VIEWS = new Column<{ detailPageViews: number }>({
  dataName: "detailPageViews",
  displayName: "Detail Page Views",
  displaySubtitle: "",
  tooltip: "",
  defaultSortDirection: "asc",
  compareStyle: moreIsBetter,
  totalFn: calculateSumTotal("detailPageViews"),
  sortFn: numberSort,
  filterFn: numberFilterFn,
  formatFn: formatSimpleMetric,
  tableCell: MetricCell,
  filterUI: NumberFilterUI
});

export const COL_FACEBOOK_COST_PER_OUTBOUND_CLICK = new Column<{
  facebookCostPerOutboundClick: number;
}>({
  dataName: "facebookCostPerOutboundClick",
  displayName: "Facebook Cost Per Outbound Click",
  displaySubtitle: "",
  tooltip: "",
  defaultSortDirection: "asc",
  compareStyle: lessIsBetter,
  totalFn: calculateAverageTotal("facebookCostPerOutboundClick"),
  sortFn: numberSort,
  filterFn: numberFilterFn,
  formatFn: formatSmallCurrency,
  tableCell: MetricCell,
  filterUI: NumberFilterUI
});

export const COL_FACEBOOK_INTERACTIONS = new Column<{
  facebookInteractions: number;
}>({
  dataName: "facebookInteractions",
  displayName: "Facebook Interactions",
  displaySubtitle: "",
  tooltip: "",
  defaultSortDirection: "asc",
  compareStyle: moreIsBetter,
  totalFn: calculateSumTotal("facebookInteractions"),
  sortFn: numberSort,
  filterFn: numberFilterFn,
  formatFn: formatSimpleMetric,
  tableCell: MetricCell,
  filterUI: NumberFilterUI
});

export const COL_FACEBOOK_OUTBOUND_CLICK_RATE = new Column<{
  facebookOutboundClickRate: number;
  facebookOutboundClicks: number;
  impressions: number;
}>({
  dataName: "facebookOutboundClickRate",
  displayName: "Facebook Outbound Click Rate",
  displaySubtitle: "",
  tooltip: "",
  defaultSortDirection: "asc",
  compareStyle: moreIsBetter,
  totalFn: calculateRatioTotal("facebookOutboundClicks", "impressions"),
  sortFn: numberSort,
  filterFn: numberFilterFn,
  formatFn: formatPercent,
  tableCell: MetricCell,
  filterUI: NumberFilterUI
});

export const COL_FACEBOOK_OUTBOUND_CLICKS = new Column<{
  facebookOutboundClicks: number;
}>({
  dataName: "facebookOutboundClicks",
  displayName: "Facebook Outbound Clicks",
  displaySubtitle: "",
  tooltip: "",
  defaultSortDirection: "asc",
  compareStyle: moreIsBetter,
  totalFn: calculateSumTotal("facebookOutboundClicks"),
  sortFn: numberSort,
  filterFn: numberFilterFn,
  formatFn: formatSimpleMetric,
  tableCell: MetricCell,
  filterUI: NumberFilterUI
});

export const COL_IMPRESSIONS = new Column<{ impressions: number }>({
  dataName: "impressions",
  displayName: "Impressions",
  displaySubtitle: "",
  tooltip: "",
  defaultSortDirection: "asc",
  compareStyle: moreIsBetter,
  totalFn: calculateSumTotal("impressions"),
  sortFn: numberSort,
  filterFn: numberFilterFn,
  formatFn: formatSimpleMetric,
  tableCell: MetricCell,
  filterUI: NumberFilterUI
});

export const COL_MARKETPLACE_CLICK_RATE = new Column<{
  marketplaceClickRate: number;
  marketplaceClicks: number;
  impressions: number;
}>({
  dataName: "marketplaceClickRate",
  displayName: "Marketplace Click Rate",
  displaySubtitle: "",
  tooltip: "",
  defaultSortDirection: "asc",
  compareStyle: moreIsBetter,
  totalFn: calculateRatioTotal("marketplaceClicks", "impressions"),
  sortFn: numberSort,
  filterFn: numberFilterFn,
  formatFn: formatPercent,
  tableCell: MetricCell,
  filterUI: NumberFilterUI
});

export const COL_MARKETPLACE_CLICKS = new Column<{ marketplaceClicks: number }>(
  {
    dataName: "marketplaceClicks",
    displayName: "Marketplace Clicks",
    displaySubtitle: "",
    tooltip: "",
    defaultSortDirection: "asc",
    compareStyle: moreIsBetter,
    totalFn: calculateSumTotal("marketplaceClicks"),
    sortFn: numberSort,
    filterFn: numberFilterFn,
    formatFn: formatSimpleMetric,
    tableCell: MetricCell,
    filterUI: NumberFilterUI
  }
);

export const COL_MARKETPLACE_COST_PER_CLICK = new Column<{
  marketplaceCostPerClick: number;
}>({
  dataName: "marketplaceCostPerClick",
  displayName: "Marketplace Cost Per Click",
  displaySubtitle: "",
  tooltip: "",
  defaultSortDirection: "asc",
  compareStyle: lessIsBetter,
  totalFn: calculateAverageTotal("marketplaceCostPerClick"),
  sortFn: numberSort,
  filterFn: numberFilterFn,
  formatFn: formatSmallCurrency,
  tableCell: MetricCell,
  filterUI: NumberFilterUI
});

export const COL_NTB_CONVERSION_RATE = new Column<{
  ntbConversionRate: number;
  ntbConversions: number;
  conversions: number;
}>({
  dataName: "ntbConversionRate",
  displayName: "NTB Conversion Rate",
  displaySubtitle: "",
  tooltip: "",
  defaultSortDirection: "asc",
  compareStyle: moreIsBetter,
  totalFn: calculateRatioTotal("ntbConversions", "conversions"),
  sortFn: numberSort,
  filterFn: numberFilterFn,
  formatFn: formatPercent,
  tableCell: MetricCell,
  filterUI: NumberFilterUI
});

export const COL_NTB_CONVERSIONS = new Column<{ ntbConversions: number }>({
  dataName: "ntbConversions",
  displayName: "NTB Conversions",
  displaySubtitle: "",
  tooltip: "",
  defaultSortDirection: "asc",
  compareStyle: moreIsBetter,
  totalFn: calculateSumTotal("ntbConversions"),
  sortFn: numberSort,
  filterFn: numberFilterFn,
  formatFn: formatSimpleMetric,
  tableCell: MetricCell,
  filterUI: NumberFilterUI
});

export const COL_NTB_REVENUE = new Column<{ ntbRevenue: number }>({
  dataName: "ntbRevenue",
  displayName: "NTB Revenue",
  displaySubtitle: "",
  tooltip: "",
  defaultSortDirection: "asc",
  compareStyle: moreIsBetter,
  totalFn: calculateSumTotal("ntbRevenue"),
  sortFn: numberSort,
  filterFn: numberFilterFn,
  formatFn: formatCurrency,
  tableCell: MetricCell,
  filterUI: NumberFilterUI
});

export const COL_NTB_REVENUE_RATE = new Column<{
  ntbRevenueRate: number;
  ntbRevenue: number;
  conversionValue: number;
}>({
  dataName: "ntbRevenueRate",
  displayName: "NTB Revenue Rate",
  displaySubtitle: "",
  tooltip: "",
  defaultSortDirection: "asc",
  compareStyle: moreIsBetter,
  totalFn: calculateRatioTotal("ntbRevenue", "conversionValue"),
  sortFn: numberSort,
  filterFn: numberFilterFn,
  formatFn: formatPercent,
  tableCell: MetricCell,
  filterUI: NumberFilterUI
});

export const COL_NTB_ROAS = new Column<{ ntbROAS: number }>({
  dataName: "ntbROAS",
  displayName: "NTB ROAS",
  displaySubtitle: "",
  tooltip: "",
  defaultSortDirection: "asc",
  compareStyle: moreIsBetter,
  totalFn: calculateSumTotal("ntbROAS"),
  sortFn: numberSort,
  filterFn: numberFilterFn,
  formatFn: formatSimpleMetric,
  tableCell: MetricCell,
  filterUI: NumberFilterUI
});

export const COL_NTB_UNITS_SOLD = new Column<{ ntbUnitsSold: number }>({
  dataName: "ntbUnitsSold",
  displayName: "NTB Units Sold",
  displaySubtitle: "",
  tooltip: "",
  defaultSortDirection: "asc",
  compareStyle: moreIsBetter,
  totalFn: calculateSumTotal("ntbUnitsSold"),
  sortFn: numberSort,
  filterFn: numberFilterFn,
  formatFn: formatSimpleMetric,
  tableCell: MetricCell,
  filterUI: NumberFilterUI
});

export const COL_NTB_UNITS_SOLD_RATE = new Column<{
  ntbUnitsSoldRate: number;
  ntbUnitsSold: number;
  unitsSold: number;
}>({
  dataName: "ntbUnitsSoldRate",
  displayName: "NTB Units Sold Rate",
  displaySubtitle: "",
  tooltip: "",
  defaultSortDirection: "asc",
  compareStyle: moreIsBetter,
  totalFn: calculateRatioTotal("ntbUnitsSold", "unitsSold"),
  sortFn: numberSort,
  filterFn: numberFilterFn,
  formatFn: formatPercent,
  tableCell: MetricCell,
  filterUI: NumberFilterUI
});

export const COL_ROAS = new Column<{
  roas: number;
  conversionValue: number;
  spend: number;
}>({
  dataName: "roas",
  displayName: "ROAS",
  displaySubtitle: "",
  tooltip: "",
  defaultSortDirection: "asc",
  compareStyle: moreIsBetter,
  totalFn: calculateRatioTotal("conversionValue", "spend"),
  sortFn: numberSort,
  filterFn: numberFilterFn,
  formatFn: formatSimpleMetric,
  tableCell: MetricCell,
  filterUI: NumberFilterUI
});

export const COL_SPEND = new Column<{ spend: number }>({
  dataName: "spend",
  displayName: "Spend",
  displaySubtitle: "",
  tooltip: "",
  defaultSortDirection: "asc",
  compareStyle: trueNeutral,
  totalFn: calculateSumTotal("spend"),
  sortFn: numberSort,
  filterFn: numberFilterFn,
  formatFn: formatCurrency,
  tableCell: MetricCell,
  filterUI: NumberFilterUI
});

export const COL_UNITS_SOLD = new Column<{ unitsSold: number }>({
  dataName: "unitsSold",
  displayName: "Units Sold",
  displaySubtitle: "",
  tooltip: "",
  defaultSortDirection: "asc",
  compareStyle: moreIsBetter,
  totalFn: calculateSumTotal("unitsSold"),
  sortFn: numberSort,
  filterFn: numberFilterFn,
  formatFn: formatSimpleMetric,
  tableCell: MetricCell,
  filterUI: NumberFilterUI
});

export const COL_ACCOUNT_ID = new Column<{ accountID: string }>({
  dataName: "accountID",
  displayName: "Account ID",
  displaySubtitle: "",
  tooltip: "",
  defaultSortDirection: "asc",
  compareStyle: trueNeutral,
  sortFn: stringSort,
  filterFn: stringFilterFn,
  formatFn: n => String(n),
  tableCell: SimpleCell,
  filterUI: StringFilterUI
});

export const COL_AD_ID = new Column<{ adID: string }>({
  dataName: "adID",
  displayName: "Ad ID",
  displaySubtitle: "",
  tooltip: "",
  defaultSortDirection: "asc",
  compareStyle: trueNeutral,
  sortFn: stringSort,
  filterFn: stringFilterFn,
  formatFn: n => String(n),
  tableCell: SimpleCell,
  filterUI: StringFilterUI
});

export const COL_AD_SET_ID = new Column<{ adSetID: string }>({
  dataName: "adSetID",
  displayName: "Ad Set ID",
  displaySubtitle: "",
  tooltip: "",
  defaultSortDirection: "asc",
  compareStyle: trueNeutral,
  sortFn: stringSort,
  filterFn: stringFilterFn,
  formatFn: n => String(n),
  tableCell: SimpleCell,
  filterUI: StringFilterUI
});

export const COL_CAMPAIGN_ID = new Column<{ campaignID: string }>({
  dataName: "campaignID",
  displayName: "Campaign ID",
  displaySubtitle: "",
  tooltip: "",
  defaultSortDirection: "asc",
  compareStyle: trueNeutral,
  sortFn: stringSort,
  filterFn: stringFilterFn,
  formatFn: n => String(n),
  tableCell: SimpleCell,
  filterUI: StringFilterUI
});

export const COL_NAME = new Column<{ name: string }>({
  dataName: "name",
  displayName: "Name",
  displaySubtitle: "",
  tooltip: "",
  defaultSortDirection: "asc",
  compareStyle: trueNeutral,
  sortFn: stringSort,
  filterFn: stringFilterFn,
  formatFn: n => String(n),
  tableCell: SimpleCell,
  filterUI: StringFilterUI
});

function formatSimpleMetric(rawVal: unknown, showFractions: boolean): string {
  return formatMetric(
    showFractions ? fractionalNumberMetricDef : roundedNumberMetricDef,
    rawVal
  ).toString();
}

function formatCurrency(rawVal: unknown, showFractions: boolean): string {
  return formatMetric(
    getCurrencyMetricDef("USD", showFractions),
    rawVal
  ).toString();
}

function formatSmallCurrency(rawVal: unknown, _showFractions: boolean): string {
  return formatMetric(getCurrencyMetricDef("USD", true), rawVal).toString();
}

function formatPercent(rawVal: unknown, showFractions: boolean): string {
  return formatMetric(
    showFractions ? oneDecimalPercentageMetricDef : roundedPercentageMetricDef,
    rawVal
  ).toString();
}
