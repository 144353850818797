// source: proto/common/walmart.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

var google_protobuf_descriptor_pb = require('google-protobuf/google/protobuf/descriptor_pb.js');
goog.object.extend(proto, google_protobuf_descriptor_pb);
goog.exportSymbol('proto.common.Walmart', null, global);
goog.exportSymbol('proto.common.Walmart.AdTargetType', null, global);
goog.exportSymbol('proto.common.Walmart.AdTargetType.Option', null, global);
goog.exportSymbol('proto.common.Walmart.AppType', null, global);
goog.exportSymbol('proto.common.Walmart.AppType.Option', null, global);
goog.exportSymbol('proto.common.Walmart.AttributionSource', null, global);
goog.exportSymbol('proto.common.Walmart.AttributionSource.Option', null, global);
goog.exportSymbol('proto.common.Walmart.Brand', null, global);
goog.exportSymbol('proto.common.Walmart.BrandDetails', null, global);
goog.exportSymbol('proto.common.Walmart.BrandDetails.Item', null, global);
goog.exportSymbol('proto.common.Walmart.Facet', null, global);
goog.exportSymbol('proto.common.Walmart.Facet.Value', null, global);
goog.exportSymbol('proto.common.Walmart.Marketplace', null, global);
goog.exportSymbol('proto.common.Walmart.Marketplace.Config', null, global);
goog.exportSymbol('proto.common.Walmart.Marketplace.Option', null, global);
goog.exportSymbol('proto.common.Walmart.Product', null, global);
goog.exportSymbol('proto.common.Walmart.Product.BuyboxWinner', null, global);
goog.exportSymbol('proto.common.Walmart.Product.Image', null, global);
goog.exportSymbol('proto.common.Walmart.Product.Variant', null, global);
goog.exportSymbol('proto.common.Walmart.SearchPageProduct', null, global);
goog.exportSymbol('proto.common.Walmart.SearchResult', null, global);
goog.exportSymbol('proto.common.Walmart.SearchResults', null, global);
goog.exportSymbol('proto.common.Walmart.SellerType', null, global);
goog.exportSymbol('proto.common.Walmart.SellerType.Option', null, global);
goog.exportSymbol('proto.common.Walmart.Variant', null, global);
goog.exportSymbol('proto.common.walmartMarketplaceConfig', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.Walmart = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.Walmart, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.Walmart.displayName = 'proto.common.Walmart';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.Walmart.Product = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.common.Walmart.Product.repeatedFields_, null);
};
goog.inherits(proto.common.Walmart.Product, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.Walmart.Product.displayName = 'proto.common.Walmart.Product';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.Walmart.Product.Image = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.Walmart.Product.Image, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.Walmart.Product.Image.displayName = 'proto.common.Walmart.Product.Image';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.Walmart.Product.Variant = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.Walmart.Product.Variant, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.Walmart.Product.Variant.displayName = 'proto.common.Walmart.Product.Variant';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.Walmart.Product.BuyboxWinner = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.Walmart.Product.BuyboxWinner, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.Walmart.Product.BuyboxWinner.displayName = 'proto.common.Walmart.Product.BuyboxWinner';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.Walmart.SearchPageProduct = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.common.Walmart.SearchPageProduct.repeatedFields_, null);
};
goog.inherits(proto.common.Walmart.SearchPageProduct, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.Walmart.SearchPageProduct.displayName = 'proto.common.Walmart.SearchPageProduct';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.Walmart.Variant = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.Walmart.Variant, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.Walmart.Variant.displayName = 'proto.common.Walmart.Variant';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.Walmart.Facet = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.common.Walmart.Facet.repeatedFields_, null);
};
goog.inherits(proto.common.Walmart.Facet, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.Walmart.Facet.displayName = 'proto.common.Walmart.Facet';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.Walmart.Facet.Value = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.Walmart.Facet.Value, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.Walmart.Facet.Value.displayName = 'proto.common.Walmart.Facet.Value';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.Walmart.SearchResult = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.Walmart.SearchResult, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.Walmart.SearchResult.displayName = 'proto.common.Walmart.SearchResult';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.Walmart.SearchResults = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.common.Walmart.SearchResults.repeatedFields_, null);
};
goog.inherits(proto.common.Walmart.SearchResults, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.Walmart.SearchResults.displayName = 'proto.common.Walmart.SearchResults';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.Walmart.Marketplace = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.Walmart.Marketplace, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.Walmart.Marketplace.displayName = 'proto.common.Walmart.Marketplace';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.Walmart.Marketplace.Config = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.Walmart.Marketplace.Config, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.Walmart.Marketplace.Config.displayName = 'proto.common.Walmart.Marketplace.Config';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.Walmart.AdTargetType = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.Walmart.AdTargetType, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.Walmart.AdTargetType.displayName = 'proto.common.Walmart.AdTargetType';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.Walmart.Brand = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.Walmart.Brand, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.Walmart.Brand.displayName = 'proto.common.Walmart.Brand';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.Walmart.BrandDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.common.Walmart.BrandDetails.repeatedFields_, null);
};
goog.inherits(proto.common.Walmart.BrandDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.Walmart.BrandDetails.displayName = 'proto.common.Walmart.BrandDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.Walmart.BrandDetails.Item = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.Walmart.BrandDetails.Item, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.Walmart.BrandDetails.Item.displayName = 'proto.common.Walmart.BrandDetails.Item';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.Walmart.SellerType = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.Walmart.SellerType, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.Walmart.SellerType.displayName = 'proto.common.Walmart.SellerType';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.Walmart.AppType = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.Walmart.AppType, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.Walmart.AppType.displayName = 'proto.common.Walmart.AppType';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.common.Walmart.AttributionSource = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.common.Walmart.AttributionSource, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.common.Walmart.AttributionSource.displayName = 'proto.common.Walmart.AttributionSource';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.Walmart.prototype.toObject = function(opt_includeInstance) {
  return proto.common.Walmart.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.Walmart} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Walmart.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.Walmart}
 */
proto.common.Walmart.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.Walmart;
  return proto.common.Walmart.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.Walmart} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.Walmart}
 */
proto.common.Walmart.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.Walmart.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.Walmart.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.Walmart} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Walmart.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.common.Walmart.Product.repeatedFields_ = [14];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.Walmart.Product.prototype.toObject = function(opt_includeInstance) {
  return proto.common.Walmart.Product.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.Walmart.Product} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Walmart.Product.toObject = function(includeInstance, msg) {
  var f, obj = {
    title: jspb.Message.getFieldWithDefault(msg, 1, ""),
    itemId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    productId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    itemNumber: jspb.Message.getFieldWithDefault(msg, 4, ""),
    upc: jspb.Message.getFieldWithDefault(msg, 5, ""),
    model: jspb.Message.getFieldWithDefault(msg, 6, ""),
    link: jspb.Message.getFieldWithDefault(msg, 7, ""),
    mainImage: (f = msg.getMainImage()) && proto.common.Walmart.Product.Image.toObject(includeInstance, f),
    description: jspb.Message.getFieldWithDefault(msg, 8, ""),
    descriptionFull: jspb.Message.getFieldWithDefault(msg, 9, ""),
    brand: jspb.Message.getFieldWithDefault(msg, 10, ""),
    brandLink: jspb.Message.getFieldWithDefault(msg, 11, ""),
    rating: jspb.Message.getFloatingPointFieldWithDefault(msg, 12, 0.0),
    ratingsTotal: jspb.Message.getFieldWithDefault(msg, 13, 0),
    variantsList: jspb.Message.toObjectList(msg.getVariantsList(),
    proto.common.Walmart.Product.Variant.toObject, includeInstance),
    buyboxWinner: (f = msg.getBuyboxWinner()) && proto.common.Walmart.Product.BuyboxWinner.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.Walmart.Product}
 */
proto.common.Walmart.Product.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.Walmart.Product;
  return proto.common.Walmart.Product.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.Walmart.Product} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.Walmart.Product}
 */
proto.common.Walmart.Product.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setItemId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setItemNumber(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setUpc(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setModel(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setLink(value);
      break;
    case 15:
      var value = new proto.common.Walmart.Product.Image;
      reader.readMessage(value,proto.common.Walmart.Product.Image.deserializeBinaryFromReader);
      msg.setMainImage(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescriptionFull(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setBrand(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setBrandLink(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setRating(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRatingsTotal(value);
      break;
    case 14:
      var value = new proto.common.Walmart.Product.Variant;
      reader.readMessage(value,proto.common.Walmart.Product.Variant.deserializeBinaryFromReader);
      msg.addVariants(value);
      break;
    case 16:
      var value = new proto.common.Walmart.Product.BuyboxWinner;
      reader.readMessage(value,proto.common.Walmart.Product.BuyboxWinner.deserializeBinaryFromReader);
      msg.setBuyboxWinner(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.Walmart.Product.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.Walmart.Product.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.Walmart.Product} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Walmart.Product.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getItemId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getProductId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getItemNumber();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getUpc();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getModel();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getLink();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getMainImage();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      proto.common.Walmart.Product.Image.serializeBinaryToWriter
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getDescriptionFull();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getBrand();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getBrandLink();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getRating();
  if (f !== 0.0) {
    writer.writeFloat(
      12,
      f
    );
  }
  f = message.getRatingsTotal();
  if (f !== 0) {
    writer.writeInt32(
      13,
      f
    );
  }
  f = message.getVariantsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      14,
      f,
      proto.common.Walmart.Product.Variant.serializeBinaryToWriter
    );
  }
  f = message.getBuyboxWinner();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      proto.common.Walmart.Product.BuyboxWinner.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.Walmart.Product.Image.prototype.toObject = function(opt_includeInstance) {
  return proto.common.Walmart.Product.Image.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.Walmart.Product.Image} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Walmart.Product.Image.toObject = function(includeInstance, msg) {
  var f, obj = {
    link: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.Walmart.Product.Image}
 */
proto.common.Walmart.Product.Image.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.Walmart.Product.Image;
  return proto.common.Walmart.Product.Image.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.Walmart.Product.Image} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.Walmart.Product.Image}
 */
proto.common.Walmart.Product.Image.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLink(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.Walmart.Product.Image.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.Walmart.Product.Image.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.Walmart.Product.Image} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Walmart.Product.Image.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLink();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string link = 1;
 * @return {string}
 */
proto.common.Walmart.Product.Image.prototype.getLink = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Walmart.Product.Image} returns this
 */
proto.common.Walmart.Product.Image.prototype.setLink = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.Walmart.Product.Variant.prototype.toObject = function(opt_includeInstance) {
  return proto.common.Walmart.Product.Variant.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.Walmart.Product.Variant} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Walmart.Product.Variant.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    productId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    link: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.Walmart.Product.Variant}
 */
proto.common.Walmart.Product.Variant.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.Walmart.Product.Variant;
  return proto.common.Walmart.Product.Variant.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.Walmart.Product.Variant} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.Walmart.Product.Variant}
 */
proto.common.Walmart.Product.Variant.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setItemId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLink(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.Walmart.Product.Variant.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.Walmart.Product.Variant.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.Walmart.Product.Variant} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Walmart.Product.Variant.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getProductId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLink();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string item_id = 1;
 * @return {string}
 */
proto.common.Walmart.Product.Variant.prototype.getItemId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Walmart.Product.Variant} returns this
 */
proto.common.Walmart.Product.Variant.prototype.setItemId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string product_id = 2;
 * @return {string}
 */
proto.common.Walmart.Product.Variant.prototype.getProductId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Walmart.Product.Variant} returns this
 */
proto.common.Walmart.Product.Variant.prototype.setProductId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string link = 3;
 * @return {string}
 */
proto.common.Walmart.Product.Variant.prototype.getLink = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Walmart.Product.Variant} returns this
 */
proto.common.Walmart.Product.Variant.prototype.setLink = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.Walmart.Product.BuyboxWinner.prototype.toObject = function(opt_includeInstance) {
  return proto.common.Walmart.Product.BuyboxWinner.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.Walmart.Product.BuyboxWinner} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Walmart.Product.BuyboxWinner.toObject = function(includeInstance, msg) {
  var f, obj = {
    price: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    unit: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.Walmart.Product.BuyboxWinner}
 */
proto.common.Walmart.Product.BuyboxWinner.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.Walmart.Product.BuyboxWinner;
  return proto.common.Walmart.Product.BuyboxWinner.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.Walmart.Product.BuyboxWinner} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.Walmart.Product.BuyboxWinner}
 */
proto.common.Walmart.Product.BuyboxWinner.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPrice(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUnit(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.Walmart.Product.BuyboxWinner.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.Walmart.Product.BuyboxWinner.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.Walmart.Product.BuyboxWinner} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Walmart.Product.BuyboxWinner.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPrice();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getUnit();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional double price = 1;
 * @return {number}
 */
proto.common.Walmart.Product.BuyboxWinner.prototype.getPrice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.common.Walmart.Product.BuyboxWinner} returns this
 */
proto.common.Walmart.Product.BuyboxWinner.prototype.setPrice = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional string unit = 2;
 * @return {string}
 */
proto.common.Walmart.Product.BuyboxWinner.prototype.getUnit = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Walmart.Product.BuyboxWinner} returns this
 */
proto.common.Walmart.Product.BuyboxWinner.prototype.setUnit = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string title = 1;
 * @return {string}
 */
proto.common.Walmart.Product.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Walmart.Product} returns this
 */
proto.common.Walmart.Product.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string item_id = 2;
 * @return {string}
 */
proto.common.Walmart.Product.prototype.getItemId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Walmart.Product} returns this
 */
proto.common.Walmart.Product.prototype.setItemId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string product_id = 3;
 * @return {string}
 */
proto.common.Walmart.Product.prototype.getProductId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Walmart.Product} returns this
 */
proto.common.Walmart.Product.prototype.setProductId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string item_number = 4;
 * @return {string}
 */
proto.common.Walmart.Product.prototype.getItemNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Walmart.Product} returns this
 */
proto.common.Walmart.Product.prototype.setItemNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string upc = 5;
 * @return {string}
 */
proto.common.Walmart.Product.prototype.getUpc = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Walmart.Product} returns this
 */
proto.common.Walmart.Product.prototype.setUpc = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string model = 6;
 * @return {string}
 */
proto.common.Walmart.Product.prototype.getModel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Walmart.Product} returns this
 */
proto.common.Walmart.Product.prototype.setModel = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string link = 7;
 * @return {string}
 */
proto.common.Walmart.Product.prototype.getLink = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Walmart.Product} returns this
 */
proto.common.Walmart.Product.prototype.setLink = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional Image main_image = 15;
 * @return {?proto.common.Walmart.Product.Image}
 */
proto.common.Walmart.Product.prototype.getMainImage = function() {
  return /** @type{?proto.common.Walmart.Product.Image} */ (
    jspb.Message.getWrapperField(this, proto.common.Walmart.Product.Image, 15));
};


/**
 * @param {?proto.common.Walmart.Product.Image|undefined} value
 * @return {!proto.common.Walmart.Product} returns this
*/
proto.common.Walmart.Product.prototype.setMainImage = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.Walmart.Product} returns this
 */
proto.common.Walmart.Product.prototype.clearMainImage = function() {
  return this.setMainImage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.Walmart.Product.prototype.hasMainImage = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional string description = 8;
 * @return {string}
 */
proto.common.Walmart.Product.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Walmart.Product} returns this
 */
proto.common.Walmart.Product.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string description_full = 9;
 * @return {string}
 */
proto.common.Walmart.Product.prototype.getDescriptionFull = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Walmart.Product} returns this
 */
proto.common.Walmart.Product.prototype.setDescriptionFull = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string brand = 10;
 * @return {string}
 */
proto.common.Walmart.Product.prototype.getBrand = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Walmart.Product} returns this
 */
proto.common.Walmart.Product.prototype.setBrand = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string brand_link = 11;
 * @return {string}
 */
proto.common.Walmart.Product.prototype.getBrandLink = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Walmart.Product} returns this
 */
proto.common.Walmart.Product.prototype.setBrandLink = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional float rating = 12;
 * @return {number}
 */
proto.common.Walmart.Product.prototype.getRating = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 12, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.common.Walmart.Product} returns this
 */
proto.common.Walmart.Product.prototype.setRating = function(value) {
  return jspb.Message.setProto3FloatField(this, 12, value);
};


/**
 * optional int32 ratings_total = 13;
 * @return {number}
 */
proto.common.Walmart.Product.prototype.getRatingsTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.common.Walmart.Product} returns this
 */
proto.common.Walmart.Product.prototype.setRatingsTotal = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * repeated Variant variants = 14;
 * @return {!Array<!proto.common.Walmart.Product.Variant>}
 */
proto.common.Walmart.Product.prototype.getVariantsList = function() {
  return /** @type{!Array<!proto.common.Walmart.Product.Variant>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.common.Walmart.Product.Variant, 14));
};


/**
 * @param {!Array<!proto.common.Walmart.Product.Variant>} value
 * @return {!proto.common.Walmart.Product} returns this
*/
proto.common.Walmart.Product.prototype.setVariantsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 14, value);
};


/**
 * @param {!proto.common.Walmart.Product.Variant=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.Walmart.Product.Variant}
 */
proto.common.Walmart.Product.prototype.addVariants = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 14, opt_value, proto.common.Walmart.Product.Variant, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.common.Walmart.Product} returns this
 */
proto.common.Walmart.Product.prototype.clearVariantsList = function() {
  return this.setVariantsList([]);
};


/**
 * optional BuyboxWinner buybox_winner = 16;
 * @return {?proto.common.Walmart.Product.BuyboxWinner}
 */
proto.common.Walmart.Product.prototype.getBuyboxWinner = function() {
  return /** @type{?proto.common.Walmart.Product.BuyboxWinner} */ (
    jspb.Message.getWrapperField(this, proto.common.Walmart.Product.BuyboxWinner, 16));
};


/**
 * @param {?proto.common.Walmart.Product.BuyboxWinner|undefined} value
 * @return {!proto.common.Walmart.Product} returns this
*/
proto.common.Walmart.Product.prototype.setBuyboxWinner = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.Walmart.Product} returns this
 */
proto.common.Walmart.Product.prototype.clearBuyboxWinner = function() {
  return this.setBuyboxWinner(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.Walmart.Product.prototype.hasBuyboxWinner = function() {
  return jspb.Message.getField(this, 16) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.common.Walmart.SearchPageProduct.repeatedFields_ = [8,16];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.Walmart.SearchPageProduct.prototype.toObject = function(opt_includeInstance) {
  return proto.common.Walmart.SearchPageProduct.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.Walmart.SearchPageProduct} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Walmart.SearchPageProduct.toObject = function(includeInstance, msg) {
  var f, obj = {
    title: jspb.Message.getFieldWithDefault(msg, 1, ""),
    link: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: jspb.Message.getFieldWithDefault(msg, 3, ""),
    brand: jspb.Message.getFieldWithDefault(msg, 4, ""),
    itemId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    productId: jspb.Message.getFieldWithDefault(msg, 6, ""),
    mainImage: jspb.Message.getFieldWithDefault(msg, 7, ""),
    imagesList: (f = jspb.Message.getRepeatedField(msg, 8)) == null ? undefined : f,
    department: jspb.Message.getFieldWithDefault(msg, 9, ""),
    sponsored: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    bestSeller: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
    popularPick: jspb.Message.getBooleanFieldWithDefault(msg, 12, false),
    rollback: jspb.Message.getBooleanFieldWithDefault(msg, 13, false),
    rating: jspb.Message.getFloatingPointFieldWithDefault(msg, 14, 0.0),
    ratingsTotal: jspb.Message.getFieldWithDefault(msg, 15, 0),
    variantsList: jspb.Message.toObjectList(msg.getVariantsList(),
    proto.common.Walmart.Variant.toObject, includeInstance),
    primaryPrice: jspb.Message.getFloatingPointFieldWithDefault(msg, 19, 0.0),
    page: jspb.Message.getFieldWithDefault(msg, 17, 0),
    positionInPage: jspb.Message.getFieldWithDefault(msg, 18, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.Walmart.SearchPageProduct}
 */
proto.common.Walmart.SearchPageProduct.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.Walmart.SearchPageProduct;
  return proto.common.Walmart.SearchPageProduct.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.Walmart.SearchPageProduct} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.Walmart.SearchPageProduct}
 */
proto.common.Walmart.SearchPageProduct.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLink(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setBrand(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setItemId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductId(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setMainImage(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.addImages(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setDepartment(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSponsored(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setBestSeller(value);
      break;
    case 12:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPopularPick(value);
      break;
    case 13:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRollback(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setRating(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRatingsTotal(value);
      break;
    case 16:
      var value = new proto.common.Walmart.Variant;
      reader.readMessage(value,proto.common.Walmart.Variant.deserializeBinaryFromReader);
      msg.addVariants(value);
      break;
    case 19:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setPrimaryPrice(value);
      break;
    case 17:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPage(value);
      break;
    case 18:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPositionInPage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.Walmart.SearchPageProduct.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.Walmart.SearchPageProduct.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.Walmart.SearchPageProduct} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Walmart.SearchPageProduct.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLink();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getBrand();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getItemId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getProductId();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getMainImage();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getImagesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      8,
      f
    );
  }
  f = message.getDepartment();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getSponsored();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getBestSeller();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
  f = message.getPopularPick();
  if (f) {
    writer.writeBool(
      12,
      f
    );
  }
  f = message.getRollback();
  if (f) {
    writer.writeBool(
      13,
      f
    );
  }
  f = message.getRating();
  if (f !== 0.0) {
    writer.writeFloat(
      14,
      f
    );
  }
  f = message.getRatingsTotal();
  if (f !== 0) {
    writer.writeInt32(
      15,
      f
    );
  }
  f = message.getVariantsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      16,
      f,
      proto.common.Walmart.Variant.serializeBinaryToWriter
    );
  }
  f = message.getPrimaryPrice();
  if (f !== 0.0) {
    writer.writeFloat(
      19,
      f
    );
  }
  f = message.getPage();
  if (f !== 0) {
    writer.writeInt32(
      17,
      f
    );
  }
  f = message.getPositionInPage();
  if (f !== 0) {
    writer.writeInt32(
      18,
      f
    );
  }
};


/**
 * optional string title = 1;
 * @return {string}
 */
proto.common.Walmart.SearchPageProduct.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Walmart.SearchPageProduct} returns this
 */
proto.common.Walmart.SearchPageProduct.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string link = 2;
 * @return {string}
 */
proto.common.Walmart.SearchPageProduct.prototype.getLink = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Walmart.SearchPageProduct} returns this
 */
proto.common.Walmart.SearchPageProduct.prototype.setLink = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.common.Walmart.SearchPageProduct.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Walmart.SearchPageProduct} returns this
 */
proto.common.Walmart.SearchPageProduct.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string brand = 4;
 * @return {string}
 */
proto.common.Walmart.SearchPageProduct.prototype.getBrand = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Walmart.SearchPageProduct} returns this
 */
proto.common.Walmart.SearchPageProduct.prototype.setBrand = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string item_id = 5;
 * @return {string}
 */
proto.common.Walmart.SearchPageProduct.prototype.getItemId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Walmart.SearchPageProduct} returns this
 */
proto.common.Walmart.SearchPageProduct.prototype.setItemId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string product_id = 6;
 * @return {string}
 */
proto.common.Walmart.SearchPageProduct.prototype.getProductId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Walmart.SearchPageProduct} returns this
 */
proto.common.Walmart.SearchPageProduct.prototype.setProductId = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string main_image = 7;
 * @return {string}
 */
proto.common.Walmart.SearchPageProduct.prototype.getMainImage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Walmart.SearchPageProduct} returns this
 */
proto.common.Walmart.SearchPageProduct.prototype.setMainImage = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * repeated string images = 8;
 * @return {!Array<string>}
 */
proto.common.Walmart.SearchPageProduct.prototype.getImagesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 8));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.common.Walmart.SearchPageProduct} returns this
 */
proto.common.Walmart.SearchPageProduct.prototype.setImagesList = function(value) {
  return jspb.Message.setField(this, 8, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.common.Walmart.SearchPageProduct} returns this
 */
proto.common.Walmart.SearchPageProduct.prototype.addImages = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 8, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.common.Walmart.SearchPageProduct} returns this
 */
proto.common.Walmart.SearchPageProduct.prototype.clearImagesList = function() {
  return this.setImagesList([]);
};


/**
 * optional string department = 9;
 * @return {string}
 */
proto.common.Walmart.SearchPageProduct.prototype.getDepartment = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Walmart.SearchPageProduct} returns this
 */
proto.common.Walmart.SearchPageProduct.prototype.setDepartment = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional bool sponsored = 10;
 * @return {boolean}
 */
proto.common.Walmart.SearchPageProduct.prototype.getSponsored = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.common.Walmart.SearchPageProduct} returns this
 */
proto.common.Walmart.SearchPageProduct.prototype.setSponsored = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional bool best_seller = 11;
 * @return {boolean}
 */
proto.common.Walmart.SearchPageProduct.prototype.getBestSeller = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.common.Walmart.SearchPageProduct} returns this
 */
proto.common.Walmart.SearchPageProduct.prototype.setBestSeller = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};


/**
 * optional bool popular_pick = 12;
 * @return {boolean}
 */
proto.common.Walmart.SearchPageProduct.prototype.getPopularPick = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 12, false));
};


/**
 * @param {boolean} value
 * @return {!proto.common.Walmart.SearchPageProduct} returns this
 */
proto.common.Walmart.SearchPageProduct.prototype.setPopularPick = function(value) {
  return jspb.Message.setProto3BooleanField(this, 12, value);
};


/**
 * optional bool rollback = 13;
 * @return {boolean}
 */
proto.common.Walmart.SearchPageProduct.prototype.getRollback = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 13, false));
};


/**
 * @param {boolean} value
 * @return {!proto.common.Walmart.SearchPageProduct} returns this
 */
proto.common.Walmart.SearchPageProduct.prototype.setRollback = function(value) {
  return jspb.Message.setProto3BooleanField(this, 13, value);
};


/**
 * optional float rating = 14;
 * @return {number}
 */
proto.common.Walmart.SearchPageProduct.prototype.getRating = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 14, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.common.Walmart.SearchPageProduct} returns this
 */
proto.common.Walmart.SearchPageProduct.prototype.setRating = function(value) {
  return jspb.Message.setProto3FloatField(this, 14, value);
};


/**
 * optional int32 ratings_total = 15;
 * @return {number}
 */
proto.common.Walmart.SearchPageProduct.prototype.getRatingsTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!proto.common.Walmart.SearchPageProduct} returns this
 */
proto.common.Walmart.SearchPageProduct.prototype.setRatingsTotal = function(value) {
  return jspb.Message.setProto3IntField(this, 15, value);
};


/**
 * repeated Variant variants = 16;
 * @return {!Array<!proto.common.Walmart.Variant>}
 */
proto.common.Walmart.SearchPageProduct.prototype.getVariantsList = function() {
  return /** @type{!Array<!proto.common.Walmart.Variant>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.common.Walmart.Variant, 16));
};


/**
 * @param {!Array<!proto.common.Walmart.Variant>} value
 * @return {!proto.common.Walmart.SearchPageProduct} returns this
*/
proto.common.Walmart.SearchPageProduct.prototype.setVariantsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 16, value);
};


/**
 * @param {!proto.common.Walmart.Variant=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.Walmart.Variant}
 */
proto.common.Walmart.SearchPageProduct.prototype.addVariants = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 16, opt_value, proto.common.Walmart.Variant, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.common.Walmart.SearchPageProduct} returns this
 */
proto.common.Walmart.SearchPageProduct.prototype.clearVariantsList = function() {
  return this.setVariantsList([]);
};


/**
 * optional float primary_price = 19;
 * @return {number}
 */
proto.common.Walmart.SearchPageProduct.prototype.getPrimaryPrice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 19, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.common.Walmart.SearchPageProduct} returns this
 */
proto.common.Walmart.SearchPageProduct.prototype.setPrimaryPrice = function(value) {
  return jspb.Message.setProto3FloatField(this, 19, value);
};


/**
 * optional int32 page = 17;
 * @return {number}
 */
proto.common.Walmart.SearchPageProduct.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
};


/**
 * @param {number} value
 * @return {!proto.common.Walmart.SearchPageProduct} returns this
 */
proto.common.Walmart.SearchPageProduct.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 17, value);
};


/**
 * optional int32 position_in_page = 18;
 * @return {number}
 */
proto.common.Walmart.SearchPageProduct.prototype.getPositionInPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 18, 0));
};


/**
 * @param {number} value
 * @return {!proto.common.Walmart.SearchPageProduct} returns this
 */
proto.common.Walmart.SearchPageProduct.prototype.setPositionInPage = function(value) {
  return jspb.Message.setProto3IntField(this, 18, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.Walmart.Variant.prototype.toObject = function(opt_includeInstance) {
  return proto.common.Walmart.Variant.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.Walmart.Variant} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Walmart.Variant.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    productId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    title: jspb.Message.getFieldWithDefault(msg, 3, ""),
    image: jspb.Message.getFieldWithDefault(msg, 4, ""),
    link: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.Walmart.Variant}
 */
proto.common.Walmart.Variant.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.Walmart.Variant;
  return proto.common.Walmart.Variant.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.Walmart.Variant} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.Walmart.Variant}
 */
proto.common.Walmart.Variant.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setItemId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setImage(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setLink(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.Walmart.Variant.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.Walmart.Variant.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.Walmart.Variant} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Walmart.Variant.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getProductId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getImage();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getLink();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string item_id = 1;
 * @return {string}
 */
proto.common.Walmart.Variant.prototype.getItemId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Walmart.Variant} returns this
 */
proto.common.Walmart.Variant.prototype.setItemId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string product_id = 2;
 * @return {string}
 */
proto.common.Walmart.Variant.prototype.getProductId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Walmart.Variant} returns this
 */
proto.common.Walmart.Variant.prototype.setProductId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string title = 3;
 * @return {string}
 */
proto.common.Walmart.Variant.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Walmart.Variant} returns this
 */
proto.common.Walmart.Variant.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string image = 4;
 * @return {string}
 */
proto.common.Walmart.Variant.prototype.getImage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Walmart.Variant} returns this
 */
proto.common.Walmart.Variant.prototype.setImage = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string link = 5;
 * @return {string}
 */
proto.common.Walmart.Variant.prototype.getLink = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Walmart.Variant} returns this
 */
proto.common.Walmart.Variant.prototype.setLink = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.common.Walmart.Facet.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.Walmart.Facet.prototype.toObject = function(opt_includeInstance) {
  return proto.common.Walmart.Facet.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.Walmart.Facet} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Walmart.Facet.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    displayName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    valuesList: jspb.Message.toObjectList(msg.getValuesList(),
    proto.common.Walmart.Facet.Value.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.Walmart.Facet}
 */
proto.common.Walmart.Facet.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.Walmart.Facet;
  return proto.common.Walmart.Facet.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.Walmart.Facet} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.Walmart.Facet}
 */
proto.common.Walmart.Facet.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayName(value);
      break;
    case 3:
      var value = new proto.common.Walmart.Facet.Value;
      reader.readMessage(value,proto.common.Walmart.Facet.Value.deserializeBinaryFromReader);
      msg.addValues(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.Walmart.Facet.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.Walmart.Facet.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.Walmart.Facet} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Walmart.Facet.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDisplayName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getValuesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.common.Walmart.Facet.Value.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.Walmart.Facet.Value.prototype.toObject = function(opt_includeInstance) {
  return proto.common.Walmart.Facet.Value.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.Walmart.Facet.Value} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Walmart.Facet.Value.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    count: jspb.Message.getFieldWithDefault(msg, 2, 0),
    active: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.Walmart.Facet.Value}
 */
proto.common.Walmart.Facet.Value.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.Walmart.Facet.Value;
  return proto.common.Walmart.Facet.Value.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.Walmart.Facet.Value} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.Walmart.Facet.Value}
 */
proto.common.Walmart.Facet.Value.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCount(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setActive(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.Walmart.Facet.Value.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.Walmart.Facet.Value.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.Walmart.Facet.Value} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Walmart.Facet.Value.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCount();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getActive();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.common.Walmart.Facet.Value.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Walmart.Facet.Value} returns this
 */
proto.common.Walmart.Facet.Value.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 count = 2;
 * @return {number}
 */
proto.common.Walmart.Facet.Value.prototype.getCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.common.Walmart.Facet.Value} returns this
 */
proto.common.Walmart.Facet.Value.prototype.setCount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional bool active = 3;
 * @return {boolean}
 */
proto.common.Walmart.Facet.Value.prototype.getActive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.common.Walmart.Facet.Value} returns this
 */
proto.common.Walmart.Facet.Value.prototype.setActive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.common.Walmart.Facet.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Walmart.Facet} returns this
 */
proto.common.Walmart.Facet.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string display_name = 2;
 * @return {string}
 */
proto.common.Walmart.Facet.prototype.getDisplayName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Walmart.Facet} returns this
 */
proto.common.Walmart.Facet.prototype.setDisplayName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated Value values = 3;
 * @return {!Array<!proto.common.Walmart.Facet.Value>}
 */
proto.common.Walmart.Facet.prototype.getValuesList = function() {
  return /** @type{!Array<!proto.common.Walmart.Facet.Value>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.common.Walmart.Facet.Value, 3));
};


/**
 * @param {!Array<!proto.common.Walmart.Facet.Value>} value
 * @return {!proto.common.Walmart.Facet} returns this
*/
proto.common.Walmart.Facet.prototype.setValuesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.common.Walmart.Facet.Value=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.Walmart.Facet.Value}
 */
proto.common.Walmart.Facet.prototype.addValues = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.common.Walmart.Facet.Value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.common.Walmart.Facet} returns this
 */
proto.common.Walmart.Facet.prototype.clearValuesList = function() {
  return this.setValuesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.Walmart.SearchResult.prototype.toObject = function(opt_includeInstance) {
  return proto.common.Walmart.SearchResult.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.Walmart.SearchResult} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Walmart.SearchResult.toObject = function(includeInstance, msg) {
  var f, obj = {
    page: jspb.Message.getFieldWithDefault(msg, 1, 0),
    position: jspb.Message.getFieldWithDefault(msg, 2, 0),
    positionInPage: jspb.Message.getFieldWithDefault(msg, 3, 0),
    itemId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    sponsored: jspb.Message.getBooleanFieldWithDefault(msg, 5, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.Walmart.SearchResult}
 */
proto.common.Walmart.SearchResult.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.Walmart.SearchResult;
  return proto.common.Walmart.SearchResult.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.Walmart.SearchResult} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.Walmart.SearchResult}
 */
proto.common.Walmart.SearchResult.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPage(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPosition(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPositionInPage(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setItemId(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSponsored(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.Walmart.SearchResult.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.Walmart.SearchResult.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.Walmart.SearchResult} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Walmart.SearchResult.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPage();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getPosition();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getPositionInPage();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getItemId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getSponsored();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
};


/**
 * optional int32 page = 1;
 * @return {number}
 */
proto.common.Walmart.SearchResult.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.common.Walmart.SearchResult} returns this
 */
proto.common.Walmart.SearchResult.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 position = 2;
 * @return {number}
 */
proto.common.Walmart.SearchResult.prototype.getPosition = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.common.Walmart.SearchResult} returns this
 */
proto.common.Walmart.SearchResult.prototype.setPosition = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 position_in_page = 3;
 * @return {number}
 */
proto.common.Walmart.SearchResult.prototype.getPositionInPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.common.Walmart.SearchResult} returns this
 */
proto.common.Walmart.SearchResult.prototype.setPositionInPage = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string item_id = 4;
 * @return {string}
 */
proto.common.Walmart.SearchResult.prototype.getItemId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Walmart.SearchResult} returns this
 */
proto.common.Walmart.SearchResult.prototype.setItemId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional bool sponsored = 5;
 * @return {boolean}
 */
proto.common.Walmart.SearchResult.prototype.getSponsored = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.common.Walmart.SearchResult} returns this
 */
proto.common.Walmart.SearchResult.prototype.setSponsored = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.common.Walmart.SearchResults.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.Walmart.SearchResults.prototype.toObject = function(opt_includeInstance) {
  return proto.common.Walmart.SearchResults.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.Walmart.SearchResults} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Walmart.SearchResults.toObject = function(includeInstance, msg) {
  var f, obj = {
    searchResultsList: jspb.Message.toObjectList(msg.getSearchResultsList(),
    proto.common.Walmart.SearchResult.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.Walmart.SearchResults}
 */
proto.common.Walmart.SearchResults.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.Walmart.SearchResults;
  return proto.common.Walmart.SearchResults.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.Walmart.SearchResults} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.Walmart.SearchResults}
 */
proto.common.Walmart.SearchResults.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.common.Walmart.SearchResult;
      reader.readMessage(value,proto.common.Walmart.SearchResult.deserializeBinaryFromReader);
      msg.addSearchResults(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.Walmart.SearchResults.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.Walmart.SearchResults.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.Walmart.SearchResults} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Walmart.SearchResults.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSearchResultsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.common.Walmart.SearchResult.serializeBinaryToWriter
    );
  }
};


/**
 * repeated SearchResult search_results = 1;
 * @return {!Array<!proto.common.Walmart.SearchResult>}
 */
proto.common.Walmart.SearchResults.prototype.getSearchResultsList = function() {
  return /** @type{!Array<!proto.common.Walmart.SearchResult>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.common.Walmart.SearchResult, 1));
};


/**
 * @param {!Array<!proto.common.Walmart.SearchResult>} value
 * @return {!proto.common.Walmart.SearchResults} returns this
*/
proto.common.Walmart.SearchResults.prototype.setSearchResultsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.common.Walmart.SearchResult=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.Walmart.SearchResult}
 */
proto.common.Walmart.SearchResults.prototype.addSearchResults = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.common.Walmart.SearchResult, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.common.Walmart.SearchResults} returns this
 */
proto.common.Walmart.SearchResults.prototype.clearSearchResultsList = function() {
  return this.setSearchResultsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.Walmart.Marketplace.prototype.toObject = function(opt_includeInstance) {
  return proto.common.Walmart.Marketplace.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.Walmart.Marketplace} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Walmart.Marketplace.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.Walmart.Marketplace}
 */
proto.common.Walmart.Marketplace.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.Walmart.Marketplace;
  return proto.common.Walmart.Marketplace.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.Walmart.Marketplace} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.Walmart.Marketplace}
 */
proto.common.Walmart.Marketplace.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.Walmart.Marketplace.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.Walmart.Marketplace.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.Walmart.Marketplace} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Walmart.Marketplace.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


/**
 * @enum {number}
 */
proto.common.Walmart.Marketplace.Option = {
  UNKNOWN: 0,
  CANADA: 1,
  UNITED_STATES: 2,
  MEXICO: 3
};




if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.Walmart.Marketplace.Config.prototype.toObject = function(opt_includeInstance) {
  return proto.common.Walmart.Marketplace.Config.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.Walmart.Marketplace.Config} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Walmart.Marketplace.Config.toObject = function(includeInstance, msg) {
  var f, obj = {
    marketplaceId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    countryCode: jspb.Message.getFieldWithDefault(msg, 3, ""),
    domain: jspb.Message.getFieldWithDefault(msg, 4, ""),
    queryTimezone: jspb.Message.getFieldWithDefault(msg, 5, ""),
    currencyCode: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.Walmart.Marketplace.Config}
 */
proto.common.Walmart.Marketplace.Config.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.Walmart.Marketplace.Config;
  return proto.common.Walmart.Marketplace.Config.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.Walmart.Marketplace.Config} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.Walmart.Marketplace.Config}
 */
proto.common.Walmart.Marketplace.Config.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMarketplaceId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountryCode(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDomain(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setQueryTimezone(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrencyCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.Walmart.Marketplace.Config.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.Walmart.Marketplace.Config.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.Walmart.Marketplace.Config} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Walmart.Marketplace.Config.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMarketplaceId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCountryCode();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDomain();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getQueryTimezone();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCurrencyCode();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string marketplace_id = 2;
 * @return {string}
 */
proto.common.Walmart.Marketplace.Config.prototype.getMarketplaceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Walmart.Marketplace.Config} returns this
 */
proto.common.Walmart.Marketplace.Config.prototype.setMarketplaceId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string country_code = 3;
 * @return {string}
 */
proto.common.Walmart.Marketplace.Config.prototype.getCountryCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Walmart.Marketplace.Config} returns this
 */
proto.common.Walmart.Marketplace.Config.prototype.setCountryCode = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string domain = 4;
 * @return {string}
 */
proto.common.Walmart.Marketplace.Config.prototype.getDomain = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Walmart.Marketplace.Config} returns this
 */
proto.common.Walmart.Marketplace.Config.prototype.setDomain = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string query_timezone = 5;
 * @return {string}
 */
proto.common.Walmart.Marketplace.Config.prototype.getQueryTimezone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Walmart.Marketplace.Config} returns this
 */
proto.common.Walmart.Marketplace.Config.prototype.setQueryTimezone = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string currency_code = 6;
 * @return {string}
 */
proto.common.Walmart.Marketplace.Config.prototype.getCurrencyCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Walmart.Marketplace.Config} returns this
 */
proto.common.Walmart.Marketplace.Config.prototype.setCurrencyCode = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.Walmart.AdTargetType.prototype.toObject = function(opt_includeInstance) {
  return proto.common.Walmart.AdTargetType.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.Walmart.AdTargetType} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Walmart.AdTargetType.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.Walmart.AdTargetType}
 */
proto.common.Walmart.AdTargetType.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.Walmart.AdTargetType;
  return proto.common.Walmart.AdTargetType.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.Walmart.AdTargetType} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.Walmart.AdTargetType}
 */
proto.common.Walmart.AdTargetType.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.Walmart.AdTargetType.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.Walmart.AdTargetType.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.Walmart.AdTargetType} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Walmart.AdTargetType.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


/**
 * @enum {number}
 */
proto.common.Walmart.AdTargetType.Option = {
  UNKNOWN: 0,
  CUSTOM: 1,
  PRODUCT: 2,
  SEARCH: 3,
  BRAND_SHOP: 4,
  BRAND_SHELF: 5
};




if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.Walmart.Brand.prototype.toObject = function(opt_includeInstance) {
  return proto.common.Walmart.Brand.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.Walmart.Brand} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Walmart.Brand.toObject = function(includeInstance, msg) {
  var f, obj = {
    marketplace: jspb.Message.getFieldWithDefault(msg, 1, 0),
    brandName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    brandDetails: (f = msg.getBrandDetails()) && proto.common.Walmart.BrandDetails.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.Walmart.Brand}
 */
proto.common.Walmart.Brand.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.Walmart.Brand;
  return proto.common.Walmart.Brand.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.Walmart.Brand} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.Walmart.Brand}
 */
proto.common.Walmart.Brand.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.common.Walmart.Marketplace.Option} */ (reader.readEnum());
      msg.setMarketplace(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBrandName(value);
      break;
    case 3:
      var value = new proto.common.Walmart.BrandDetails;
      reader.readMessage(value,proto.common.Walmart.BrandDetails.deserializeBinaryFromReader);
      msg.setBrandDetails(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.Walmart.Brand.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.Walmart.Brand.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.Walmart.Brand} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Walmart.Brand.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMarketplace();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getBrandName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getBrandDetails();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.common.Walmart.BrandDetails.serializeBinaryToWriter
    );
  }
};


/**
 * optional Marketplace.Option marketplace = 1;
 * @return {!proto.common.Walmart.Marketplace.Option}
 */
proto.common.Walmart.Brand.prototype.getMarketplace = function() {
  return /** @type {!proto.common.Walmart.Marketplace.Option} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.common.Walmart.Marketplace.Option} value
 * @return {!proto.common.Walmart.Brand} returns this
 */
proto.common.Walmart.Brand.prototype.setMarketplace = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string brand_name = 2;
 * @return {string}
 */
proto.common.Walmart.Brand.prototype.getBrandName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Walmart.Brand} returns this
 */
proto.common.Walmart.Brand.prototype.setBrandName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional BrandDetails brand_details = 3;
 * @return {?proto.common.Walmart.BrandDetails}
 */
proto.common.Walmart.Brand.prototype.getBrandDetails = function() {
  return /** @type{?proto.common.Walmart.BrandDetails} */ (
    jspb.Message.getWrapperField(this, proto.common.Walmart.BrandDetails, 3));
};


/**
 * @param {?proto.common.Walmart.BrandDetails|undefined} value
 * @return {!proto.common.Walmart.Brand} returns this
*/
proto.common.Walmart.Brand.prototype.setBrandDetails = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.common.Walmart.Brand} returns this
 */
proto.common.Walmart.Brand.prototype.clearBrandDetails = function() {
  return this.setBrandDetails(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.common.Walmart.Brand.prototype.hasBrandDetails = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.common.Walmart.BrandDetails.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.Walmart.BrandDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.common.Walmart.BrandDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.Walmart.BrandDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Walmart.BrandDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.common.Walmart.BrandDetails.Item.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.Walmart.BrandDetails}
 */
proto.common.Walmart.BrandDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.Walmart.BrandDetails;
  return proto.common.Walmart.BrandDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.Walmart.BrandDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.Walmart.BrandDetails}
 */
proto.common.Walmart.BrandDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.common.Walmart.BrandDetails.Item;
      reader.readMessage(value,proto.common.Walmart.BrandDetails.Item.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.Walmart.BrandDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.Walmart.BrandDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.Walmart.BrandDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Walmart.BrandDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.common.Walmart.BrandDetails.Item.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.Walmart.BrandDetails.Item.prototype.toObject = function(opt_includeInstance) {
  return proto.common.Walmart.BrandDetails.Item.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.Walmart.BrandDetails.Item} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Walmart.BrandDetails.Item.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    itemName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    priceMicros: jspb.Message.getFieldWithDefault(msg, 3, 0),
    primaryItemId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    variantLabel: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.Walmart.BrandDetails.Item}
 */
proto.common.Walmart.BrandDetails.Item.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.Walmart.BrandDetails.Item;
  return proto.common.Walmart.BrandDetails.Item.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.Walmart.BrandDetails.Item} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.Walmart.BrandDetails.Item}
 */
proto.common.Walmart.BrandDetails.Item.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setItemId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setItemName(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPriceMicros(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPrimaryItemId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setVariantLabel(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.Walmart.BrandDetails.Item.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.Walmart.BrandDetails.Item.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.Walmart.BrandDetails.Item} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Walmart.BrandDetails.Item.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getItemName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPriceMicros();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getPrimaryItemId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getVariantLabel();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string item_id = 1;
 * @return {string}
 */
proto.common.Walmart.BrandDetails.Item.prototype.getItemId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Walmart.BrandDetails.Item} returns this
 */
proto.common.Walmart.BrandDetails.Item.prototype.setItemId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string item_name = 2;
 * @return {string}
 */
proto.common.Walmart.BrandDetails.Item.prototype.getItemName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Walmart.BrandDetails.Item} returns this
 */
proto.common.Walmart.BrandDetails.Item.prototype.setItemName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 price_micros = 3;
 * @return {number}
 */
proto.common.Walmart.BrandDetails.Item.prototype.getPriceMicros = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.common.Walmart.BrandDetails.Item} returns this
 */
proto.common.Walmart.BrandDetails.Item.prototype.setPriceMicros = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string primary_item_id = 4;
 * @return {string}
 */
proto.common.Walmart.BrandDetails.Item.prototype.getPrimaryItemId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Walmart.BrandDetails.Item} returns this
 */
proto.common.Walmart.BrandDetails.Item.prototype.setPrimaryItemId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string variant_label = 5;
 * @return {string}
 */
proto.common.Walmart.BrandDetails.Item.prototype.getVariantLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.common.Walmart.BrandDetails.Item} returns this
 */
proto.common.Walmart.BrandDetails.Item.prototype.setVariantLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * repeated Item items = 1;
 * @return {!Array<!proto.common.Walmart.BrandDetails.Item>}
 */
proto.common.Walmart.BrandDetails.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.common.Walmart.BrandDetails.Item>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.common.Walmart.BrandDetails.Item, 1));
};


/**
 * @param {!Array<!proto.common.Walmart.BrandDetails.Item>} value
 * @return {!proto.common.Walmart.BrandDetails} returns this
*/
proto.common.Walmart.BrandDetails.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.common.Walmart.BrandDetails.Item=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.Walmart.BrandDetails.Item}
 */
proto.common.Walmart.BrandDetails.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.common.Walmart.BrandDetails.Item, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.common.Walmart.BrandDetails} returns this
 */
proto.common.Walmart.BrandDetails.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.Walmart.SellerType.prototype.toObject = function(opt_includeInstance) {
  return proto.common.Walmart.SellerType.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.Walmart.SellerType} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Walmart.SellerType.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.Walmart.SellerType}
 */
proto.common.Walmart.SellerType.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.Walmart.SellerType;
  return proto.common.Walmart.SellerType.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.Walmart.SellerType} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.Walmart.SellerType}
 */
proto.common.Walmart.SellerType.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.Walmart.SellerType.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.Walmart.SellerType.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.Walmart.SellerType} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Walmart.SellerType.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


/**
 * @enum {number}
 */
proto.common.Walmart.SellerType.Option = {
  UNKNOWN: 0,
  FIRST_PARTY: 1,
  THIRD_PARTY: 2
};




if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.Walmart.AppType.prototype.toObject = function(opt_includeInstance) {
  return proto.common.Walmart.AppType.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.Walmart.AppType} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Walmart.AppType.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.Walmart.AppType}
 */
proto.common.Walmart.AppType.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.Walmart.AppType;
  return proto.common.Walmart.AppType.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.Walmart.AppType} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.Walmart.AppType}
 */
proto.common.Walmart.AppType.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.Walmart.AppType.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.Walmart.AppType.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.Walmart.AppType} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Walmart.AppType.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


/**
 * @enum {number}
 */
proto.common.Walmart.AppType.Option = {
  UNKNOWN: 0,
  ANDROID: 1,
  IOS: 2,
  WEB: 3
};




if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.common.Walmart.AttributionSource.prototype.toObject = function(opt_includeInstance) {
  return proto.common.Walmart.AttributionSource.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.common.Walmart.AttributionSource} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Walmart.AttributionSource.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.common.Walmart.AttributionSource}
 */
proto.common.Walmart.AttributionSource.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.common.Walmart.AttributionSource;
  return proto.common.Walmart.AttributionSource.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.common.Walmart.AttributionSource} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.common.Walmart.AttributionSource}
 */
proto.common.Walmart.AttributionSource.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.common.Walmart.AttributionSource.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.common.Walmart.AttributionSource.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.common.Walmart.AttributionSource} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.common.Walmart.AttributionSource.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


/**
 * @enum {number}
 */
proto.common.Walmart.AttributionSource.Option = {
  UNKNOWN: 0,
  WALMART_BACKEND_3DAY: 1,
  IMPACT_RADIUS_14DAY: 2
};


/**
 * A tuple of {field number, class constructor} for the extension
 * field named `walmartMarketplaceConfig`.
 * @type {!jspb.ExtensionFieldInfo<!proto.common.Walmart.Marketplace.Config>}
 */
proto.common.walmartMarketplaceConfig = new jspb.ExtensionFieldInfo(
    50007,
    {walmartMarketplaceConfig: 0},
    proto.common.Walmart.Marketplace.Config,
     /** @type {?function((boolean|undefined),!jspb.Message=): !Object} */ (
         proto.common.Walmart.Marketplace.Config.toObject),
    0);

google_protobuf_descriptor_pb.EnumValueOptions.extensionsBinary[50007] = new jspb.ExtensionFieldBinaryInfo(
    proto.common.walmartMarketplaceConfig,
    jspb.BinaryReader.prototype.readMessage,
    jspb.BinaryWriter.prototype.writeMessage,
    proto.common.Walmart.Marketplace.Config.serializeBinaryToWriter,
    proto.common.Walmart.Marketplace.Config.deserializeBinaryFromReader,
    false);
// This registers the extension field with the extended class, so that
// toObject() will function correctly.
google_protobuf_descriptor_pb.EnumValueOptions.extensions[50007] = proto.common.walmartMarketplaceConfig;

goog.object.extend(exports, proto.common);
