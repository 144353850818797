import { useState, useEffect, useCallback } from "react";

type UseURLParamOrLocalStorageOption<T> = {
  localStorageKey: string; // The key to use for local storage
  urlParam: string; // The name of the query param to check
  defaultValue: T; // The default value to use if nothing is found
};

// Attempt to to parse the data from the URL param.
const parseFromURL = <T,>(
  urlParam: string,
  searchParams: URLSearchParams
): T | null => {
  try {
    const paramValue = searchParams.get(urlParam);
    if (!paramValue) {
      return null;
    }
    const parsed: T = JSON.parse(paramValue);
    return parsed;
  } catch (err) {
    // If parse fails, ignore
    return null;
  }
};

/**
 * First tries to read the object from the URL query parameter, then from from
 * local storage, then falls back to a default value if neither is found or
 * valid. It also persists changes back to local storage.
 *
 * This hook will only work with objects that can be serialized to JSON. If you
 * want to store a primitive value or array, you should wrap it in an object.
 */
export function useURLParamOrLocalStorageObject<
  T extends Record<string, unknown>
>(options: UseURLParamOrLocalStorageOption<T>): [T, (val: T) => void] {
  const { localStorageKey, urlParam, defaultValue } = options;

  // Only read from URL/localStorage once on mount
  const [value, setValue] = useState<T>(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const urlData = parseFromURL<T>(urlParam, searchParams);
    if (urlData) {
      localStorage.setItem(localStorageKey, JSON.stringify(urlData));
      searchParams.delete(urlParam);

      let nextUrl = window.location.pathname;
      if (searchParams.toString()) {
        nextUrl += `?${searchParams}`;
      }
      window.history.replaceState({}, "", nextUrl);

      return urlData;
    }

    // If not found in URL, look in localStorage
    const storedData = localStorage.getItem(localStorageKey);
    if (storedData) {
      try {
        const parsed: T = JSON.parse(storedData);
        return parsed;
      } catch {
        // If parse fails, fallback to default
      }
    }

    // Fallback to defaultValue
    return defaultValue;
  });

  // Persist the current state back to local storage whenever it changes
  useEffect(() => {
    localStorage.setItem(localStorageKey, JSON.stringify(value));
  }, [value, localStorageKey]);

  const updateValue = useCallback(
    (newVal: T) => {
      const currentValString = JSON.stringify(newVal);
      const newValString = JSON.stringify(value);
      if (currentValString === newValString) {
        return;
      }
      setValue(newVal);
    },
    [value]
  );

  return [value, updateValue];
}
