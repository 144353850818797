/* eslint-disable */// @ts-nocheck
/* eslint-disable */
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /**
   * The `GenericScalar` scalar type represents a generic
   * GraphQL scalar value that could be:
   * String, Boolean, Int, Float, List or Object.
   */
  GenericScalar: any;
};

/** Defines the root of the GraphQL query tree. */
export type Query = {
  __typename?: 'Query';
  issues?: Maybe<Issues>;
  issue?: Maybe<Issue>;
  session?: Maybe<SessionInfo>;
  site?: Maybe<Site>;
  sessionHub?: Maybe<SessionHub>;
  hubTree?: Maybe<HubTree>;
  searchQueryExplorer?: Maybe<SearchQueryExplorer>;
  cpcBidAudit?: Maybe<CpcBidAudit>;
  addKeywordAudit?: Maybe<AddKeywordAudit>;
  idToNameMapping?: Maybe<IdToNameMapping>;
  customerHealthForSite?: Maybe<CustomerHealthForSite>;
  customerHealth?: Maybe<CustomerHealth>;
  groupEditor?: Maybe<GroupEditor>;
  googleSearch?: Maybe<GoogleSearch>;
  siteGrouping?: Maybe<SiteGrouping>;
  googleAdsListCustomers?: Maybe<Array<Maybe<GoogleAdsCustomer>>>;
  adwordsObjects?: Maybe<AdwordsObjects>;
  adwordsMetrics?: Maybe<AdwordsMetrics>;
  fuzzyPhraseClassifier?: Maybe<FuzzyPhraseClassifier>;
  ampdReport?: Maybe<AmpdReport>;
  opportunities?: Maybe<Opportunities>;
  opportunityHelp?: Maybe<OpportunityHelp>;
  opportunityStats?: Maybe<OpportunityStats>;
  getEligibleSubscriptionPlans?: Maybe<Array<Maybe<SubscriptionPlan>>>;
  sitemapInfo?: Maybe<SitemapInfo>;
  userBookmarks?: Maybe<UserBookmarks>;
  products?: Maybe<ProductsTop>;
};


/** Defines the root of the GraphQL query tree. */
export type QueryIssuesArgs = {
  site: SiteSelector;
  startDate?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['String']>;
  createdSince?: InputMaybe<Scalars['String']>;
  createdBefore?: InputMaybe<Scalars['String']>;
  includeStates?: InputMaybe<Array<InputMaybe<IssueState>>>;
  includeDimensionNames?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  includeDimensions?: InputMaybe<Array<InputMaybe<InputDimensionNameAndValue>>>;
  includeTags?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  includeAttributes?: InputMaybe<Array<InputMaybe<InputAttributeNameAndValue>>>;
  excludeAttributeNames?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  includeAssigneeUserIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** Defines the root of the GraphQL query tree. */
export type QueryIssueArgs = {
  issueId: Scalars['ID'];
  site: SiteSelector;
  siteId?: InputMaybe<Scalars['ID']>;
};


/** Defines the root of the GraphQL query tree. */
export type QuerySiteArgs = {
  site: SiteSelector;
};


/** Defines the root of the GraphQL query tree. */
export type QuerySessionHubArgs = {
  endDate: Scalars['String'];
  site?: InputMaybe<SiteSelector>;
  hub: HubSelector;
};


/** Defines the root of the GraphQL query tree. */
export type QueryHubTreeArgs = {
  site: SiteSelector;
  treeNodes: Array<InputMaybe<TreeNodeSelector>>;
  metric: Scalars['String'];
  treeId?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['String']>;
  issueId?: InputMaybe<Scalars['ID']>;
  minNumWeeks?: InputMaybe<Scalars['Int']>;
  numWeeks?: InputMaybe<Scalars['Int']>;
  previousPeriods?: InputMaybe<Array<InputMaybe<PeriodSelector>>>;
  currentPeriods?: InputMaybe<Array<InputMaybe<PeriodSelector>>>;
};


/** Defines the root of the GraphQL query tree. */
export type QuerySearchQueryExplorerArgs = {
  endDate: Scalars['String'];
  site: SiteSelector;
  numWeeks: Scalars['Int'];
  dimensions: Array<InputMaybe<Scalars['String']>>;
  metrics: Array<InputMaybe<Scalars['String']>>;
  clicksMetric?: InputMaybe<Scalars['String']>;
  clicksDimensions?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  minClicks?: InputMaybe<Scalars['Int']>;
  searchQueryDimension?: InputMaybe<Scalars['String']>;
  attributeJoinSelectors?: InputMaybe<Array<InputMaybe<AttributeJoinSelector>>>;
  metricJoinSelectors?: InputMaybe<Array<InputMaybe<MetricJoinSelector>>>;
};


/** Defines the root of the GraphQL query tree. */
export type QueryCpcBidAuditArgs = {
  endDate: Scalars['String'];
  site: SiteSelector;
  criteriaType: CriteriaType;
  startDate?: InputMaybe<Scalars['String']>;
  numWeeks?: InputMaybe<Scalars['Int']>;
  minClicks?: InputMaybe<Scalars['Int']>;
  minRoas?: InputMaybe<Scalars['Float']>;
  maxImpressionShare?: InputMaybe<Scalars['Float']>;
  minQualityScore?: InputMaybe<Scalars['Int']>;
  selectionType?: InputMaybe<CriteriaSelectionType>;
  selectionTopBottomCount?: InputMaybe<Scalars['Int']>;
  selectionTopBottomOffset?: InputMaybe<Scalars['Int']>;
  criteriaIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  includeZeroImpressions?: InputMaybe<Scalars['Boolean']>;
};


/** Defines the root of the GraphQL query tree. */
export type QueryAddKeywordAuditArgs = {
  endDate: Scalars['String'];
  site: SiteSelector;
  addKeywordSource: AddKeywordSource;
  numWeeks?: InputMaybe<Scalars['Int']>;
  minClicks?: InputMaybe<Scalars['Int']>;
  minRoas?: InputMaybe<Scalars['Float']>;
};


/** Defines the root of the GraphQL query tree. */
export type QueryIdToNameMappingArgs = {
  endDate: Scalars['String'];
  site: SiteSelector;
  idDimensions: Array<InputMaybe<Scalars['String']>>;
  nameDimension: Scalars['String'];
  referenceMetric: Scalars['String'];
  nameAttribute?: InputMaybe<Scalars['String']>;
  numWeeks?: InputMaybe<Scalars['Int']>;
  notSetValue?: InputMaybe<Scalars['String']>;
};


/** Defines the root of the GraphQL query tree. */
export type QueryCustomerHealthForSiteArgs = {
  site: SiteSelector;
};


/** Defines the root of the GraphQL query tree. */
export type QueryGoogleSearchArgs = {
  domain: Scalars['String'];
  term: Scalars['String'];
};


/** Defines the root of the GraphQL query tree. */
export type QuerySiteGroupingArgs = {
  site: SiteSelector;
  siteGroupingId: Scalars['String'];
  importVersion?: InputMaybe<Scalars['Int']>;
  importCsv?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  provisionalName?: InputMaybe<Scalars['String']>;
  provisionalCommonName?: InputMaybe<Scalars['String']>;
  provisionalValueType?: InputMaybe<ValueType>;
  provisionalGroups?: InputMaybe<Array<InputMaybe<InputSiteGroupingGroup>>>;
  extraDimensions?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  ignoreDimensions?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  dayRange?: InputMaybe<Scalars['Int']>;
};


/** Defines the root of the GraphQL query tree. */
export type QueryGoogleAdsListCustomersArgs = {
  site: SiteSelector;
  accessToken: Scalars['String'];
};


/** Defines the root of the GraphQL query tree. */
export type QueryAdwordsObjectsArgs = {
  site: SiteSelector;
  endDate?: InputMaybe<Scalars['String']>;
  numWeeks?: InputMaybe<Scalars['Int']>;
  campaignIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  campaignStatuses?: InputMaybe<Array<InputMaybe<Status>>>;
  adGroupIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  adGroupStatuses?: InputMaybe<Array<InputMaybe<Status>>>;
  criteriaIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  criteriaStatuses?: InputMaybe<Array<InputMaybe<Status>>>;
  isSample?: InputMaybe<Scalars['Boolean']>;
  queryId?: InputMaybe<Scalars['String']>;
};


/** Defines the root of the GraphQL query tree. */
export type QueryAdwordsMetricsArgs = {
  site: SiteSelector;
  endDate: Scalars['String'];
  startDate?: InputMaybe<Scalars['String']>;
  numWeeks?: InputMaybe<Scalars['Int']>;
  objectType?: InputMaybe<AdwordsObjectType>;
  campaignIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  adGroupIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  criteriaIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  queryTexts?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  includeAmazonAttributionData?: InputMaybe<Scalars['Boolean']>;
  queryByDay?: InputMaybe<Scalars['Boolean']>;
  queryId?: InputMaybe<Scalars['String']>;
};


/** Defines the root of the GraphQL query tree. */
export type QueryFuzzyPhraseClassifierArgs = {
  categories: Array<InputMaybe<InputCategory>>;
  newPhrases: Array<InputMaybe<Scalars['String']>>;
  limit?: InputMaybe<Scalars['Int']>;
};


/** Defines the root of the GraphQL query tree. */
export type QueryAmpdReportArgs = {
  reportType: ReportType;
  reportData: ReportData;
  includePausedKeywords?: InputMaybe<Scalars['Boolean']>;
};


/** Defines the root of the GraphQL query tree. */
export type QueryOpportunitiesArgs = {
  site: SiteSelector;
  limitPerType?: InputMaybe<Scalars['Int']>;
  aboveLimitSuspendHours?: InputMaybe<Scalars['Int']>;
  sampleUrl?: InputMaybe<Scalars['String']>;
};


/** Defines the root of the GraphQL query tree. */
export type QueryOpportunityStatsArgs = {
  site: SiteSelector;
  endDate: Scalars['String'];
};


/** Defines the root of the GraphQL query tree. */
export type QueryGetEligibleSubscriptionPlansArgs = {
  site: SiteSelector;
};


/** Defines the root of the GraphQL query tree. */
export type QuerySitemapInfoArgs = {
  site: SiteSelector;
  homePage?: InputMaybe<Scalars['String']>;
};


/** Defines the root of the GraphQL query tree. */
export type QueryUserBookmarksArgs = {
  featureCategory?: InputMaybe<Scalars['String']>;
};


/** Defines the root of the GraphQL query tree. */
export type QueryProductsArgs = {
  site: SiteSelector;
  includeUnpublished?: InputMaybe<Scalars['Boolean']>;
};

/** Input object for selecting a site by id or alias. */
export type SiteSelector = {
  siteId?: InputMaybe<Scalars['String']>;
  siteAlias?: InputMaybe<Scalars['String']>;
};

/** An enumeration. */
export enum IssueState {
  Unspecified = 'UNSPECIFIED',
  Open = 'OPEN',
  Closed = 'CLOSED'
}

/** Graphene object representing a dimension name-value pair input. */
export type InputDimensionNameAndValue = {
  name?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Graphene object representing an attribute name-value pair input. */
export type InputAttributeNameAndValue = {
  name?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** A list of issues */
export type Issues = {
  __typename?: 'Issues';
  issues?: Maybe<Array<Maybe<Issue>>>;
};

/**
 * Contains all of the display info for an Issue.
 *
 * Attributes:
 *     assignee_user_id: The id of the assignee of the user to the issue
 *     site_id: The id of the site that this anomaly was generated for
 *     issue_id: The id of this issue
 *     details: The issue details
 *     anomalies: The anomalies for this issue
 */
export type Issue = {
  __typename?: 'Issue';
  siteId?: Maybe<Scalars['String']>;
  issueId?: Maybe<Scalars['String']>;
  assigneeUser?: Maybe<SiteUser>;
  details?: Maybe<IssueDetails>;
  anomalies?: Maybe<Array<Maybe<Anomaly>>>;
  assigneeUserId?: Maybe<Scalars['String']>;
};

/** Details about a user of a given site. */
export type SiteUser = {
  __typename?: 'SiteUser';
  user?: Maybe<User>;
  siteRole?: Maybe<SiteRole>;
};

/** Details about a given user. */
export type User = {
  __typename?: 'User';
  id?: Maybe<Scalars['ID']>;
  email?: Maybe<Scalars['String']>;
  profile?: Maybe<UserProfile>;
  operator?: Maybe<Scalars['Boolean']>;
};

/** Graphene object for a User's profile. */
export type UserProfile = {
  __typename?: 'UserProfile';
  name?: Maybe<Scalars['String']>;
  givenName?: Maybe<Scalars['String']>;
  familyName?: Maybe<Scalars['String']>;
  nickname?: Maybe<Scalars['String']>;
  picture?: Maybe<Scalars['String']>;
};

/** An enumeration. */
export enum SiteRole {
  Undefined = 'UNDEFINED',
  None = 'NONE',
  User = 'USER',
  Admin = 'ADMIN',
  Disabled = 'DISABLED'
}

/** Contains details about the issue state and configuration. */
export type IssueDetails = {
  __typename?: 'IssueDetails';
  state?: Maybe<IssueState>;
  disposition?: Maybe<IssueDisposition>;
  dimensions?: Maybe<Array<Maybe<DimensionNameAndValue>>>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  attributes?: Maybe<Array<Maybe<AttributeNameAndValue>>>;
  comments?: Maybe<Array<Maybe<Comment>>>;
};

/** An enumeration. */
export enum IssueDisposition {
  Unspecified = 'UNSPECIFIED',
  HighlyUseful = 'HIGHLY_USEFUL',
  Useful = 'USEFUL',
  NotUseful = 'NOT_USEFUL'
}

/** Graphene object representing a dimension name-value pair. */
export type DimensionNameAndValue = {
  __typename?: 'DimensionNameAndValue';
  name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** Graphene object representing an attribute name-value pair. */
export type AttributeNameAndValue = {
  __typename?: 'AttributeNameAndValue';
  name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** A graphql comment. */
export type Comment = {
  __typename?: 'Comment';
  commentId?: Maybe<Scalars['String']>;
  owner?: Maybe<SiteUser>;
  text?: Maybe<Scalars['String']>;
  createdTime?: Maybe<DateTime>;
  lastUpdatedBy?: Maybe<SiteUser>;
  lastUpdatedTime?: Maybe<DateTime>;
};

/**
 * A graphql DateTime.
 *
 * Attributes:
 *     date: The date.
 *     time: The time.
 */
export type DateTime = {
  __typename?: 'DateTime';
  date?: Maybe<Date>;
  time?: Maybe<Time>;
};

/**
 * A graphql Date
 *
 * Attributes:
 *     year: the year, e.g. 2017
 *     month: the month, e.g. 10
 *     day: the day, e.g. 31
 */
export type Date = {
  __typename?: 'Date';
  year?: Maybe<Scalars['Int']>;
  month?: Maybe<Scalars['Int']>;
  day?: Maybe<Scalars['Int']>;
};

/**
 * A graphql Time without timezone.
 *
 * Attributes:
 *     hour: The hour 0 - 23.
 *     minute: The minute 0 - 59.
 *     second: The second 0 - 59.
 */
export type Time = {
  __typename?: 'Time';
  hour?: Maybe<Scalars['Int']>;
  minute?: Maybe<Scalars['Int']>;
  second?: Maybe<Scalars['Int']>;
};

/**
 * A graphql Anomaly object. This contains all of the display info
 * for an anomaly.
 *
 * Attributes:
 *     date: The date with which the anomaly is associated, this is
 *           typically the end date of the current period.
 *     title: The title of the anomaly.
 *     impact: The impacted metric and direction
 *     magnitude: The magnitude of the anomaly
 *     current_date_range: The more recent date range for the anomaly
 *     previous_date_range: The less recent date range for the anomaly
 *     amounts: The current, previous, and change amounts for the
 *              relevant metrics
 *     deep_dive_items: The items that provide contextual information
 */
export type Anomaly = {
  __typename?: 'Anomaly';
  date?: Maybe<Date>;
  title?: Maybe<Scalars['String']>;
  impact?: Maybe<Impact>;
  magnitude?: Maybe<Magnitude>;
  currentDateRange?: Maybe<DateRange>;
  previousDateRange?: Maybe<DateRange>;
  amounts?: Maybe<Array<Maybe<Amount>>>;
  gaLink?: Maybe<Scalars['String']>;
  deepDiveItems?: Maybe<Array<Maybe<DeepDiveItem>>>;
};

/**
 * A graphql Impact object
 *
 * Attributes:
 *     direction: An enum representing the direction of change
 *     metric: The changing metric
 */
export type Impact = {
  __typename?: 'Impact';
  direction?: Maybe<Direction>;
  metric?: Maybe<Scalars['String']>;
};

/** Represents whether a change has moved up or down */
export enum Direction {
  Up = 'UP',
  Down = 'DOWN'
}

/**
 * A graphql Magnitude object
 *
 * Attributes:
 *     amount: The amount of the change
 *     time_frame: The time frame over which the change occurred
 *     display: The display string, e.g. "$-1,342/week"
 */
export type Magnitude = {
  __typename?: 'Magnitude';
  amount?: Maybe<Scalars['Float']>;
  timeFrame?: Maybe<ImpactPeriod>;
  display?: Maybe<Scalars['String']>;
};

/** An enumeration. */
export enum ImpactPeriod {
  Undefined = 'UNDEFINED',
  Total = 'TOTAL',
  PerWeek = 'PER_WEEK'
}

/**
 * A graphql DateRange
 *
 * Attributes:
 *     start_date: the inclusive start of the date range
 *     end_date: the inclusive end of the date range
 */
export type DateRange = {
  __typename?: 'DateRange';
  startDate?: Maybe<Date>;
  endDate?: Maybe<Date>;
};

/**
 * A graphql Amount containing MetricAmounts for current,
 * previous, and change values
 *
 * Attributes:
 *     alias: The name of the metric
 *     current: The current amount
 *     previous: The previous amount
 *     relative_change: The relative change from previous to current
 */
export type Amount = {
  __typename?: 'Amount';
  alias?: Maybe<Scalars['String']>;
  current?: Maybe<MetricAmount>;
  previous?: Maybe<MetricAmount>;
  relativeChange?: Maybe<MetricAmount>;
};

/**
 * A graphql MetricAmount
 *
 * Attributes:
 *     amount: The numeric amount
 *     display_amount: The pre formatted amount for rendering
 */
export type MetricAmount = {
  __typename?: 'MetricAmount';
  amount?: Maybe<Scalars['Float']>;
  display?: Maybe<Scalars['String']>;
};

/**
 * A graphql DeepDiveItem
 *
 * Attributes:
 *     title: The title of this item
 *     amounts: The amounts for this item
 *     dimensions: The dimension names and values
 */
export type DeepDiveItem = {
  __typename?: 'DeepDiveItem';
  title?: Maybe<Scalars['String']>;
  amounts?: Maybe<Array<Maybe<Amount>>>;
  dimensions?: Maybe<Array<Maybe<DimensionNameAndValue>>>;
};

/** Graphene object for UserSiteInfo. */
export type SessionInfo = {
  __typename?: 'SessionInfo';
  session?: Maybe<Session>;
  user?: Maybe<User>;
  userSites?: Maybe<Array<Maybe<UserSite>>>;
};

/** Details about a given session. */
export type Session = {
  __typename?: 'Session';
  id?: Maybe<Scalars['String']>;
  expirationTimeSeconds?: Maybe<Scalars['Int']>;
};

/** Graphene object for SiteUserSite. */
export type UserSite = {
  __typename?: 'UserSite';
  site?: Maybe<Site>;
  isSiteAdmin?: Maybe<Scalars['Boolean']>;
};

/** Details about a given site. */
export type Site = {
  __typename?: 'Site';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  siteAlias?: Maybe<Scalars['String']>;
  billingAccountStatus?: Maybe<BillingAccountStatus>;
  stripeInfo?: Maybe<StripeInfo>;
  currencyCode?: Maybe<Scalars['String']>;
  webAppEnabled?: Maybe<Scalars['Boolean']>;
  hasExecutedActions?: Maybe<Scalars['Boolean']>;
  trialExpirationTime?: Maybe<DateTime>;
  featureMask?: Maybe<Array<Maybe<Scalars['String']>>>;
  hasAdwordsAccount?: Maybe<Scalars['Boolean']>;
  adwordsCustomerIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  adwordsAccounts?: Maybe<Array<Maybe<AdWordsAccount>>>;
  amazonInfo?: Maybe<AmazonInfo>;
  hasGaInfo?: Maybe<Scalars['Boolean']>;
  gaViewIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  shopifyShop?: Maybe<Scalars['String']>;
  shopifyInfo?: Maybe<ShopifyInfo>;
  bigCommerceStore?: Maybe<Scalars['String']>;
  bigCommerceInfo?: Maybe<BigCommerceInfo>;
  siteDomainName?: Maybe<Scalars['String']>;
  targetRoas?: Maybe<Scalars['Float']>;
  siteUsers?: Maybe<Array<Maybe<SiteUser>>>;
  siteGroupings?: Maybe<Array<Maybe<SiteGrouping>>>;
  hubs?: Maybe<Array<Maybe<Hub>>>;
  availableMetrics?: Maybe<Array<Maybe<MetricDefinition>>>;
  siteProblems?: Maybe<Array<Maybe<SiteProblem>>>;
  userFeedbackStatus?: Maybe<UserFeedbackStatus>;
  salesPlatforms?: Maybe<SalesPlatforms>;
  isAmpdAdsSubscriber?: Maybe<Scalars['Boolean']>;
  coupon?: Maybe<Scalars['String']>;
  contactEmail?: Maybe<Scalars['String']>;
};


/** Details about a given site. */
export type SiteAvailableMetricsArgs = {
  dataSources?: InputMaybe<Array<InputMaybe<DataSource>>>;
};

/** An enumeration. */
export enum BillingAccountStatus {
  Unknown = 'UNKNOWN',
  Active = 'ACTIVE',
  Linked = 'LINKED',
  Delinquent = 'DELINQUENT',
  TrialActive = 'TRIAL_ACTIVE',
  Pending = 'PENDING',
  TrialExpired = 'TRIAL_EXPIRED',
  Cancelled = 'CANCELLED'
}

/** Details from the stripe subscription information from a billing account. */
export type StripeInfo = {
  __typename?: 'StripeInfo';
  subscriptionStatus?: Maybe<Scalars['String']>;
  subscriptionPlan?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  amountCents?: Maybe<Scalars['Int']>;
};

/** Details for a Google Ads account, from common.AdWordsAccount. */
export type AdWordsAccount = {
  __typename?: 'AdWordsAccount';
  name?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  currencyCode?: Maybe<Scalars['String']>;
  invitation?: Maybe<AdWordsAccountInvitation>;
};

/** An invitation to join an AdWords account. */
export type AdWordsAccountInvitation = {
  __typename?: 'AdWordsAccountInvitation';
  link?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  status?: Maybe<AdWordsAccountInvitationStatus>;
};

/** Status of an AdWordsAccountInvitation */
export enum AdWordsAccountInvitationStatus {
  Unknown = 'UNKNOWN',
  None = 'NONE',
  Valid = 'VALID',
  IsMember = 'IS_MEMBER',
  Expired = 'EXPIRED'
}

/** Details from the AmazonInfo of a site. */
export type AmazonInfo = {
  __typename?: 'AmazonInfo';
  advertisingAccounts?: Maybe<Array<Maybe<AmazonAdvertisingAccount>>>;
  sellerAccounts?: Maybe<Array<Maybe<AmazonSellerAccount>>>;
};

/** Details from an AmazonAdvertisingAccount. */
export type AmazonAdvertisingAccount = {
  __typename?: 'AmazonAdvertisingAccount';
  email?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  region?: Maybe<AmazonRegion>;
};

/** An enumeration. */
export enum AmazonRegion {
  Unknown = 'UNKNOWN',
  NorthAmerica = 'NORTH_AMERICA',
  Europe = 'EUROPE',
  FarEast = 'FAR_EAST'
}

/** Details from the AmazonSellerAccount of a site. */
export type AmazonSellerAccount = {
  __typename?: 'AmazonSellerAccount';
  sellingPartnerId?: Maybe<Scalars['String']>;
  region?: Maybe<AmazonRegion>;
};

/** Details from the ShopifyInfo of a site. */
export type ShopifyInfo = {
  __typename?: 'ShopifyInfo';
  shop?: Maybe<Scalars['String']>;
  currencyCode?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
};

/** Details from the BigCommerceInfo of a site. */
export type BigCommerceInfo = {
  __typename?: 'BigCommerceInfo';
  storeHash?: Maybe<Scalars['String']>;
  currencyCode?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
};

/** Details about a given site grouping. */
export type SiteGrouping = {
  __typename?: 'SiteGrouping';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  commonName?: Maybe<Scalars['String']>;
  valueType?: Maybe<ValueType>;
  version?: Maybe<Scalars['Int']>;
  groups?: Maybe<Array<Maybe<SiteGroupingGroup>>>;
  dimensionNames?: Maybe<Array<Maybe<Scalars['String']>>>;
  metricName?: Maybe<Scalars['String']>;
  csv?: Maybe<Array<Maybe<Scalars['String']>>>;
};


/** Details about a given site grouping. */
export type SiteGroupingGroupsArgs = {
  maxMembers?: InputMaybe<Scalars['Int']>;
};

/** An enumeration. */
export enum ValueType {
  Undefined = 'UNDEFINED',
  String = 'STRING',
  Integer = 'INTEGER',
  Float = 'FLOAT',
  Date = 'DATE',
  McfSequence = 'MCF_SEQUENCE',
  StringList = 'STRING_LIST',
  IntegerList = 'INTEGER_LIST'
}

/** Details about a group in a site grouping. */
export type SiteGroupingGroup = {
  __typename?: 'SiteGroupingGroup';
  name?: Maybe<Scalars['String']>;
  filters?: Maybe<Array<Maybe<SiteGroupingFilter>>>;
  members?: Maybe<Array<Maybe<SiteGroupingMember>>>;
  memberCount?: Maybe<Scalars['Int']>;
  metricPercentage?: Maybe<Scalars['Float']>;
  priority?: Maybe<Scalars['Int']>;
};

/** Details about a dimension selector filter. */
export type SiteGroupingFilter = {
  __typename?: 'SiteGroupingFilter';
  dimension?: Maybe<Scalars['String']>;
  operator?: Maybe<FilterOperator>;
  values?: Maybe<Array<Maybe<Scalars['String']>>>;
  orGroup?: Maybe<Scalars['Int']>;
  negated?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
};

/** An enumeration. */
export enum FilterOperator {
  Unspecified = 'UNSPECIFIED',
  Equals = 'EQUALS',
  LessThan = 'LESS_THAN',
  GreaterThan = 'GREATER_THAN',
  InList = 'IN_LIST',
  StringContains = 'STRING_CONTAINS',
  StringBeginsWith = 'STRING_BEGINS_WITH',
  StringEndsWith = 'STRING_ENDS_WITH',
  StringContainsPhrase = 'STRING_CONTAINS_PHRASE',
  ListContainsAny = 'LIST_CONTAINS_ANY',
  ListContainsAll = 'LIST_CONTAINS_ALL',
  ListContainsNone = 'LIST_CONTAINS_NONE'
}

/** Details about the member of a grouping group. */
export type SiteGroupingMember = {
  __typename?: 'SiteGroupingMember';
  dimensionValues?: Maybe<Array<Maybe<Scalars['String']>>>;
  metricValue?: Maybe<Scalars['Float']>;
  metricPercentage?: Maybe<Scalars['Float']>;
};

/** Details about a user of a given site. */
export type Hub = {
  __typename?: 'Hub';
  alias?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

/** An enumeration. */
export enum DataSource {
  Undefined = 'UNDEFINED',
  GoogleAnalytics = 'GOOGLE_ANALYTICS',
  Metricstory = 'METRICSTORY',
  MultiChannelFunnel = 'MULTI_CHANNEL_FUNNEL',
  Adwords = 'ADWORDS',
  Evaluation = 'EVALUATION',
  AmazonAttribution = 'AMAZON_ATTRIBUTION',
  AmazonAds = 'AMAZON_ADS',
  AmazonSeller = 'AMAZON_SELLER',
  WalmartAttribution = 'WALMART_ATTRIBUTION',
  Facebook = 'FACEBOOK'
}

/** Contains all of the information about a metric (datatype, formatting, etc.) */
export type MetricDefinition = {
  __typename?: 'MetricDefinition';
  name?: Maybe<Scalars['String']>;
  commonName?: Maybe<Scalars['String']>;
  dataSource?: Maybe<DataSource>;
  summable?: Maybe<Scalars['Boolean']>;
  formatting?: Maybe<MetricFormat>;
  formula?: Maybe<MetricFormula>;
  metricGroups?: Maybe<Array<Maybe<Scalars['String']>>>;
  briefDescription?: Maybe<Scalars['String']>;
  isCurrency?: Maybe<Scalars['Boolean']>;
  currencyCode?: Maybe<Scalars['String']>;
};

/** Information about how to format a metric value. */
export type MetricFormat = {
  __typename?: 'MetricFormat';
  prefix?: Maybe<Scalars['String']>;
  postfix?: Maybe<Scalars['String']>;
  precision?: Maybe<Scalars['Int']>;
  sign?: Maybe<Scalars['Boolean']>;
  isPercent?: Maybe<Scalars['Boolean']>;
  currencyUseMinorUnits?: Maybe<Scalars['Boolean']>;
};

/** Formula describing how to calculate a metric. */
export type MetricFormula = {
  __typename?: 'MetricFormula';
  x?: Maybe<MetricOperand>;
  y?: Maybe<MetricOperand>;
  op?: Maybe<MetricOperator>;
};

/** An operand. */
export type MetricOperand = {
  __typename?: 'MetricOperand';
  met?: Maybe<Scalars['String']>;
  constant?: Maybe<Scalars['Float']>;
};

/** Describes the operator for a formula. */
export enum MetricOperator {
  Undefined = 'UNDEFINED',
  Add = 'ADD',
  Subtract = 'SUBTRACT',
  Multiply = 'MULTIPLY',
  Divide = 'DIVIDE'
}

/** An object representing a problem with a site. */
export type SiteProblem = {
  __typename?: 'SiteProblem';
  message?: Maybe<Scalars['String']>;
  error?: Maybe<SiteError>;
};

/** Union type for all concrete SiteError graphql objects. */
export type SiteError = DomainError | DataSourceLinkError;

/** An error representing a problem with a site's domain. */
export type DomainError = BaseSiteError & {
  __typename?: 'DomainError';
  errorType?: Maybe<Scalars['String']>;
  domain?: Maybe<Scalars['String']>;
};

/** A base error from which all errors inherit. */
export type BaseSiteError = {
  errorType?: Maybe<Scalars['String']>;
};

/** An error representing a problem with a site's dataSourceLinks. */
export type DataSourceLinkError = BaseSiteError & {
  __typename?: 'DataSourceLinkError';
  errorType?: Maybe<Scalars['String']>;
};

/** An enumeration. */
export enum UserFeedbackStatus {
  Undefined = 'UNDEFINED',
  Satisfied = 'SATISFIED',
  Unsatisfied = 'UNSATISFIED',
  Dismissed = 'DISMISSED'
}

/** Details from the SalesPlatforms of a site. */
export type SalesPlatforms = {
  __typename?: 'SalesPlatforms';
  shopify?: Maybe<Scalars['Boolean']>;
  bigCommerce?: Maybe<Scalars['Boolean']>;
  amazon?: Maybe<Scalars['Boolean']>;
};

/** Input object for selecting a hub by id or alias. */
export type HubSelector = {
  id?: InputMaybe<Scalars['String']>;
  alias?: InputMaybe<Scalars['String']>;
};

/** The necessary data for displaying a session hub. */
export type SessionHub = {
  __typename?: 'SessionHub';
  definition?: Maybe<SessionHubDefinition>;
  current?: Maybe<Table>;
  previous?: Maybe<Table>;
};

/** Contains all of the metric and dimension definitions for a session hub. */
export type SessionHubDefinition = {
  __typename?: 'SessionHubDefinition';
  startDateDimension?: Maybe<DimensionDefinition>;
  endDateDimension?: Maybe<DimensionDefinition>;
  indexDimension?: Maybe<DimensionDefinition>;
  mainDimension?: Maybe<DimensionDefinition>;
  detailDimension?: Maybe<DimensionDefinition>;
  individualDimension?: Maybe<DimensionDefinition>;
  highlightedDetailValue?: Maybe<Scalars['String']>;
  primaryMetric?: Maybe<MetricDefinition>;
  individualMetric?: Maybe<MetricDefinition>;
  detailMetrics?: Maybe<Array<Maybe<MetricDefinition>>>;
};

/** Contains all of the information about a dimension. */
export type DimensionDefinition = {
  __typename?: 'DimensionDefinition';
  name?: Maybe<Scalars['String']>;
  commonName?: Maybe<Scalars['String']>;
  dataSource?: Maybe<DataSource>;
  classifiers?: Maybe<Array<Maybe<Scalars['String']>>>;
  incompatibleMetricGroups?: Maybe<Array<Maybe<Scalars['String']>>>;
  valueType?: Maybe<Scalars['String']>;
};

/** A table object containing a header, defining the columns, and list of rows. */
export type Table = {
  __typename?: 'Table';
  header?: Maybe<TableHeader>;
  data?: Maybe<TableData>;
  dataQuality?: Maybe<DataQualityInfo>;
};

/** Header for a table, contains metric and dimension definitions. */
export type TableHeader = {
  __typename?: 'TableHeader';
  metrics?: Maybe<Array<Maybe<MetricDefinition>>>;
  dimensions?: Maybe<Array<Maybe<DimensionDefinition>>>;
  attributes?: Maybe<Array<Maybe<AttributeDefinition>>>;
};

/** Contains all of the information about an attribute. */
export type AttributeDefinition = {
  __typename?: 'AttributeDefinition';
  name?: Maybe<Scalars['String']>;
  commonName?: Maybe<Scalars['String']>;
  valueType?: Maybe<Scalars['String']>;
};

/** The table data, effectively an exported DataFrame. */
export type TableData = {
  __typename?: 'TableData';
  jsonData?: Maybe<Scalars['String']>;
};

/** Contains info about data quality for a Table object. */
export type DataQualityInfo = {
  __typename?: 'DataQualityInfo';
  isGolden?: Maybe<Scalars['Boolean']>;
  isComplete?: Maybe<Scalars['Boolean']>;
  isSampled?: Maybe<Scalars['Boolean']>;
  worstSampleFraction?: Maybe<Scalars['Float']>;
};

/**
 * Selector for tree node by drill-down dimension along with optional
 * drill-down value to fix the node's dimension.
 */
export type TreeNodeSelector = {
  id?: InputMaybe<Scalars['String']>;
  alias?: InputMaybe<Scalars['String']>;
  drillDownDimension?: InputMaybe<Scalars['String']>;
  drillDownValue?: InputMaybe<Scalars['String']>;
};

/** Selector for a particular date period given an inclusive date range. */
export type PeriodSelector = {
  startDate: Scalars['String'];
  endDate: Scalars['String'];
};

/** The necessary data for displaying a tree hub. */
export type HubTree = {
  __typename?: 'HubTree';
  definition?: Maybe<HubTreeDefinition>;
  current?: Maybe<Table>;
  previous?: Maybe<Table>;
  availableNextTreeNodes?: Maybe<Array<Maybe<TreeNode>>>;
  treeId?: Maybe<Scalars['String']>;
  numWeeks?: Maybe<Scalars['Int']>;
  issue?: Maybe<Issue>;
  rawRequest?: Maybe<Scalars['String']>;
};

/** Contains all of the metric and dimension definitions for the returned data */
export type HubTreeDefinition = {
  __typename?: 'HubTreeDefinition';
  startDateDimension?: Maybe<DimensionDefinition>;
  endDateDimension?: Maybe<DimensionDefinition>;
  indexDimension?: Maybe<DimensionDefinition>;
  metrics?: Maybe<Array<Maybe<HubTreeMetricDefinition>>>;
  treeNodes?: Maybe<Array<Maybe<TreeNode>>>;
};

/**
 * Contains all of the information about a metric (datatype, formatting, etc.)
 * plus whether the metric is compatible with the query.
 */
export type HubTreeMetricDefinition = {
  __typename?: 'HubTreeMetricDefinition';
  name?: Maybe<Scalars['String']>;
  commonName?: Maybe<Scalars['String']>;
  dataSource?: Maybe<DataSource>;
  summable?: Maybe<Scalars['Boolean']>;
  formatting?: Maybe<MetricFormat>;
  formula?: Maybe<MetricFormula>;
  metricGroups?: Maybe<Array<Maybe<Scalars['String']>>>;
  briefDescription?: Maybe<Scalars['String']>;
  isCurrency?: Maybe<Scalars['Boolean']>;
  currencyCode?: Maybe<Scalars['String']>;
  isCompatible?: Maybe<Scalars['Boolean']>;
  category?: Maybe<Scalars['String']>;
  categories?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/**
 * Contains the information about a particular tree node plus whether it is
 * compatible with the requested metric.
 */
export type TreeNode = {
  __typename?: 'TreeNode';
  alias?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  isCompatible?: Maybe<Scalars['Boolean']>;
  drillDownDimension?: Maybe<Scalars['String']>;
  drillDownValue?: Maybe<Scalars['String']>;
};

/** Selector for specifying a join with additional attributes / dimensions. */
export type AttributeJoinSelector = {
  queryDimensions: Array<InputMaybe<Scalars['String']>>;
  queryAttributes?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  filterDimension?: InputMaybe<Scalars['String']>;
  staticFilterAttribute?: InputMaybe<Scalars['String']>;
  staticFilterValues?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  postFilterDimension?: InputMaybe<Scalars['String']>;
  primaryJoinDimensions?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  primaryFilterDimension?: InputMaybe<Scalars['String']>;
  primaryPostFilterDimension?: InputMaybe<Scalars['String']>;
  failOnRequestError?: InputMaybe<Scalars['Boolean']>;
};

/** Selector for joining additional metrics from a secondary timeseries query. */
export type MetricJoinSelector = {
  queryDimensions: Array<InputMaybe<Scalars['String']>>;
  queryMetrics?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  filterDimension?: InputMaybe<Scalars['String']>;
  primaryJoinDimensions?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  primaryFilterDimension?: InputMaybe<Scalars['String']>;
  failOnRequestError?: InputMaybe<Scalars['Boolean']>;
};

/** The necessary data for Search Query Explorer */
export type SearchQueryExplorer = {
  __typename?: 'SearchQueryExplorer';
  definition?: Maybe<SearchQueryExplorerDefinition>;
  table?: Maybe<Table>;
};

/** Contains all of the metric and dimension definitions for the returned data */
export type SearchQueryExplorerDefinition = {
  __typename?: 'SearchQueryExplorerDefinition';
  startDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
  dimensions?: Maybe<Array<Maybe<DimensionDefinition>>>;
  metrics?: Maybe<Array<Maybe<MetricDefinition>>>;
  attributes?: Maybe<Array<Maybe<AttributeDefinition>>>;
};

/** Represents the type of criteria. */
export enum CriteriaType {
  Keyword = 'KEYWORD',
  ListingGroup = 'LISTING_GROUP'
}

/** Represents how to select the criteria to return. */
export enum CriteriaSelectionType {
  SelectByRoasAndImpressionShare = 'SELECT_BY_ROAS_AND_IMPRESSION_SHARE',
  SelectTopScores = 'SELECT_TOP_SCORES',
  SelectBottomScores = 'SELECT_BOTTOM_SCORES',
  SelectByCriteriaIds = 'SELECT_BY_CRITERIA_IDS'
}

/** Contains definitions and table data. */
export type CpcBidAudit = {
  __typename?: 'CPCBidAudit';
  definition?: Maybe<CpcBidAuditDefinition>;
  table?: Maybe<Table>;
  comment?: Maybe<Scalars['String']>;
};

/** Contains all of the metric and dimension definitions for the returned data */
export type CpcBidAuditDefinition = {
  __typename?: 'CPCBidAuditDefinition';
  startDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
  dimensions?: Maybe<Array<Maybe<DimensionDefinition>>>;
  metrics?: Maybe<Array<Maybe<MetricDefinition>>>;
  attributes?: Maybe<Array<Maybe<AttributeDefinition>>>;
};

/** Represents the type of criteria. */
export enum AddKeywordSource {
  ShoppingSearchTerm = 'SHOPPING_SEARCH_TERM'
}

/** Contains definitions and table data. */
export type AddKeywordAudit = {
  __typename?: 'AddKeywordAudit';
  definition?: Maybe<AddKeywordAuditDefinition>;
  table?: Maybe<Table>;
  comment?: Maybe<Scalars['String']>;
};

/** Contains all of the metric and dimension definitions for the returned data */
export type AddKeywordAuditDefinition = {
  __typename?: 'AddKeywordAuditDefinition';
  startDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
  dimensions?: Maybe<Array<Maybe<DimensionDefinition>>>;
  metrics?: Maybe<Array<Maybe<MetricDefinition>>>;
  attributes?: Maybe<Array<Maybe<AttributeDefinition>>>;
};

/**
 * Contains dimension definition of all of the dimensions used in the query
 * and all of the id-to-name mappings.
 */
export type IdToNameMapping = {
  __typename?: 'IdToNameMapping';
  dimensions?: Maybe<Array<Maybe<DimensionDefinition>>>;
  idToName?: Maybe<Array<Maybe<IdToName>>>;
};

/**
 * Contains the mapping of an ID (possibly composed of more than one
 * dimension values) to a single name, where that name is the latest
 * of potentially many dimension values that correspond with the specified ID
 * in the single query over the specified date range.
 *
 * The type of the name will be determined by the value type of the
 * name_dimension.  The type of the id will be a array if there is more than
 * one id dimension, or it will be the type of the single id dimension if there
 * is only one.
 */
export type IdToName = {
  __typename?: 'IdToName';
  id?: Maybe<Scalars['GenericScalar']>;
  name?: Maybe<Scalars['GenericScalar']>;
};

/** Contains all information relating to customer health for a site. */
export type CustomerHealthForSite = {
  __typename?: 'CustomerHealthForSite';
  siteAlias?: Maybe<Scalars['String']>;
  hubs?: Maybe<CustomerHealthHubs>;
  issues?: Maybe<CustomerHealthIssues>;
  opportunities?: Maybe<CustomerHealthOpportunities>;
  googleAds?: Maybe<CustomerHealthGoogleAds>;
};

/** Contains hub information relating to customer health. */
export type CustomerHealthHubs = {
  __typename?: 'CustomerHealthHubs';
  siteAlias?: Maybe<Scalars['String']>;
  count?: Maybe<Scalars['Int']>;
};

/** Contains issue information relating to customer health. */
export type CustomerHealthIssues = {
  __typename?: 'CustomerHealthIssues';
  siteAlias?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Int']>;
};

/** Contains opportunity information relating to customer health. */
export type CustomerHealthOpportunities = {
  __typename?: 'CustomerHealthOpportunities';
  siteAlias?: Maybe<Scalars['String']>;
  opportunities?: Maybe<Array<Maybe<CustomerHealthOpportunitiesByDate>>>;
};

/** Contains information about opportunity interactions on a specific date. */
export type CustomerHealthOpportunitiesByDate = {
  __typename?: 'CustomerHealthOpportunitiesByDate';
  date?: Maybe<Date>;
  executed?: Maybe<Scalars['Int']>;
  failed?: Maybe<Scalars['Int']>;
};

/** Contains google ads information relating to customer health. */
export type CustomerHealthGoogleAds = {
  __typename?: 'CustomerHealthGoogleAds';
  hasGoogleAds?: Maybe<Scalars['Boolean']>;
  hasSearchCampaigns?: Maybe<Scalars['Boolean']>;
  hasShoppingCampaigns?: Maybe<Scalars['Boolean']>;
  unlinkedAccounts?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** Contains info relating to customer health for a list of sites. */
export type CustomerHealth = {
  __typename?: 'CustomerHealth';
  sites?: Maybe<Array<Maybe<CustomerHealthForSite>>>;
};

/** Contains info relating to group editor for a list of sites. */
export type GroupEditor = {
  __typename?: 'GroupEditor';
  sites?: Maybe<Array<Maybe<Site>>>;
  baseDimensions?: Maybe<Array<Maybe<DimensionDefinition>>>;
};


/** Contains info relating to group editor for a list of sites. */
export type GroupEditorBaseDimensionsArgs = {
  includeClassifiers?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** Graphene object to hold google search results */
export type GoogleSearch = {
  __typename?: 'GoogleSearch';
  term?: Maybe<Scalars['String']>;
  domainName?: Maybe<Scalars['String']>;
  problem?: Maybe<Scalars['String']>;
  googleResults?: Maybe<Scalars['GenericScalar']>;
};

/** Specification of a grouping group. */
export type InputSiteGroupingGroup = {
  name?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<Array<InputMaybe<InputSiteGroupingFilter>>>;
};

/** Specification of a dimension selector filter. */
export type InputSiteGroupingFilter = {
  dimension?: InputMaybe<Scalars['String']>;
  operator?: InputMaybe<FilterOperator>;
  values?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  orGroup?: InputMaybe<Scalars['Int']>;
  negated?: InputMaybe<Scalars['Boolean']>;
};

/** A Google Ads customer. */
export type GoogleAdsCustomer = {
  __typename?: 'GoogleAdsCustomer';
  resourceName?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  customerIdWithHyphens?: Maybe<Scalars['String']>;
  currencyCode?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  isLinkedToSite?: Maybe<Scalars['Boolean']>;
  managerName?: Maybe<Scalars['String']>;
  managerId?: Maybe<Scalars['String']>;
};

/** Status of campaign, ad group, or criteria */
export enum Status {
  Unknown = 'UNKNOWN',
  Enabled = 'ENABLED',
  Paused = 'PAUSED',
  Removed = 'REMOVED'
}

/** Contains information about adwords objects for a site. */
export type AdwordsObjects = {
  __typename?: 'AdwordsObjects';
  queryId?: Maybe<Scalars['String']>;
  siteAlias?: Maybe<Scalars['String']>;
  campaigns?: Maybe<Array<Maybe<Campaign>>>;
  adGroups?: Maybe<Array<Maybe<AdGroup>>>;
  budgets?: Maybe<Array<Maybe<Budget>>>;
  labels?: Maybe<Array<Maybe<Label>>>;
};


/** Contains information about adwords objects for a site. */
export type AdwordsObjectsCampaignsArgs = {
  maxAdGroupCount?: InputMaybe<Scalars['Int']>;
};

/** Basic identity and status information about an Adwords campaign. */
export type Campaign = {
  __typename?: 'Campaign';
  customerId?: Maybe<Scalars['String']>;
  campaignId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<Status>;
  advertisingChannelType?: Maybe<Scalars['String']>;
  advertisingChannelSubType?: Maybe<Scalars['String']>;
  biddingStrategy?: Maybe<Scalars['String']>;
  maxCpcBid?: Maybe<Scalars['Float']>;
  campaignStartDate?: Maybe<Scalars['String']>;
  labelIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  budgetId?: Maybe<Scalars['String']>;
  adGroupCount?: Maybe<Scalars['Int']>;
  adGroupTypes?: Maybe<Array<Maybe<Scalars['String']>>>;
  adGroups?: Maybe<Array<Maybe<AdGroup>>>;
  campaignNegativeKeywords?: Maybe<Array<Maybe<CampaignNegativeKeyword>>>;
};

/** Basic identity and status information about an Adwords ad group. */
export type AdGroup = {
  __typename?: 'AdGroup';
  adGroupId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<Status>;
  criteria?: Maybe<Array<Maybe<Criteria>>>;
  keywords?: Maybe<Array<Maybe<Keyword>>>;
  defaultCpcBid?: Maybe<Scalars['Float']>;
  defaultCpcBidIsAuto?: Maybe<Scalars['Boolean']>;
  effectiveDefaultCpcBid?: Maybe<Scalars['Float']>;
  adGroupType?: Maybe<Scalars['String']>;
  labelIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  positiveKeywords?: Maybe<Array<Maybe<Keyword>>>;
  negativeKeywords?: Maybe<Array<Maybe<Keyword>>>;
  productGroups?: Maybe<Array<Maybe<ProductGroup>>>;
  searchTerms?: Maybe<Array<Maybe<SearchTerm>>>;
};

/** Basic identity and status information about an Adwords criteria object. */
export type Criteria = {
  __typename?: 'Criteria';
  adGroupId?: Maybe<Scalars['String']>;
  criteriaId?: Maybe<Scalars['String']>;
  criteriaType?: Maybe<Scalars['String']>;
  criteriaText?: Maybe<Scalars['String']>;
  isNegative?: Maybe<Scalars['Boolean']>;
  status?: Maybe<Status>;
  cpcBid?: Maybe<Scalars['Float']>;
  cpcBidIsAuto?: Maybe<Scalars['Boolean']>;
  effectiveCpcBid?: Maybe<Scalars['Float']>;
  labelIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** Basic identity and status information about an Adwords keyword criteria object. */
export type Keyword = {
  __typename?: 'Keyword';
  adGroupId?: Maybe<Scalars['String']>;
  criteriaId?: Maybe<Scalars['String']>;
  criteriaType?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  criteriaText?: Maybe<Scalars['String']>;
  matchType?: Maybe<Scalars['String']>;
  isNegative?: Maybe<Scalars['Boolean']>;
  status?: Maybe<Status>;
  cpcBid?: Maybe<Scalars['Float']>;
  cpcBidIsAuto?: Maybe<Scalars['Boolean']>;
  effectiveCpcBid?: Maybe<Scalars['Float']>;
  labelIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** Basic identity and status information about an Adwords product group criteria object. */
export type ProductGroup = {
  __typename?: 'ProductGroup';
  adGroupId?: Maybe<Scalars['String']>;
  criteriaId?: Maybe<Scalars['String']>;
  criteriaType?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  partitionType?: Maybe<Scalars['String']>;
  isNegative?: Maybe<Scalars['Boolean']>;
  cpcBid?: Maybe<Scalars['Float']>;
  cpcBidIsAuto?: Maybe<Scalars['Boolean']>;
  effectiveCpcBid?: Maybe<Scalars['Float']>;
  parentCriteriaId?: Maybe<Scalars['String']>;
};

/** Basic identity and status information about an Adwords search term object. */
export type SearchTerm = {
  __typename?: 'SearchTerm';
  adGroupId?: Maybe<Scalars['String']>;
  queryText?: Maybe<Scalars['String']>;
  queryTargetingStatus?: Maybe<Scalars['String']>;
  queryMatchType?: Maybe<Scalars['String']>;
  criteriaId?: Maybe<Scalars['String']>;
  matchingCriteriaText?: Maybe<Scalars['String']>;
};

/** Basic identity and status information about an Adwords campaign negative keyword object. */
export type CampaignNegativeKeyword = {
  __typename?: 'CampaignNegativeKeyword';
  text?: Maybe<Scalars['String']>;
  matchType?: Maybe<Scalars['String']>;
};

/** Basic identity and status information about an Adwords budget object. */
export type Budget = {
  __typename?: 'Budget';
  customerId?: Maybe<Scalars['String']>;
  budgetId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<Status>;
  campaignCount?: Maybe<Scalars['Int']>;
  budgetAmount?: Maybe<Scalars['Float']>;
  recommendedBudgetAmount?: Maybe<Scalars['Float']>;
};

/** Basic identity information about an Adwords label object. */
export type Label = {
  __typename?: 'Label';
  customerId?: Maybe<Scalars['String']>;
  labelId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** Type of Adwords object to collect information for. */
export enum AdwordsObjectType {
  Auto = 'AUTO',
  Campaign = 'CAMPAIGN',
  AdGroup = 'AD_GROUP',
  Criteria = 'CRITERIA',
  SearchTerm = 'SEARCH_TERM'
}

/** Contains information about adwords metrics for a site. */
export type AdwordsMetrics = {
  __typename?: 'AdwordsMetrics';
  queryId?: Maybe<Scalars['String']>;
  siteAlias?: Maybe<Scalars['String']>;
  basicMetrics?: Maybe<Array<Maybe<BasicMetrics>>>;
  statisticalMetrics?: Maybe<Array<Maybe<StatisticalMetrics>>>;
  ampdPerformanceMetrics?: Maybe<Array<Maybe<AmpdPerformanceMetrics>>>;
};

/**
 * Contains information about adwords metrics for a particular campaign,
 * ad group, keyword, product group, or search term.
 */
export type BasicMetrics = {
  __typename?: 'BasicMetrics';
  objectType?: Maybe<AdwordsObjectType>;
  date?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  campaignId?: Maybe<Scalars['String']>;
  adGroupId?: Maybe<Scalars['String']>;
  criteriaId?: Maybe<Scalars['String']>;
  queryText?: Maybe<Scalars['String']>;
  costCurrencyCode?: Maybe<Scalars['String']>;
  revenueCurrencyCode?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Float']>;
  impressions?: Maybe<Scalars['Float']>;
  clicks?: Maybe<Scalars['Float']>;
  conversions?: Maybe<Scalars['Float']>;
  revenue?: Maybe<Scalars['Float']>;
  averageSessionDuration?: Maybe<Scalars['Float']>;
  impressionShare?: Maybe<Scalars['Float']>;
  lostImpressionShareLowRank?: Maybe<Scalars['Float']>;
  lostImpressionShareLowBudget?: Maybe<Scalars['Float']>;
  clickShare?: Maybe<Scalars['Float']>;
  firstPageCpc?: Maybe<Scalars['Float']>;
  firstPositionCpc?: Maybe<Scalars['Float']>;
  topOfPageCpc?: Maybe<Scalars['Float']>;
  attributedClicks?: Maybe<Scalars['Float']>;
  detailPageViewClicks?: Maybe<Scalars['Float']>;
  addToCartClicks?: Maybe<Scalars['Float']>;
  unitsSold?: Maybe<Scalars['Float']>;
  newToBrandConversions?: Maybe<Scalars['Float']>;
  newToBrandRevenue?: Maybe<Scalars['Float']>;
  newToBrandUnitsSold?: Maybe<Scalars['Float']>;
  maxDataWithheldPercent?: Maybe<Scalars['Float']>;
  brandReferralBonus?: Maybe<Scalars['Float']>;
  adjustedCost?: Maybe<Scalars['Float']>;
  parentAsinRevenue?: Maybe<Scalars['Float']>;
  purchaseFrequency?: Maybe<Scalars['Float']>;
  annualizedRevenue?: Maybe<Scalars['Float']>;
  parentAsinAmazonAdsCost?: Maybe<Scalars['Float']>;
  parentAsinAdjustedAdsCost?: Maybe<Scalars['Float']>;
  unconvertedRevenueCurrencyCode?: Maybe<Scalars['String']>;
  unconvertedRevenue?: Maybe<Scalars['Float']>;
  unconvertedBrandReferralBonus?: Maybe<Scalars['Float']>;
  includesAmazonAttributionData?: Maybe<Scalars['Boolean']>;
};

/**
 * Contains information about adwords metrics for a particular campaign,
 * ad group, or search term.
 */
export type StatisticalMetrics = {
  __typename?: 'StatisticalMetrics';
  objectType?: Maybe<AdwordsObjectType>;
  campaignId?: Maybe<Scalars['String']>;
  adGroupId?: Maybe<Scalars['String']>;
  criteriaId?: Maybe<Scalars['String']>;
  queryText?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Float']>;
  clicks?: Maybe<Scalars['Float']>;
  estimatedAverageOrderValue?: Maybe<Scalars['Float']>;
  expectedConversionRate?: Maybe<Scalars['Float']>;
  expectedRoasValue?: Maybe<Scalars['Float']>;
  expectedRevenueDiff?: Maybe<Scalars['Float']>;
  underperformingProbability?: Maybe<Scalars['Float']>;
  rawScore?: Maybe<Scalars['Float']>;
  score?: Maybe<Scalars['Float']>;
  revenueDiffScore?: Maybe<Scalars['Float']>;
};

/** Contains Ampd performance metrics for a particular keyword. */
export type AmpdPerformanceMetrics = {
  __typename?: 'AmpdPerformanceMetrics';
  objectType?: Maybe<AdwordsObjectType>;
  campaignId?: Maybe<Scalars['String']>;
  adGroupId?: Maybe<Scalars['String']>;
  criteriaId?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Float']>;
  clicks?: Maybe<Scalars['Float']>;
  revenue?: Maybe<Scalars['Float']>;
  estimatedAverageOrderValue?: Maybe<Scalars['Float']>;
  revenueImpactScore?: Maybe<Scalars['Float']>;
  belowConfidence?: Maybe<Scalars['Float']>;
  aboveConfidence?: Maybe<Scalars['Float']>;
  percentile16?: Maybe<Scalars['Float']>;
  percentile84?: Maybe<Scalars['Float']>;
  performanceScore?: Maybe<Scalars['Float']>;
};

/**
 * Input object for specifying the phrases (optionally weighted) that
 * belong to the named category.
 */
export type InputCategory = {
  name: Scalars['String'];
  phrases: Array<InputMaybe<Scalars['String']>>;
  weights?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
};

/** Graphene object to fuzzy phrase suggestions. */
export type FuzzyPhraseClassifier = {
  __typename?: 'FuzzyPhraseClassifier';
  categorySuggestions?: Maybe<Array<Maybe<FuzzyCategorySuggestion>>>;
  remainingPhrases?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/**
 * List of suggestions about which category should include new phrase based
 * on the current phrases in the various categories.
 */
export type FuzzyCategorySuggestion = {
  __typename?: 'FuzzyCategorySuggestion';
  phrase?: Maybe<Scalars['String']>;
  categories?: Maybe<Array<Maybe<Scalars['String']>>>;
  scores?: Maybe<Array<Maybe<Scalars['Float']>>>;
};

/** Represents the type of report to generate. */
export enum ReportType {
  Excel = 'EXCEL',
  UnformattedCampaignMetricsCsv = 'UNFORMATTED_CAMPAIGN_METRICS_CSV',
  UnformattedKeywordMetricsCsv = 'UNFORMATTED_KEYWORD_METRICS_CSV',
  SummaryCsv = 'SUMMARY_CSV',
  SummaryPdf = 'SUMMARY_PDF',
  CampaignImpressionsShareCsv = 'CAMPAIGN_IMPRESSIONS_SHARE_CSV',
  KeywordImpressionsShareCsv = 'KEYWORD_IMPRESSIONS_SHARE_CSV'
}

/** Input object for specifying the report data. */
export type ReportData = {
  reportTitle?: InputMaybe<Scalars['String']>;
  reportFilename?: InputMaybe<Scalars['String']>;
  startDates?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  endDates?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  dateRangeNames?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  campaignMetricInfos?: InputMaybe<Array<InputMaybe<ReportMetricInfo>>>;
  keywordMetricInfos?: InputMaybe<Array<InputMaybe<ReportMetricInfo>>>;
  totalDataList: Array<InputMaybe<ReportTotalData>>;
};

/** Input object for specifying the column attributes */
export type ReportMetricInfo = {
  name: Scalars['String'];
  title: Scalars['String'];
  excelFormat?: InputMaybe<Scalars['String']>;
  useRevenueCurrency?: InputMaybe<Scalars['Boolean']>;
  checked?: InputMaybe<Scalars['Boolean']>;
};

/** Input information about the totals for a single currency. */
export type ReportTotalData = {
  totalTitle: Scalars['String'];
  costCurrencyCode: Scalars['String'];
  revenueCurrencyCode: Scalars['String'];
  totalMetricsList?: InputMaybe<Array<InputMaybe<ReportMetrics>>>;
  siteDataList: Array<InputMaybe<ReportSiteData>>;
};

/** Input information about metric values */
export type ReportMetrics = {
  forRangeFormatted?: InputMaybe<Scalars['GenericScalar']>;
  forRangeNumeric?: InputMaybe<Scalars['GenericScalar']>;
  byDayFormatted?: InputMaybe<Scalars['GenericScalar']>;
  byDayNumeric?: InputMaybe<Scalars['GenericScalar']>;
};

/** Input information about a single site and its campaigns. */
export type ReportSiteData = {
  siteName: Scalars['String'];
  siteAlias: Scalars['String'];
  siteMetricsList?: InputMaybe<Array<InputMaybe<ReportMetrics>>>;
  campaignDataList?: InputMaybe<Array<InputMaybe<ReportCampaignData>>>;
};

/** Input information about a single campaign and its keywords. */
export type ReportCampaignData = {
  customerId: Scalars['String'];
  campaignId: Scalars['String'];
  campaignName: Scalars['String'];
  campaignStatus: Scalars['String'];
  asin?: InputMaybe<Scalars['String']>;
  campaignMetricsList?: InputMaybe<Array<InputMaybe<ReportMetrics>>>;
  keywordDataList?: InputMaybe<Array<InputMaybe<ReportKeywordData>>>;
};

/** Input information about a single keyword. */
export type ReportKeywordData = {
  adGroupId: Scalars['String'];
  criteriaId: Scalars['String'];
  keywordText: Scalars['String'];
  keywordStatus: Scalars['String'];
  keywordMetricsList?: InputMaybe<Array<InputMaybe<ReportMetrics>>>;
};

/** Return information about the generated report. */
export type AmpdReport = {
  __typename?: 'AmpdReport';
  exports?: Maybe<Array<Maybe<AmpdReportExport>>>;
};

/** Return information about a single exported file. */
export type AmpdReportExport = {
  __typename?: 'AmpdReportExport';
  filename?: Maybe<Scalars['String']>;
  mimeType?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
};

/** A list of opportunities */
export type Opportunities = {
  __typename?: 'Opportunities';
  opportunities?: Maybe<Array<Maybe<Opportunity>>>;
  completedOpportunities?: Maybe<Array<Maybe<Opportunity>>>;
  failedOpportunities?: Maybe<Array<Maybe<Opportunity>>>;
  dismissedOpportunities?: Maybe<Array<Maybe<Opportunity>>>;
  initiallyGenerated?: Maybe<Scalars['String']>;
  opportunityTypeMessages?: Maybe<Array<Maybe<OpportunityTypeMessage>>>;
};

/** Union type for all concrete Opportunity graphql objects. */
export type Opportunity = IncreaseKeywordCpcBidOpportunity | IncreaseProductGroupCpcBidOpportunity | AddKeywordFromShoppingSearchTermOpportunity | AddKeywordFromOrganicSearchTermOpportunity | AddDynamicSearchForSitemapFolderOpportunity | NegateInefficientSearchTermOpportunity;

/** Contains the fields IncreaseKeywordCpcBid Opportunity objects. */
export type IncreaseKeywordCpcBidOpportunity = BaseOpportunity & AdwordsOpportunity & {
  __typename?: 'IncreaseKeywordCpcBidOpportunity';
  siteId?: Maybe<Scalars['String']>;
  opportunityId?: Maybe<Scalars['String']>;
  alternativeIndex?: Maybe<Scalars['Int']>;
  opportunityType?: Maybe<Scalars['String']>;
  priorityWithinType?: Maybe<Scalars['Int']>;
  hasFailures?: Maybe<Scalars['Boolean']>;
  fingerprint?: Maybe<Scalars['String']>;
  isSample?: Maybe<Scalars['Boolean']>;
  isTeaser?: Maybe<Scalars['Boolean']>;
  customerId?: Maybe<Scalars['String']>;
  campaignId?: Maybe<Scalars['String']>;
  campaignName?: Maybe<Scalars['String']>;
  adGroupId?: Maybe<Scalars['String']>;
  adGroupName?: Maybe<Scalars['String']>;
  keywordText?: Maybe<Scalars['String']>;
  averageCpc?: Maybe<Scalars['String']>;
  currentCpcBid?: Maybe<Scalars['String']>;
  newCpcBid?: Maybe<Scalars['String']>;
  newCpcBidMicros?: Maybe<Scalars['String']>;
  cpcBidReason?: Maybe<Scalars['String']>;
  topImpressionShare?: Maybe<Scalars['String']>;
  roas?: Maybe<Scalars['String']>;
  conversionValue?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['String']>;
  conversions?: Maybe<Scalars['Float']>;
  clicks?: Maybe<Scalars['Float']>;
  impressions?: Maybe<Scalars['Float']>;
  startDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
  alternatives?: Maybe<Array<Maybe<IncreaseKeywordCpcBidAlternative>>>;
};

/** Contains the base fields for all Opportunity objects. */
export type BaseOpportunity = {
  siteId?: Maybe<Scalars['String']>;
  opportunityId?: Maybe<Scalars['String']>;
  alternativeIndex?: Maybe<Scalars['Int']>;
  opportunityType?: Maybe<Scalars['String']>;
  priorityWithinType?: Maybe<Scalars['Int']>;
  hasFailures?: Maybe<Scalars['Boolean']>;
  fingerprint?: Maybe<Scalars['String']>;
  isSample?: Maybe<Scalars['Boolean']>;
  isTeaser?: Maybe<Scalars['Boolean']>;
};

/** Contains the fields for all AdWords Opportunity objects. */
export type AdwordsOpportunity = {
  customerId?: Maybe<Scalars['String']>;
  campaignId?: Maybe<Scalars['String']>;
  campaignName?: Maybe<Scalars['String']>;
  adGroupId?: Maybe<Scalars['String']>;
  adGroupName?: Maybe<Scalars['String']>;
};

/**
 * Contains the fields that can vary for an IncreaseKeywordCpcBid alternative
 * opportunity.
 */
export type IncreaseKeywordCpcBidAlternative = {
  __typename?: 'IncreaseKeywordCpcBidAlternative';
  alternativeIndex?: Maybe<Scalars['Int']>;
  newCpcBid?: Maybe<Scalars['String']>;
  newCpcBidMicros?: Maybe<Scalars['String']>;
  cpcBidReason?: Maybe<Scalars['String']>;
};

/** Contains the fields IncreaseProductGroupCpcBid Opportunity objects. */
export type IncreaseProductGroupCpcBidOpportunity = BaseOpportunity & AdwordsOpportunity & {
  __typename?: 'IncreaseProductGroupCpcBidOpportunity';
  siteId?: Maybe<Scalars['String']>;
  opportunityId?: Maybe<Scalars['String']>;
  alternativeIndex?: Maybe<Scalars['Int']>;
  opportunityType?: Maybe<Scalars['String']>;
  priorityWithinType?: Maybe<Scalars['Int']>;
  hasFailures?: Maybe<Scalars['Boolean']>;
  fingerprint?: Maybe<Scalars['String']>;
  isSample?: Maybe<Scalars['Boolean']>;
  isTeaser?: Maybe<Scalars['Boolean']>;
  customerId?: Maybe<Scalars['String']>;
  campaignId?: Maybe<Scalars['String']>;
  campaignName?: Maybe<Scalars['String']>;
  adGroupId?: Maybe<Scalars['String']>;
  adGroupName?: Maybe<Scalars['String']>;
  productGroup?: Maybe<Scalars['String']>;
  averageCpc?: Maybe<Scalars['String']>;
  currentCpcBid?: Maybe<Scalars['String']>;
  newCpcBid?: Maybe<Scalars['String']>;
  newCpcBidMicros?: Maybe<Scalars['String']>;
  cpcBidReason?: Maybe<Scalars['String']>;
  impressionShare?: Maybe<Scalars['String']>;
  roas?: Maybe<Scalars['String']>;
  conversionValue?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['String']>;
  conversions?: Maybe<Scalars['Float']>;
  clicks?: Maybe<Scalars['Float']>;
  impressions?: Maybe<Scalars['Float']>;
  startDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
  alternatives?: Maybe<Array<Maybe<IncreaseKeywordCpcBidAlternative>>>;
};

/** Contains the fields AddKeywordFromShoppingSearchTerm Opportunity objects. */
export type AddKeywordFromShoppingSearchTermOpportunity = BaseOpportunity & AdwordsOpportunity & {
  __typename?: 'AddKeywordFromShoppingSearchTermOpportunity';
  siteId?: Maybe<Scalars['String']>;
  opportunityId?: Maybe<Scalars['String']>;
  alternativeIndex?: Maybe<Scalars['Int']>;
  opportunityType?: Maybe<Scalars['String']>;
  priorityWithinType?: Maybe<Scalars['Int']>;
  hasFailures?: Maybe<Scalars['Boolean']>;
  fingerprint?: Maybe<Scalars['String']>;
  isSample?: Maybe<Scalars['Boolean']>;
  isTeaser?: Maybe<Scalars['Boolean']>;
  customerId?: Maybe<Scalars['String']>;
  campaignId?: Maybe<Scalars['String']>;
  campaignName?: Maybe<Scalars['String']>;
  adGroupId?: Maybe<Scalars['String']>;
  adGroupName?: Maybe<Scalars['String']>;
  defaultCpcBid?: Maybe<Scalars['String']>;
  newCpcBid?: Maybe<Scalars['String']>;
  newCpcBidMicros?: Maybe<Scalars['String']>;
  cpcBidReason?: Maybe<Scalars['String']>;
  newKeywordText?: Maybe<Scalars['String']>;
  averageCpc?: Maybe<Scalars['String']>;
  roas?: Maybe<Scalars['String']>;
  conversionValue?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['String']>;
  conversions?: Maybe<Scalars['Float']>;
  clicks?: Maybe<Scalars['Float']>;
  impressions?: Maybe<Scalars['Float']>;
  startDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
  alternatives?: Maybe<Array<Maybe<AddKeywordFromShoppingSearchTermAlternative>>>;
};

/**
 * Contains the fields that can vary for an AddKeywordFromShoppingSearchTerm
 * alternative opportunity.
 */
export type AddKeywordFromShoppingSearchTermAlternative = {
  __typename?: 'AddKeywordFromShoppingSearchTermAlternative';
  alternativeIndex?: Maybe<Scalars['Int']>;
  campaignId?: Maybe<Scalars['String']>;
  campaignName?: Maybe<Scalars['String']>;
  adGroupId?: Maybe<Scalars['String']>;
  adGroupName?: Maybe<Scalars['String']>;
  defaultCpcBid?: Maybe<Scalars['String']>;
  newCpcBid?: Maybe<Scalars['String']>;
  newCpcBidMicros?: Maybe<Scalars['String']>;
  cpcBidReason?: Maybe<Scalars['String']>;
};

/** Contains the fields AddKeywordFromOrganicSearchTerm Opportunity objects. */
export type AddKeywordFromOrganicSearchTermOpportunity = BaseOpportunity & AdwordsOpportunity & {
  __typename?: 'AddKeywordFromOrganicSearchTermOpportunity';
  siteId?: Maybe<Scalars['String']>;
  opportunityId?: Maybe<Scalars['String']>;
  alternativeIndex?: Maybe<Scalars['Int']>;
  opportunityType?: Maybe<Scalars['String']>;
  priorityWithinType?: Maybe<Scalars['Int']>;
  hasFailures?: Maybe<Scalars['Boolean']>;
  fingerprint?: Maybe<Scalars['String']>;
  isSample?: Maybe<Scalars['Boolean']>;
  isTeaser?: Maybe<Scalars['Boolean']>;
  customerId?: Maybe<Scalars['String']>;
  campaignId?: Maybe<Scalars['String']>;
  campaignName?: Maybe<Scalars['String']>;
  adGroupId?: Maybe<Scalars['String']>;
  adGroupName?: Maybe<Scalars['String']>;
  defaultCpcBid?: Maybe<Scalars['String']>;
  newCpcBid?: Maybe<Scalars['String']>;
  newCpcBidMicros?: Maybe<Scalars['String']>;
  cpcBidReason?: Maybe<Scalars['String']>;
  newKeywordText?: Maybe<Scalars['String']>;
  averageCpc?: Maybe<Scalars['String']>;
  searchVolume?: Maybe<Scalars['Int']>;
  competition?: Maybe<Scalars['Float']>;
  alternatives?: Maybe<Array<Maybe<AddKeywordFromOrganicSearchTermAlternative>>>;
};

/**
 * Contains the fields that can vary for an AddKeywordFromOrganicSearchTerm
 * alternative opportunity.
 */
export type AddKeywordFromOrganicSearchTermAlternative = {
  __typename?: 'AddKeywordFromOrganicSearchTermAlternative';
  alternativeIndex?: Maybe<Scalars['Int']>;
  campaignId?: Maybe<Scalars['String']>;
  campaignName?: Maybe<Scalars['String']>;
  adGroupId?: Maybe<Scalars['String']>;
  adGroupName?: Maybe<Scalars['String']>;
  defaultCpcBid?: Maybe<Scalars['String']>;
  newCpcBid?: Maybe<Scalars['String']>;
  newCpcBidMicros?: Maybe<Scalars['String']>;
  cpcBidReason?: Maybe<Scalars['String']>;
};

/** Contains the fields AddDynamicSearchForSitemapFolder Opportunity objects. */
export type AddDynamicSearchForSitemapFolderOpportunity = BaseOpportunity & AdwordsOpportunity & {
  __typename?: 'AddDynamicSearchForSitemapFolderOpportunity';
  siteId?: Maybe<Scalars['String']>;
  opportunityId?: Maybe<Scalars['String']>;
  alternativeIndex?: Maybe<Scalars['Int']>;
  opportunityType?: Maybe<Scalars['String']>;
  priorityWithinType?: Maybe<Scalars['Int']>;
  hasFailures?: Maybe<Scalars['Boolean']>;
  fingerprint?: Maybe<Scalars['String']>;
  isSample?: Maybe<Scalars['Boolean']>;
  isTeaser?: Maybe<Scalars['Boolean']>;
  customerId?: Maybe<Scalars['String']>;
  campaignId?: Maybe<Scalars['String']>;
  campaignName?: Maybe<Scalars['String']>;
  adGroupId?: Maybe<Scalars['String']>;
  adGroupName?: Maybe<Scalars['String']>;
  folderUrl?: Maybe<Scalars['String']>;
  folderDomain?: Maybe<Scalars['String']>;
  folderPath?: Maybe<Scalars['String']>;
  folderPageCount?: Maybe<Scalars['Int']>;
  untargetedPageCount?: Maybe<Scalars['Int']>;
  dynamicSearchTargetedPageCount?: Maybe<Scalars['Int']>;
  standardSearchTargetedPageCount?: Maybe<Scalars['Int']>;
  otherTargetedPageCount?: Maybe<Scalars['Int']>;
  newCampaignName?: Maybe<Scalars['String']>;
  newAdGroupName?: Maybe<Scalars['String']>;
  campaignBudget?: Maybe<Scalars['String']>;
  campaignBudgetMicros?: Maybe<Scalars['String']>;
  biddingStrategyDesc?: Maybe<Scalars['String']>;
  biddingStrategy?: Maybe<Scalars['String']>;
  defaultCpcBidMicros?: Maybe<Scalars['String']>;
  languageName?: Maybe<Scalars['String']>;
  adDescriptionLine1?: Maybe<Scalars['String']>;
  adDescriptionLine2?: Maybe<Scalars['String']>;
  alternatives?: Maybe<Array<Maybe<AddDynamicSearchForSitemapFolderAlternative>>>;
};

/**
 * Contains the fields that can vary for an AddDynamicSearchForSitemapFolder
 * alternative opportunity.
 */
export type AddDynamicSearchForSitemapFolderAlternative = {
  __typename?: 'AddDynamicSearchForSitemapFolderAlternative';
  alternativeIndex?: Maybe<Scalars['Int']>;
  campaignBudget?: Maybe<Scalars['String']>;
  campaignBudgetMicros?: Maybe<Scalars['String']>;
  biddingStrategyDesc?: Maybe<Scalars['String']>;
  biddingStrategy?: Maybe<Scalars['String']>;
  defaultCpcBidMicros?: Maybe<Scalars['String']>;
};

/** Contains the fields for a NegateInefficientSearchTermOpportunity. */
export type NegateInefficientSearchTermOpportunity = BaseOpportunity & AdwordsOpportunity & {
  __typename?: 'NegateInefficientSearchTermOpportunity';
  siteId?: Maybe<Scalars['String']>;
  opportunityId?: Maybe<Scalars['String']>;
  alternativeIndex?: Maybe<Scalars['Int']>;
  opportunityType?: Maybe<Scalars['String']>;
  priorityWithinType?: Maybe<Scalars['Int']>;
  hasFailures?: Maybe<Scalars['Boolean']>;
  fingerprint?: Maybe<Scalars['String']>;
  isSample?: Maybe<Scalars['Boolean']>;
  isTeaser?: Maybe<Scalars['Boolean']>;
  customerId?: Maybe<Scalars['String']>;
  campaignId?: Maybe<Scalars['String']>;
  campaignName?: Maybe<Scalars['String']>;
  adGroupId?: Maybe<Scalars['String']>;
  adGroupName?: Maybe<Scalars['String']>;
  criteriaText?: Maybe<Scalars['String']>;
  estimatedAov?: Maybe<Scalars['Float']>;
  expectedConversionRate?: Maybe<Scalars['Float']>;
  expectedRoas?: Maybe<Scalars['Float']>;
  revenueDiffScore?: Maybe<Scalars['Float']>;
  roasUnderperformingProb?: Maybe<Scalars['Float']>;
  clicks?: Maybe<Scalars['Float']>;
  conversions?: Maybe<Scalars['Float']>;
  cost?: Maybe<Scalars['String']>;
  costPerClick?: Maybe<Scalars['Float']>;
  revenue?: Maybe<Scalars['Float']>;
  roas?: Maybe<Scalars['String']>;
  campaignEstimatedAov?: Maybe<Scalars['Float']>;
  campaignExpectedConversionRate?: Maybe<Scalars['Float']>;
  campaignExpectedRoas?: Maybe<Scalars['Float']>;
  campaignClicks?: Maybe<Scalars['Float']>;
  campaignCost?: Maybe<Scalars['Float']>;
  campaignCostPerClick?: Maybe<Scalars['Float']>;
};

/**
 * A message to be shown for a specific type of opportunity, generally when
 * the list is empty (unless always_show is True).
 */
export type OpportunityTypeMessage = {
  __typename?: 'OpportunityTypeMessage';
  opportunityType?: Maybe<Scalars['String']>;
  alwaysShow?: Maybe<Scalars['Boolean']>;
  isWarning?: Maybe<Scalars['Boolean']>;
  message?: Maybe<Scalars['String']>;
  videoSource?: Maybe<Scalars['String']>;
  videoId?: Maybe<Scalars['String']>;
  videoCaption?: Maybe<Scalars['String']>;
  infoLink?: Maybe<Scalars['String']>;
  infoLinkLabel?: Maybe<Scalars['String']>;
};

/**
 * Help about working with Keyword Genius, in particular a json object
 * containing the frequently-asked-question data.
 */
export type OpportunityHelp = {
  __typename?: 'OpportunityHelp';
  faq?: Maybe<Scalars['GenericScalar']>;
};

/**
 * Contains weekly and year-to-date statistics for executed opportunities,
 * the dates over which the metrics were calculated, and the counts of the
 * various opportunity types.
 */
export type OpportunityStats = {
  __typename?: 'OpportunityStats';
  weeklyEffects?: Maybe<OpportunityStatValues>;
  ytdEffects?: Maybe<OpportunityStatValues>;
  hasConversions?: Maybe<Scalars['Boolean']>;
  hasRevenue?: Maybe<Scalars['Boolean']>;
  old?: Maybe<OpportunityStatValues>;
  new?: Maybe<OpportunityStatValues>;
  baselineOld?: Maybe<OpportunityStatValues>;
  baselineNew?: Maybe<OpportunityStatValues>;
  endDate?: Maybe<Scalars['String']>;
  weekStart?: Maybe<Scalars['String']>;
  yearStart?: Maybe<Scalars['String']>;
};

/** Contains the value of a set of common metrics for reporting. */
export type OpportunityStatValues = {
  __typename?: 'OpportunityStatValues';
  clicks?: Maybe<Scalars['Float']>;
  cost?: Maybe<Scalars['Float']>;
  conversions?: Maybe<Scalars['Float']>;
  revenue?: Maybe<Scalars['Float']>;
};

/** Details about a subscription plan. */
export type SubscriptionPlan = {
  __typename?: 'SubscriptionPlan';
  monthlyPlanId?: Maybe<Scalars['String']>;
  costPerMonthInCents?: Maybe<Scalars['Int']>;
};

/** Graphene object to hold sitemap information. */
export type SitemapInfo = {
  __typename?: 'SitemapInfo';
  homePages?: Maybe<Array<Maybe<Scalars['String']>>>;
  targetedHomePages?: Maybe<Array<Maybe<Scalars['String']>>>;
  folders?: Maybe<Array<Maybe<SitemapFolder>>>;
  pages?: Maybe<Array<Maybe<SitemapPage>>>;
};

/** Graphene object to represent a single sitemap folder. */
export type SitemapFolder = {
  __typename?: 'SitemapFolder';
  index?: Maybe<Scalars['Int']>;
  parent?: Maybe<Scalars['Int']>;
  path?: Maybe<Scalars['String']>;
  otherPages?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** Graphene object to represent a single sitemap page. */
export type SitemapPage = {
  __typename?: 'SitemapPage';
  parent?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  adGroupIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/**
 * Graphene object for returning the bookmarks of a feature as identified by
 * the feature_category.
 */
export type UserBookmarks = {
  __typename?: 'UserBookmarks';
  featureCategory?: Maybe<Scalars['String']>;
  bookmarks: Array<Maybe<UserBookmark>>;
};

/** Graphene object for returning a bookmark. */
export type UserBookmark = {
  __typename?: 'UserBookmark';
  name: Scalars['String'];
  parameters?: Maybe<Array<Maybe<UserBookmarkParameter>>>;
  featureCategory?: Maybe<Scalars['String']>;
};

/** Graphene object for returning a bookmark parameter. */
export type UserBookmarkParameter = {
  __typename?: 'UserBookmarkParameter';
  name: Scalars['String'];
  value: Scalars['String'];
};

/** Contains list of product collections for the site. */
export type ProductsTop = {
  __typename?: 'ProductsTop';
  collections?: Maybe<Array<Maybe<ProductCollection>>>;
  products?: Maybe<Array<Maybe<ProductInfo>>>;
  orderCounts?: Maybe<Array<Maybe<ProductInfo>>>;
  collectionProducts?: Maybe<Array<Maybe<ProductCollection>>>;
};


/** Contains list of product collections for the site. */
export type ProductsTopProductsArgs = {
  collectionId?: InputMaybe<Scalars['String']>;
};

/** Information about a single product collection/category. */
export type ProductCollection = {
  __typename?: 'ProductCollection';
  collectionId?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  pageUrl?: Maybe<Scalars['String']>;
  descriptionHtml?: Maybe<Scalars['String']>;
  isPublished?: Maybe<Scalars['Boolean']>;
};

/** Information about a single product. */
export type ProductInfo = {
  __typename?: 'ProductInfo';
  productId?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  pageUrl?: Maybe<Scalars['String']>;
  imageSrc?: Maybe<Scalars['String']>;
  descriptionHtml?: Maybe<Scalars['String']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  orderCount?: Maybe<Scalars['Int']>;
};

/** Defines the mutations supported by our GraphQL API. */
export type Mutation = {
  __typename?: 'Mutation';
  /** Invokes EntityService.InviteUserToSite(). */
  inviteUserToSite?: Maybe<InviteUserToSite>;
  /** Invokes EntityService.UpdateIssue(). */
  setIssueState?: Maybe<SetState>;
  /** Invokes EntityService.UpdateIssue(). */
  updateIssueTags?: Maybe<UpdateTags>;
  /** Invokes EntityService.UpdateSiteUser() with a ChangeSiteRole action. */
  changeSiteRole?: Maybe<ChangeSiteRole>;
  /**
   * Invokes EntityService.UpdateIssue().
   *
   * Note that this can only be used to assign a new user. If we want to remove a
   * user, we will need to set up an explicit mutation to do so.
   */
  updateIssueAssignee?: Maybe<UpdateAssignee>;
  /** Adds comment to STATIC_COMMENTS. */
  addIssueComment?: Maybe<AddIssueComment>;
  /** Deletes a comment. */
  deleteIssueComment?: Maybe<DeleteIssueComment>;
  /** Edits a comment. */
  editIssueComment?: Maybe<EditIssueComment>;
  /** Updates a site grouping through the entity service. */
  updateSiteGrouping?: Maybe<UpdateSiteGrouping>;
  /**
   * Sets up a link between the customers Google Ads accounts and the
   * Metricstory manager account, then adds the AdWords accounts to the
   * DataSourceLinks for the site.
   */
  linkGoogleAdsAccounts?: Maybe<LinkAccounts>;
  /** Validates the ad description text for creating Google Ads ads. */
  validateAdDescription?: Maybe<ValidateAdDescription>;
  /** Mutation for dismissing an opportunity. */
  dismissOpportunity?: Maybe<Dismiss>;
  /** Mutation for undismissing an opportunity that was previous dismissed. */
  undismissOpportunity?: Maybe<Undismiss>;
  /** Attempts to execute the actions associated with one or more opportunities. */
  executeOpportunities?: Maybe<ExecuteOpportunities>;
  /** Attempts to execute the actions associated with one or more opportunities. */
  generateOpportunities?: Maybe<GenerateOpportunities>;
  /** Invokes EntityService.CreateStripeSubscription(). */
  createStripeSubscription?: Maybe<CreateStripeSubscription>;
  /** Invokes EntityService.CheckStripeSubscription(). */
  checkStripeSubscription?: Maybe<CheckStripeSubscription>;
  /** Invokes EntityService.PrepareShopifySubscription(). */
  prepareShopifySubscription?: Maybe<PrepareShopifySubscription>;
  /** Invokes EntityService.ActivateShopifySubscription(). */
  activateShopifySubscription?: Maybe<ActivateShopifySubscription>;
  /** Mutation for creating a site. */
  createTrialSite?: Maybe<CreateTrialSite>;
  /** Mutation for updating feedback on a site. */
  updateSiteFeedback?: Maybe<UpdateSiteFeedback>;
  /** Mutation for editing the domain for a site. */
  setSiteDomain?: Maybe<SetSiteDomain>;
  /** Invokes EntityService.UpdateUser() with an UpdateBookmarksAction action. */
  updateUserBookmarks?: Maybe<UpdateUserBookmarks>;
  /** Invokes Facebook Conversion API event. */
  setFacebookConversion?: Maybe<SetFacebookConversion>;
};


/** Defines the mutations supported by our GraphQL API. */
export type MutationInviteUserToSiteArgs = {
  email: Scalars['String'];
  newUserProfile: NewUserProfile;
  site: SiteSelector;
  siteRole: SiteRole;
};


/** Defines the mutations supported by our GraphQL API. */
export type MutationSetIssueStateArgs = {
  closeComment?: InputMaybe<Scalars['String']>;
  issueId: Scalars['ID'];
  newDisposition: IssueDisposition;
  newState: IssueState;
  site: SiteSelector;
};


/** Defines the mutations supported by our GraphQL API. */
export type MutationUpdateIssueTagsArgs = {
  addTags: Array<InputMaybe<Scalars['String']>>;
  issueId: Scalars['ID'];
  removeTags: Array<InputMaybe<Scalars['String']>>;
  site: SiteSelector;
};


/** Defines the mutations supported by our GraphQL API. */
export type MutationChangeSiteRoleArgs = {
  newSiteRole: SiteRole;
  site: SiteSelector;
  user: UserSelector;
};


/** Defines the mutations supported by our GraphQL API. */
export type MutationUpdateIssueAssigneeArgs = {
  assigneeUser: UserSelector;
  issueId: Scalars['ID'];
  site: SiteSelector;
};


/** Defines the mutations supported by our GraphQL API. */
export type MutationAddIssueCommentArgs = {
  issueId: Scalars['ID'];
  site: SiteSelector;
  text: Scalars['String'];
};


/** Defines the mutations supported by our GraphQL API. */
export type MutationDeleteIssueCommentArgs = {
  commentId: Scalars['ID'];
  issueId: Scalars['ID'];
  site: SiteSelector;
};


/** Defines the mutations supported by our GraphQL API. */
export type MutationEditIssueCommentArgs = {
  commentId: Scalars['ID'];
  issueId: Scalars['ID'];
  newText: Scalars['String'];
  site: SiteSelector;
};


/** Defines the mutations supported by our GraphQL API. */
export type MutationUpdateSiteGroupingArgs = {
  csv: Array<InputMaybe<Scalars['String']>>;
  doCreate?: InputMaybe<Scalars['Boolean']>;
  site: SiteSelector;
  siteGroupingId?: InputMaybe<Scalars['String']>;
};


/** Defines the mutations supported by our GraphQL API. */
export type MutationLinkGoogleAdsAccountsArgs = {
  accessToken: Scalars['String'];
  attribution?: InputMaybe<Scalars['String']>;
  customerIds: Array<InputMaybe<Scalars['String']>>;
  managerIds: Array<InputMaybe<Scalars['String']>>;
  site: SiteSelector;
  skipBilling?: InputMaybe<Scalars['Boolean']>;
};


/** Defines the mutations supported by our GraphQL API. */
export type MutationValidateAdDescriptionArgs = {
  customerId?: InputMaybe<Scalars['String']>;
  descriptionLine1: Scalars['String'];
  descriptionLine2: Scalars['String'];
  site: SiteSelector;
};


/** Defines the mutations supported by our GraphQL API. */
export type MutationDismissOpportunityArgs = {
  expireDays?: InputMaybe<Scalars['Int']>;
  opportunityId: Scalars['String'];
  reasonComment?: InputMaybe<Scalars['String']>;
  reasonType?: InputMaybe<Scalars['String']>;
  site: SiteSelector;
};


/** Defines the mutations supported by our GraphQL API. */
export type MutationUndismissOpportunityArgs = {
  opportunityId: Scalars['String'];
  site: SiteSelector;
};


/** Defines the mutations supported by our GraphQL API. */
export type MutationExecuteOpportunitiesArgs = {
  alternativeIndexes: Array<InputMaybe<Scalars['Int']>>;
  customAlterations?: InputMaybe<Array<InputMaybe<CustomAlterations>>>;
  opportunityIds: Array<InputMaybe<Scalars['String']>>;
  site: SiteSelector;
};


/** Defines the mutations supported by our GraphQL API. */
export type MutationGenerateOpportunitiesArgs = {
  logEachOpportunity?: InputMaybe<Scalars['Boolean']>;
  maxCountPerType?: InputMaybe<Scalars['Int']>;
  opportunityTypes?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  site: SiteSelector;
};


/** Defines the mutations supported by our GraphQL API. */
export type MutationCreateStripeSubscriptionArgs = {
  plan: Scalars['String'];
  site: SiteSelector;
  trialLengthInDays: Scalars['Int'];
};


/** Defines the mutations supported by our GraphQL API. */
export type MutationCheckStripeSubscriptionArgs = {
  site: SiteSelector;
};


/** Defines the mutations supported by our GraphQL API. */
export type MutationPrepareShopifySubscriptionArgs = {
  plan: Scalars['String'];
  returnUrl: Scalars['String'];
  site: SiteSelector;
};


/** Defines the mutations supported by our GraphQL API. */
export type MutationActivateShopifySubscriptionArgs = {
  recurringChargeId: Scalars['String'];
  site: SiteSelector;
};


/** Defines the mutations supported by our GraphQL API. */
export type MutationCreateTrialSiteArgs = {
  domain?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
};


/** Defines the mutations supported by our GraphQL API. */
export type MutationUpdateSiteFeedbackArgs = {
  feedbackStatus: UserFeedbackStatus;
  location: Scalars['String'];
  site: SiteSelector;
};


/** Defines the mutations supported by our GraphQL API. */
export type MutationSetSiteDomainArgs = {
  domain: Scalars['String'];
  site: SiteSelector;
};


/** Defines the mutations supported by our GraphQL API. */
export type MutationUpdateUserBookmarksArgs = {
  bookmarks: Array<InputMaybe<InputUserBookmark>>;
  featureCategory: Scalars['String'];
};


/** Defines the mutations supported by our GraphQL API. */
export type MutationSetFacebookConversionArgs = {
  email: Scalars['String'];
  value: Scalars['Int'];
};

/** Profile details for registering a new user. */
export type NewUserProfile = {
  name?: InputMaybe<Scalars['String']>;
  givenName?: InputMaybe<Scalars['String']>;
  familyName?: InputMaybe<Scalars['String']>;
  nickname?: InputMaybe<Scalars['String']>;
  picture?: InputMaybe<Scalars['String']>;
};

/** Invokes EntityService.InviteUserToSite(). */
export type InviteUserToSite = {
  __typename?: 'InviteUserToSite';
  ok?: Maybe<Scalars['Boolean']>;
};

/** Invokes EntityService.UpdateIssue(). */
export type SetState = {
  __typename?: 'SetState';
  issue?: Maybe<Issue>;
};

/** Invokes EntityService.UpdateIssue(). */
export type UpdateTags = {
  __typename?: 'UpdateTags';
  issue?: Maybe<Issue>;
};

/** Input object for selecting a user by id or email. */
export type UserSelector = {
  id?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
};

/** Invokes EntityService.UpdateSiteUser() with a ChangeSiteRole action. */
export type ChangeSiteRole = {
  __typename?: 'ChangeSiteRole';
  ok?: Maybe<Scalars['Boolean']>;
};

/**
 * Invokes EntityService.UpdateIssue().
 *
 * Note that this can only be used to assign a new user. If we want to remove a
 * user, we will need to set up an explicit mutation to do so.
 */
export type UpdateAssignee = {
  __typename?: 'UpdateAssignee';
  issue?: Maybe<Issue>;
};

/** Adds comment to STATIC_COMMENTS. */
export type AddIssueComment = {
  __typename?: 'AddIssueComment';
  ok?: Maybe<Scalars['Boolean']>;
  comment?: Maybe<Comment>;
};

/** Deletes a comment. */
export type DeleteIssueComment = {
  __typename?: 'DeleteIssueComment';
  ok?: Maybe<Scalars['Boolean']>;
};

/** Edits a comment. */
export type EditIssueComment = {
  __typename?: 'EditIssueComment';
  ok?: Maybe<Scalars['Boolean']>;
};

/** Updates a site grouping through the entity service. */
export type UpdateSiteGrouping = {
  __typename?: 'UpdateSiteGrouping';
  siteGrouping?: Maybe<SiteGrouping>;
};

/**
 * Sets up a link between the customers Google Ads accounts and the
 * Metricstory manager account, then adds the AdWords accounts to the
 * DataSourceLinks for the site.
 */
export type LinkAccounts = {
  __typename?: 'LinkAccounts';
  site?: Maybe<Site>;
};

/** Validates the ad description text for creating Google Ads ads. */
export type ValidateAdDescription = {
  __typename?: 'ValidateAdDescription';
  isValid?: Maybe<Scalars['Boolean']>;
};

/** Mutation for dismissing an opportunity. */
export type Dismiss = {
  __typename?: 'Dismiss';
  ok?: Maybe<Scalars['Boolean']>;
  message?: Maybe<Scalars['String']>;
};

/** Mutation for undismissing an opportunity that was previous dismissed. */
export type Undismiss = {
  __typename?: 'Undismiss';
  ok?: Maybe<Scalars['Boolean']>;
  message?: Maybe<Scalars['String']>;
};

/**
 * Contains the fields that can be used to create a custom alterations of an
 * opportunity and its action(s).  Only some alterations to opportunity/action
 * variables are allowed currently.
 */
export type CustomAlterations = {
  customerId?: InputMaybe<Scalars['String']>;
  campaignId?: InputMaybe<Scalars['String']>;
  campaignName?: InputMaybe<Scalars['String']>;
  adGroupId?: InputMaybe<Scalars['String']>;
  adGroupName?: InputMaybe<Scalars['String']>;
  defaultCpcBid?: InputMaybe<Scalars['String']>;
  newCpcBid?: InputMaybe<Scalars['String']>;
  newCpcBidMicros?: InputMaybe<Scalars['String']>;
  cpcBidReason?: InputMaybe<Scalars['String']>;
  newCampaignName?: InputMaybe<Scalars['String']>;
  newAdGroupName?: InputMaybe<Scalars['String']>;
  adDescriptionLine1?: InputMaybe<Scalars['String']>;
  adDescriptionLine2?: InputMaybe<Scalars['String']>;
  campaignBudget?: InputMaybe<Scalars['String']>;
  campaignBudgetMicros?: InputMaybe<Scalars['String']>;
  biddingStrategyDesc?: InputMaybe<Scalars['String']>;
  biddingStrategy?: InputMaybe<Scalars['String']>;
  defaultCpcBidMicros?: InputMaybe<Scalars['String']>;
};

/** Attempts to execute the actions associated with one or more opportunities. */
export type ExecuteOpportunities = {
  __typename?: 'ExecuteOpportunities';
  results?: Maybe<Array<Maybe<ExecuteResult>>>;
};

/** Graphene object representing the result of executing an opportunity's actions. */
export type ExecuteResult = {
  __typename?: 'ExecuteResult';
  opportunityId?: Maybe<Scalars['String']>;
  opportunity?: Maybe<Opportunity>;
  ok?: Maybe<Scalars['Boolean']>;
  message?: Maybe<Scalars['String']>;
};

/** Attempts to execute the actions associated with one or more opportunities. */
export type GenerateOpportunities = {
  __typename?: 'GenerateOpportunities';
  generated?: Maybe<Array<Maybe<GenerateResult>>>;
  errors?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** Graphene object representing the result of generating an opportunity. */
export type GenerateResult = {
  __typename?: 'GenerateResult';
  opportunityType?: Maybe<Scalars['String']>;
  opportunityId?: Maybe<Scalars['String']>;
};

/** Invokes EntityService.CreateStripeSubscription(). */
export type CreateStripeSubscription = {
  __typename?: 'CreateStripeSubscription';
  requiresAction?: Maybe<StripeSubscriptionRequiresAction>;
  clientSecret?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
};

/** GraphQL object indication that a subscription required user intervention. */
export type StripeSubscriptionRequiresAction = {
  __typename?: 'StripeSubscriptionRequiresAction';
  paymentIntent?: Maybe<StripePaymentIntent>;
};

/** GraphQL representation of a Stripe PaymentIntent. */
export type StripePaymentIntent = {
  __typename?: 'StripePaymentIntent';
  status?: Maybe<Scalars['String']>;
  clientSecret?: Maybe<Scalars['String']>;
};

/** Invokes EntityService.CheckStripeSubscription(). */
export type CheckStripeSubscription = {
  __typename?: 'CheckStripeSubscription';
  ok?: Maybe<Scalars['Boolean']>;
};

/** Invokes EntityService.PrepareShopifySubscription(). */
export type PrepareShopifySubscription = {
  __typename?: 'PrepareShopifySubscription';
  confirmationUrl?: Maybe<Scalars['String']>;
};

/** Invokes EntityService.ActivateShopifySubscription(). */
export type ActivateShopifySubscription = {
  __typename?: 'ActivateShopifySubscription';
  ok?: Maybe<Scalars['Boolean']>;
};

/** Mutation for creating a site. */
export type CreateTrialSite = {
  __typename?: 'CreateTrialSite';
  site?: Maybe<Site>;
};

/** Mutation for updating feedback on a site. */
export type UpdateSiteFeedback = {
  __typename?: 'UpdateSiteFeedback';
  site?: Maybe<Site>;
};

/** Mutation for editing the domain for a site. */
export type SetSiteDomain = {
  __typename?: 'SetSiteDomain';
  site?: Maybe<Site>;
};

/** Graphene object for inputing a bookmark. */
export type InputUserBookmark = {
  name: Scalars['String'];
  parameters?: InputMaybe<Array<InputMaybe<InputUserBookmarkParameter>>>;
  featureCategory?: InputMaybe<Scalars['String']>;
};

/** Graphene object for inputing a bookmark parameter. */
export type InputUserBookmarkParameter = {
  name: Scalars['String'];
  value: Scalars['String'];
};

/** Invokes EntityService.UpdateUser() with an UpdateBookmarksAction action. */
export type UpdateUserBookmarks = {
  __typename?: 'UpdateUserBookmarks';
  ok?: Maybe<Scalars['Boolean']>;
};

/** Invokes Facebook Conversion API event. */
export type SetFacebookConversion = {
  __typename?: 'SetFacebookConversion';
  ok?: Maybe<Scalars['Boolean']>;
};
