import React, { useMemo, useRef, useState } from "react";
import { Button, Icon, Popup, Table } from "semantic-ui-react";

import styled from "styled-components";

import { Column } from "./columns";
import { FilterArguments } from "./filters";
import { SortOption } from "./sorting";
import { None } from "Common/utils/tsUtils";
import { ampdRed, backgroundDark } from "ExtensionV2/styles/colors";
import { FilterContainer, FilterManager } from "./FilterManager";
import { SEMANTIC_DARK_ACCENT } from "ExtensionV2/components/AmpdDataTable";
import { toast } from "react-toastify";
import SimpleTooltip from "../SimpleTooltip";

const selectBoxWidth = "3rem";
const minCellWidthPixels = 85;

const insetTop = `inset 0px 1px 0px 0px ${ampdRed}`;
const insetRight = `inset -1px 0px 0px 0px ${ampdRed}`;
const insetLeft = `inset 1px 0px 0px 0px ${ampdRed}`;
const insetBottom = `inset 0px -1px 0px 0px ${ampdRed}`;

const insetTopLeft = `inset 1px 1px 0px 0px ${ampdRed}`;
const insetTopRight = `inset -1px 1px 0px 0px ${ampdRed}`;
const insetBottomRight = `inset -1px -1px 0px 0px ${ampdRed}`;
const insetBottomLeft = `inset 1px -1px 0px 0px ${ampdRed}`;

const outsideTop = "0px -1px 0px 0px red";

// simulated left/right border for the first column that holds its position when horizontal
// scrolling
const simulatedSideBorders = `inset 1px 0px 0px 0px ${backgroundDark}, inset -1px 0px 0px 0px ${backgroundDark}`;

const StyledTable = styled(Table)`
  border: none !important;
  table-layout: fixed;

  tbody {
    :hover {
      // show the left border of cell in the first column
      td:first-child {
        box-shadow: ${insetLeft};
      }

      // show the right border of cell in the last column
      td:last-child {
        box-shadow: ${insetRight};
      }

      td:nth-child(2) {
        box-shadow: ${simulatedSideBorders};
      }

      // for the first row of every grouping show the top border of every cell
      // and the top/left border for the first column and the top/right border
      // for the last column
      > tr:first-child {
        td {
          box-shadow: ${insetTop};
        }
        td:first-child {
          box-shadow: ${insetTopLeft};
        }
        td:nth-child(2) {
          box-shadow: ${insetTop}, ${simulatedSideBorders};
        }
        td:last-child {
          box-shadow: ${insetTopRight};
        }
      }

      // for the last row of every grouping show the bottom border of every cell
      // and the bottom/left border for the first column and the bottom/right border
      // for the last column
      > tr:last-child {
        td {
          box-shadow: ${insetBottom};
        }
        td:first-child {
          box-shadow: ${insetBottomLeft};
        }
        td:nth-child(2) {
          box-shadow: ${insetBottom}, ${simulatedSideBorders};
        }
        td:last-child {
          box-shadow: ${insetBottomRight};
        }
      }

      tr:only-child {
        td {
          box-shadow: ${insetTop}, ${insetBottom};
        }
        td:first-child {
          box-shadow: ${insetTopLeft}, ${insetBottom};
        }
        td:nth-child(2) {
          box-shadow: ${insetTop}, ${insetBottom}, ${simulatedSideBorders};
        }
        td:last-child {
          box-shadow: ${insetTopRight}, ${insetBottom};
        }
      }
    }

    // use outline shadow for the top border on every row except the first row
    :not(:first-of-type) {
      :hover {
        tr:first-child {
          td {
            box-shadow: ${outsideTop};
          }

          td:nth-child(2) {
            box-shadow: ${outsideTop}, ${simulatedSideBorders};
          }

          td:last-child {
            box-shadow: ${outsideTop}, ${insetRight};
          }
          td:first-child {
            box-shadow: ${outsideTop}, ${insetLeft};
          }
        }

        tr:only-child {
          td {
            box-shadow: ${outsideTop}, ${insetBottom};
          }
          td:first-child {
            box-shadow: ${outsideTop}, ${insetLeft}, ${insetBottom};
          }
          td:nth-child(2) {
            box-shadow: ${outsideTop}, ${insetBottom}, ${simulatedSideBorders};
          }
          td:last-child {
            box-shadow: ${insetTopRight}, ${insetBottom};
          }
        }
      }
    }
  }

  tbody {
    :nth-child(even) {
      tr:nth-child(odd) {
        td {
          background-color: white;
        }
      }
      tr:nth-child(even) {
        td {
          background-color: ${SEMANTIC_DARK_ACCENT};
        }
      }
    }

    :nth-child(odd) {
      tr:nth-child(even) {
        td {
          background-color: white;
        }
      }
      tr:nth-child(odd) {
        td {
          background-color: ${SEMANTIC_DARK_ACCENT};
        }
      }
    }
  }

  tr {
    :hover {
      td {
        background-color: ${backgroundDark} !important;
      }
    }
  }

  th {
    width: 10rem;
    border: 1px solid ${backgroundDark};
    position: sticky;
    top: 0;
    border-bottom: 1px solid ${backgroundDark} !important;
    padding: 0.5rem 0 0.5rem 0.5rem !important;

    .resizing {
      -webkit-user-select: none; /* Safari */
      user-select: none; /* Standard syntax */
      background-color: ${backgroundDark};
      border: 1px solid red !important;
    }

    > div:first-child {
      display: grid;
      grid-template-columns: 1fr 10px 10px;
      grid-template-rows: 1fr;
      grid-template-areas: "name sort-icon resize-handle";

      h3 {
        grid-area: name;
        margin-bottom: 0;
        font-size: 1rem;
        overflow: hidden;
        white-space: normal;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        cursor: pointer;
      }

      .resize-handle {
        grid-area: resize-handle;
        cursor: col-resize;
        background-color: transparent;
        width: 10px;
        justify-self: end;
      }

      .sort-icon {
        grid-area: sort-icon;
      }
    }

    // Select Box Column
    :nth-child(1) {
      width: ${selectBoxWidth};
      left: 0;
      position: sticky;
      border-left: none;
      border-right: none;
      box-shadow: inset 1px 0px 0px 0px ${backgroundDark};
      border-radius: 0 !important;
      padding: 1em !important;
      z-index: 1;
    }

    // Name Column
    :nth-child(2) {
      width: 40rem;
      left: ${selectBoxWidth};
      position: sticky;
      border-left: none;
      border-right: none;
      box-shadow: ${simulatedSideBorders};
      z-index: 1;
    }

    :nth-child(3) {
      border-left: none;
    }
  }

  td {
    border: 1px solid ${backgroundDark};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-left: none;
    background-color: ${backgroundDark};

    // Select Box Column
    :nth-child(1) {
      text-align: center;
      width: ${selectBoxWidth};
      left: 0;
      position: sticky;
      border-left: none;
      border-right: none;
      box-shadow: inset 1px 0px 0px 0px ${backgroundDark};
    }

    // Name Column
    :nth-child(2) {
      left: ${selectBoxWidth};
      position: sticky;
      border-left: none;
      border-right: none;
      box-shadow: ${simulatedSideBorders};
    }

    :nth-child(3) {
      border-left: none;
    }
  }
`;

export type TMetricsTable<TRow extends { key: string }> = {
  columns: Array<Column<TRow>>;
  rows: Array<TRow>;
  mapDataRowsToTableRows: (
    row: Array<TRow>,
    columns: Array<Column<TRow>>,
    expandedRows: Set<string>,
    toggleExpandedRow: (key: string) => void
  ) => Array<JSX.Element>;
  mapDataRowsToFooter?: (
    rows: Array<TRow>,
    columns: Array<Column<TRow>>
  ) => JSX.Element;
  tableOptions?: {
    storageKey: string;
    urlParam: string;
    defaultValue: Record<string, unknown>;
  };
};

export type MetricsSort<TData> = {
  column: Column<TData>;
  direction: SortOption;
};

export type MetricsFilter<TData> = {
  column: Column<TData>;
  args: FilterArguments;
};

// Every row must have a unique key
export const MetricsTable = <TData extends { key: string }>(props: {
  tableConfig: TMetricsTable<TData>;
  initialFilters?: Array<MetricsFilter<TData>>;
  initialSort?: MetricsSort<TData> | None;
  initialExpandedRows?: Set<string>;
  onUpdateSort?: (s: MetricsSort<TData>) => void;
  onToggleExpandedRows?: (rows: Set<string>) => void;
  onUpdateFilters?: (filters: Array<MetricsFilter<TData>>) => void;
}): JSX.Element => {
  const [sortColumn, setSortColumn] = useState<MetricsSort<TData> | None>(
    props.initialSort
  );

  const [filters, setFilters] = useState<Array<MetricsFilter<TData>>>(
    props.initialFilters || []
  );

  const [expandedRows, setExpandedRows] = useState<Set<string>>(
    props.initialExpandedRows || new Set()
  );

  const toggleExpandedRow = (key: string) => {
    const nextExpandedRows = new Set(expandedRows);
    if (nextExpandedRows.has(key)) {
      nextExpandedRows.delete(key);
    } else {
      nextExpandedRows.add(key);
    }

    props.onToggleExpandedRows && props.onToggleExpandedRows(nextExpandedRows);
    setExpandedRows(nextExpandedRows);
  };

  const onUpdateSort = (sort: MetricsSort<TData>) => {
    setSortColumn(sort);
    props.onUpdateSort && props.onUpdateSort(sort);
  };

  const onUpdateFilters = (filters: Array<MetricsFilter<TData>>) => {
    setFilters(filters);
    props.onUpdateFilters && props.onUpdateFilters(filters);
  };

  const handleShareView = () => {
    if (!props.tableConfig.tableOptions) {
      return;
    }
    const {
      storageKey,
      urlParam,
      defaultValue
    } = props.tableConfig.tableOptions;

    let storedViewOptions = localStorage.getItem(storageKey);
    if (!storedViewOptions) {
      storedViewOptions = JSON.stringify(defaultValue);
    }

    const url = new URL(window.location.href);
    url.searchParams.set(urlParam, storedViewOptions);
    navigator.clipboard.writeText(url.toString());
    toast.success("Link copied to clipboard", {
      autoClose: 2000,
      hideProgressBar: true
    });
  };

  return (
    <>
      <div
        style={{
          marginBottom: "1em",
          display: "flex",
          gap: "0.5rem"
        }}
      >
        <FilterManager<TData>
          columns={props.tableConfig.columns}
          filters={filters}
          onSetFilters={onUpdateFilters}
        />
        {props.tableConfig.tableOptions && (
          <SimpleTooltip
            tooltip="Share a link to this table"
            position="bottom left"
          >
            <Button icon="share" onClick={handleShareView} />
          </SimpleTooltip>
        )}
      </div>
      <FilterContainer filters={filters} onSetFilters={onUpdateFilters} />
      <div style={{ overflow: "auto", height: "95%" }}>
        <BasicMetricsTable
          tableConfig={props.tableConfig}
          filters={filters}
          expandedRows={expandedRows}
          toggleExpandedRow={toggleExpandedRow}
          sort={sortColumn}
          onUpdateSort={onUpdateSort}
        />
      </div>
    </>
  );
};

const BasicMetricsTable = <TData extends { key: string }>({
  tableConfig,
  filters,
  sort,
  expandedRows,
  toggleExpandedRow,
  onUpdateSort
}: {
  tableConfig: TMetricsTable<TData>;
  filters: Array<MetricsFilter<TData>>;
  sort: MetricsSort<TData> | None;
  expandedRows: Set<string>;
  toggleExpandedRow: (key: string) => void;
  onUpdateSort: (s: MetricsSort<TData>) => void;
}): JSX.Element => {
  const {
    columns,
    rows,
    mapDataRowsToTableRows,
    mapDataRowsToFooter
  } = tableConfig;

  const sortedAndFilteredRows = useMemo(() => {
    let filteredRows = [...rows];
    for (const filter of filters) {
      filteredRows = filteredRows.filter(row =>
        filter.column.filter(row, filter.args)
      );
    }

    if (!sort) {
      return filteredRows;
    }

    return filteredRows.sort((a, b) =>
      sort.column.compare(a, b, sort.direction)
    );
  }, [filters, rows, sort]);

  const handleSetSort = (nextSort: MetricsSort<TData>) => {
    onUpdateSort(nextSort);
  };

  return (
    <>
      <StyledTable celled unstackable>
        <Table.Header>
          <Table.Row>
            <th>
              <input type="checkbox" />
            </th>
            {columns.map(column => {
              return (
                <Header
                  key={String(column.dataName)}
                  column={column}
                  onClickSort={handleSetSort}
                  sortDirection={
                    sort?.column === column ? sort.direction : undefined
                  }
                />
              );
            })}
          </Table.Row>
        </Table.Header>
        {mapDataRowsToTableRows(
          sortedAndFilteredRows,
          columns,
          expandedRows,
          toggleExpandedRow
        )}
        {mapDataRowsToFooter &&
          mapDataRowsToFooter(sortedAndFilteredRows, columns)}
      </StyledTable>
    </>
  );
};

const Header = <TData,>({
  column,
  onClickSort,
  sortDirection
}: {
  column: Column<TData>;
  onClickSort: (s: MetricsSort<TData>) => void;
  sortDirection?: SortOption;
}): JSX.Element => {
  const [tooltipOpen, setTooltipOpen] = useState(false);

  /* Start Draggable Column Header Stuff */
  const startX = useRef(0);
  const startWidth = useRef(0);
  const columnRef = useRef<HTMLTableCellElement>(null);

  function handleMouseDown(ev: React.MouseEvent) {
    startX.current = ev.pageX;
    startWidth.current = ev.currentTarget.parentElement?.offsetWidth || 0;
    columnRef.current?.classList.add("resizing");
    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
  }

  function handleMouseMove(ev: MouseEvent) {
    const diffX = ev.pageX - startX.current;
    const nextWidth = startWidth.current + diffX;
    if (columnRef.current && nextWidth >= minCellWidthPixels) {
      columnRef.current.style.width = `${startWidth.current + diffX}px`;
    }
  }

  function handleMouseUp() {
    columnRef.current?.classList.remove("resizing");
    document.removeEventListener("mousemove", handleMouseMove);
    document.removeEventListener("mouseup", handleMouseUp);
  }
  /* End Draggable Column Header Stuff */

  function handleClickSort() {
    let direction: SortOption;
    if (sortDirection === "asc") {
      direction = "desc";
    } else if (sortDirection === "desc") {
      direction = "asc";
    } else {
      direction = column.defaultSortDirection || "asc";
    }

    onClickSort({
      column: column,
      direction: direction
    });
  }

  return (
    <Popup
      style={{ padding: 10 }}
      animation="none"
      trigger={
        <th
          ref={columnRef}
          onMouseEnter={() => setTooltipOpen(true)}
          onMouseLeave={() => setTooltipOpen(false)}
        >
          <div>
            <h3 onClick={handleClickSort}>{column.displayName}</h3>
            <SortingIcon
              sortDirection={sortDirection}
              onClick={handleClickSort}
            />
            <div className="resize-handle" onMouseDown={handleMouseDown} />
          </div>
        </th>
      }
      position={"bottom center"}
      open={tooltipOpen}
      disabled={!column.tooltip}
    >
      <strong>
        <small>{column.tooltip}</small>
      </strong>
    </Popup>
  );
};

function SortingIcon({
  sortDirection,
  onClick
}: {
  sortDirection?: SortOption;
  onClick: () => void;
}): JSX.Element {
  return (
    <Icon
      className="sort-icon"
      style={{
        alignSelf: "center",
        visibility: sortDirection ? "visible" : "hidden"
      }}
      name={
        sortDirection == null
          ? "sort"
          : sortDirection === "asc"
          ? "arrow up"
          : "arrow down"
      }
      size="small"
      onClick={onClick}
    />
  );
}
