import { Retailer } from "Common/proto/common/retailer_pb";
import { FacebookTableData } from "./FacebookPageV2";

export const testRows: Array<FacebookTableData> = [
  // campaign 1
  {
    key: "campaign-1",
    addToCartRate: 2.5,
    addToCartClicks: 150,
    brandReferralBonus: 10,
    conversionRate: 3.2,
    conversions: 120,
    conversionValue: 5000,
    costPerAddToCart: 1.5,
    costPerDetailPageView: 0.5,
    costPerMille: 10,
    detailPageViewRate: 4.5,
    detailPageViews: 300,
    facebookCostPerOutboundClick: 0.8,
    facebookInteractions: 200,
    facebookOutboundClickRate: 1.2,
    facebookOutboundClicks: 500,
    impressions: 10000,
    marketplaceClickRate: 2.1,
    marketplaceClicks: 210,
    marketplaceCostPerClick: 0.7,
    ntbConversionRate: 1.5,
    ntbConversions: 50,
    ntbRevenue: 2000,
    ntbRevenueRate: 1.8,
    ntbROAS: 2.5,
    ntbUnitsSold: 60,
    ntbUnitsSoldRate: 1.9,
    roas: 3.0,
    spend: 1500,
    unitsSold: 180,
    accountID: "1",
    adID: "",
    adSetID: "",
    campaignID: "campaign-1",
    name: "Campaign 1",
    retailer: Retailer.Option.AMAZON,
    prev: {
      addToCartRate: 2.0,
      addToCartClicks: 100,
      brandReferralBonus: 8,
      conversionRate: 2.8,
      conversions: 90,
      conversionValue: 4000,
      costPerAddToCart: 1.2,
      costPerDetailPageView: 0.4,
      costPerMille: 8,
      detailPageViewRate: 3.8,
      detailPageViews: 250,
      facebookCostPerOutboundClick: 0.7,
      facebookInteractions: 150,
      facebookOutboundClickRate: 1.0,
      facebookOutboundClicks: 140,
      impressions: 8000,
      marketplaceClickRate: 1.8,
      marketplaceClicks: 180,
      marketplaceCostPerClick: 0.6,
      ntbConversionRate: 1.3,
      ntbConversions: 40,
      ntbRevenue: 1600,
      ntbRevenueRate: 1.5,
      ntbROAS: 2.0,
      ntbUnitsSold: 50,
      ntbUnitsSoldRate: 1.6,
      roas: 2.5,
      spend: 1200,
      unitsSold: 150,
      accountID: "1",
      adID: "",
      adSetID: "campaign-1-ad-set-1",
      campaignID: "campaign-1",
      name: "Ad Set 1 (Campaign 1)",
      retailer: Retailer.Option.AMAZON
    }
  },
  {
    key: "campaign-1-ad-set-1",
    addToCartRate: 2.0,
    addToCartClicks: 100,
    brandReferralBonus: 8,
    conversionRate: 2.8,
    conversions: 90,
    conversionValue: 4000,
    costPerAddToCart: 1.2,
    costPerDetailPageView: 0.4,
    costPerMille: 8,
    detailPageViewRate: 3.8,
    detailPageViews: 250,
    facebookCostPerOutboundClick: 0.7,
    facebookInteractions: 150,
    facebookOutboundClickRate: 1.0,
    facebookOutboundClicks: 140,
    impressions: 8000,
    marketplaceClickRate: 1.8,
    marketplaceClicks: 300,
    marketplaceCostPerClick: 0.6,
    ntbConversionRate: 1.3,
    ntbConversions: 40,
    ntbRevenue: 1600,
    ntbRevenueRate: 1.5,
    ntbROAS: 2.0,
    ntbUnitsSold: 50,
    ntbUnitsSoldRate: 1.6,
    roas: 2.5,
    spend: 1200,
    unitsSold: 150,
    accountID: "1",
    adID: "",
    adSetID: "campaign-1-ad-set-1",
    campaignID: "campaign-1",
    name: "Ad Set 1 (Campaign 1)",
    retailer: Retailer.Option.AMAZON,
    prev: {
      addToCartRate: 1.8,
      addToCartClicks: 80,
      brandReferralBonus: 7,
      conversionRate: 2.5,
      conversions: 70,
      conversionValue: 3500,
      costPerAddToCart: 1.0,
      costPerDetailPageView: 0.3,
      costPerMille: 7,
      detailPageViewRate: 3.5,
      detailPageViews: 200,
      facebookCostPerOutboundClick: 0.6,
      facebookInteractions: 120,
      facebookOutboundClickRate: 0.9,
      facebookOutboundClicks: 110,
      impressions: 7000,
      marketplaceClickRate: 1.6,
      marketplaceClicks: 160,
      marketplaceCostPerClick: 0.5,
      ntbConversionRate: 1.2,
      ntbConversions: 35,
      ntbRevenue: 1400,
      ntbRevenueRate: 1.4,
      ntbROAS: 1.8,
      ntbUnitsSold: 45,
      ntbUnitsSoldRate: 1.5,
      roas: 2.2,
      spend: 1000,
      unitsSold: 130,
      accountID: "1",
      adID: "",
      adSetID: "campaign-1-ad-set-2",
      campaignID: "campaign-1",
      name: "Ad Set 2 (Campaign 1)",
      retailer: Retailer.Option.AMAZON
    }
  },
  {
    key: "campaign-1-ad-set-2",
    addToCartRate: 1.8,
    addToCartClicks: 80,
    brandReferralBonus: 7,
    conversionRate: 2.5,
    conversions: 70,
    conversionValue: 3500,
    costPerAddToCart: 1.0,
    costPerDetailPageView: 0.3,
    costPerMille: 7,
    detailPageViewRate: 3.5,
    detailPageViews: 200,
    facebookCostPerOutboundClick: 0.6,
    facebookInteractions: 120,
    facebookOutboundClickRate: 0.9,
    facebookOutboundClicks: 110,
    impressions: 7000,
    marketplaceClickRate: 1.6,
    marketplaceClicks: 160,
    marketplaceCostPerClick: 0.5,
    ntbConversionRate: 1.2,
    ntbConversions: 35,
    ntbRevenue: 1400,
    ntbRevenueRate: 1.4,
    ntbROAS: 1.8,
    ntbUnitsSold: 45,
    ntbUnitsSoldRate: 1.5,
    roas: 2.2,
    spend: 1000,
    unitsSold: 130,
    accountID: "1",
    adID: "",
    adSetID: "campaign-1-ad-set-2",
    campaignID: "campaign-1",
    name: "Ad Set 2 (Campaign 1)",
    retailer: Retailer.Option.AMAZON,
    prev: {
      addToCartRate: 1.5,
      addToCartClicks: 60,
      brandReferralBonus: 5,
      conversionRate: 2.0,
      conversions: 50,
      conversionValue: 2500,
      costPerAddToCart: 0.8,
      costPerDetailPageView: 0.2,
      costPerMille: 5,
      detailPageViewRate: 2.8,
      detailPageViews: 150,
      facebookCostPerOutboundClick: 0.5,
      facebookInteractions: 100,
      facebookOutboundClickRate: 0.7,
      facebookOutboundClicks: 90,
      impressions: 5000,
      marketplaceClickRate: 1.2,
      marketplaceClicks: 120,
      marketplaceCostPerClick: 0.4,
      ntbConversionRate: 1.0,
      ntbConversions: 30,
      ntbRevenue: 1000,
      ntbRevenueRate: 1.2,
      ntbROAS: 1.5,
      ntbUnitsSold: 35,
      ntbUnitsSoldRate: 1.3,
      roas: 1.8,
      spend: 800,
      unitsSold: 100,
      accountID: "1",
      adID: "campaign-1-ad-set-1-ad-1",
      adSetID: "campaign-1-ad-set-1",
      campaignID: "campaign-1",
      name: "Ad 1 (Campaign 1, Ad Set 1)",
      retailer: Retailer.Option.AMAZON
    }
  },
  {
    key: "campaign-1-ad-set-1-ad-1",
    addToCartRate: 1.5,
    addToCartClicks: 60,
    brandReferralBonus: 5,
    conversionRate: 2.0,
    conversions: 50,
    conversionValue: 2500,
    costPerAddToCart: 0.8,
    costPerDetailPageView: 0.2,
    costPerMille: 5,
    detailPageViewRate: 2.8,
    detailPageViews: 150,
    facebookCostPerOutboundClick: 0.5,
    facebookInteractions: 100,
    facebookOutboundClickRate: 0.7,
    facebookOutboundClicks: 90,
    impressions: 5000,
    marketplaceClickRate: 1.2,
    marketplaceClicks: 500,
    marketplaceCostPerClick: 0.4,
    ntbConversionRate: 1.0,
    ntbConversions: 30,
    ntbRevenue: 1000,
    ntbRevenueRate: 1.2,
    ntbROAS: 1.5,
    ntbUnitsSold: 35,
    ntbUnitsSoldRate: 1.3,
    roas: 1.8,
    spend: 800,
    unitsSold: 100,
    accountID: "1",
    adID: "campaign-1-ad-set-1-ad-1",
    adSetID: "campaign-1-ad-set-1",
    campaignID: "campaign-1",
    name: "Ad 1 (Campaign 1, Ad Set 1)",
    retailer: Retailer.Option.AMAZON,
    prev: {
      addToCartRate: 1.3,
      addToCartClicks: 50,
      brandReferralBonus: 4,
      conversionRate: 1.8,
      conversions: 40,
      conversionValue: 2000,
      costPerAddToCart: 0.7,
      costPerDetailPageView: 0.2,
      costPerMille: 4,
      detailPageViewRate: 2.5,
      detailPageViews: 130,
      facebookCostPerOutboundClick: 0.4,
      facebookInteractions: 80,
      facebookOutboundClickRate: 0.6,
      facebookOutboundClicks: 70,
      impressions: 4000,
      marketplaceClickRate: 1.0,
      marketplaceClicks: 100,
      marketplaceCostPerClick: 0.3,
      ntbConversionRate: 0.9,
      ntbConversions: 25,
      ntbRevenue: 800,
      ntbRevenueRate: 1.1,
      ntbROAS: 1.3,
      ntbUnitsSold: 30,
      ntbUnitsSoldRate: 1.2,
      roas: 1.5,
      spend: 600,
      unitsSold: 80,
      accountID: "1",
      adID: "campaign-1-ad-set-2-ad-1",
      adSetID: "campaign-1-ad-set-2",
      campaignID: "campaign-1",
      name: "Ad 1 (Campaign 1, Ad Set 2)",
      retailer: Retailer.Option.AMAZON
    }
  },
  {
    key: "campaign-1-ad-set-2-ad-1",
    addToCartRate: 1.3,
    addToCartClicks: 50,
    brandReferralBonus: 4,
    conversionRate: 1.8,
    conversions: 40,
    conversionValue: 2000,
    costPerAddToCart: 0.7,
    costPerDetailPageView: 0.2,
    costPerMille: 4,
    detailPageViewRate: 2.5,
    detailPageViews: 130,
    facebookCostPerOutboundClick: 0.4,
    facebookInteractions: 80,
    facebookOutboundClickRate: 0.6,
    facebookOutboundClicks: 70,
    impressions: 4000,
    marketplaceClickRate: 1.0,
    marketplaceClicks: 100,
    marketplaceCostPerClick: 0.3,
    ntbConversionRate: 0.9,
    ntbConversions: 25,
    ntbRevenue: 800,
    ntbRevenueRate: 1.1,
    ntbROAS: 1.3,
    ntbUnitsSold: 30,
    ntbUnitsSoldRate: 1.2,
    roas: 1.5,
    spend: 600,
    unitsSold: 80,
    accountID: "1",
    adID: "campaign-1-ad-set-2-ad-1",
    adSetID: "campaign-1-ad-set-2",
    campaignID: "campaign-1",
    name: "Ad 1 (Campaign 1, Ad Set 2)",
    retailer: Retailer.Option.AMAZON,
    prev: {
      addToCartRate: 1.2,
      addToCartClicks: 40,
      brandReferralBonus: 3,
      conversionRate: 1.5,
      conversions: 30,
      conversionValue: 1500,
      costPerAddToCart: 0.6,
      costPerDetailPageView: 0.1,
      costPerMille: 3,
      detailPageViewRate: 2.2,
      detailPageViews: 110,
      facebookCostPerOutboundClick: 0.3,
      facebookInteractions: 60,
      facebookOutboundClickRate: 0.5,
      facebookOutboundClicks: 50,
      impressions: 3000,
      marketplaceClickRate: 0.8,
      marketplaceClicks: 80,
      marketplaceCostPerClick: 0.2,
      ntbConversionRate: 0.8,
      ntbConversions: 20,
      ntbRevenue: 600,
      ntbRevenueRate: 1.0,
      ntbROAS: 1.2,
      ntbUnitsSold: 25,
      ntbUnitsSoldRate: 1.1,
      roas: 1.3,
      spend: 400,
      unitsSold: 60,
      accountID: "1",
      adID: "campaign-1-ad-set-2-ad-2",
      adSetID: "campaign-1-ad-set-2",
      campaignID: "campaign-1",
      name: "Ad 2 (Campaign 1, Ad Set 2)",
      retailer: Retailer.Option.AMAZON
    }
  },
  {
    key: "campaign-1-ad-set-2-ad-2",
    addToCartRate: 1.2,
    addToCartClicks: 40,
    brandReferralBonus: 3,
    conversionRate: 1.5,
    conversions: 30,
    conversionValue: 1500,
    costPerAddToCart: 0.6,
    costPerDetailPageView: 0.1,
    costPerMille: 3,
    detailPageViewRate: 2.2,
    detailPageViews: 110,
    facebookCostPerOutboundClick: 0.3,
    facebookInteractions: 60,
    facebookOutboundClickRate: 0.5,
    facebookOutboundClicks: 50,
    impressions: 3000,
    marketplaceClickRate: 0.8,
    marketplaceClicks: 80,
    marketplaceCostPerClick: 0.2,
    ntbConversionRate: 0.8,
    ntbConversions: 20,
    ntbRevenue: 600,
    ntbRevenueRate: 1.0,
    ntbROAS: 1.2,
    ntbUnitsSold: 25,
    ntbUnitsSoldRate: 1.1,
    roas: 1.3,
    spend: 400,
    unitsSold: 60,
    accountID: "1",
    adID: "campaign-1-ad-set-2-ad-2",
    adSetID: "campaign-1-ad-set-2",
    campaignID: "campaign-1",
    name: "Ad 2 (Campaign 1, Ad Set 2)",
    retailer: Retailer.Option.AMAZON,
    prev: {
      addToCartRate: 1.0,
      addToCartClicks: 30,
      brandReferralBonus: 2,
      conversionRate: 1.2,
      conversions: 20,
      conversionValue: 1000,
      costPerAddToCart: 0.5,
      costPerDetailPageView: 0.1,
      costPerMille: 2,
      detailPageViewRate: 1.8,
      detailPageViews: 90,
      facebookCostPerOutboundClick: 0.2,
      facebookInteractions: 40,
      facebookOutboundClickRate: 0.4,
      facebookOutboundClicks: 30,
      impressions: 2000,
      marketplaceClickRate: 0.6,
      marketplaceClicks: 60,
      marketplaceCostPerClick: 0.1,
      ntbConversionRate: 0.7,
      ntbConversions: 15,
      ntbRevenue: 400,
      ntbRevenueRate: 0.9,
      ntbROAS: 1.0,
      ntbUnitsSold: 20,
      ntbUnitsSoldRate: 1.0,
      roas: 1.0,
      spend: 200,
      unitsSold: 40,
      accountID: "1",
      adID: "campaign-1-ad-set-3-ad-1",
      adSetID: "campaign-1-ad-set-3",
      campaignID: "campaign-1",
      name: "Ad 1 (Campaign 1, Ad Set 3)",
      retailer: Retailer.Option.AMAZON
    }
  },
  // campaign 2
  {
    key: "campaign-2",
    addToCartRate: 2.8,
    addToCartClicks: 160,
    brandReferralBonus: 12,
    conversionRate: 3.5,
    conversions: 130,
    conversionValue: 5500,
    costPerAddToCart: 1.6,
    costPerDetailPageView: 0.6,
    costPerMille: 11,
    detailPageViewRate: 4.8,
    detailPageViews: 320,
    facebookCostPerOutboundClick: 0.9,
    facebookInteractions: 220,
    facebookOutboundClickRate: 1.3,
    facebookOutboundClicks: 190,
    impressions: 11000,
    marketplaceClickRate: 2.3,
    marketplaceClicks: 230,
    marketplaceCostPerClick: 0.8,
    ntbConversionRate: 1.6,
    ntbConversions: 55,
    ntbRevenue: 2200,
    ntbRevenueRate: 1.9,
    ntbROAS: 2.7,
    ntbUnitsSold: 65,
    ntbUnitsSoldRate: 2.0,
    roas: 3.2,
    spend: 1600,
    unitsSold: 190,
    accountID: "1",
    adID: "",
    adSetID: "",
    campaignID: "campaign-2",
    name: "Campaign 2",
    retailer: Retailer.Option.AMAZON,
    prev: {
      addToCartRate: 2.5,
      addToCartClicks: 150,
      brandReferralBonus: 10,
      conversionRate: 3.2,
      conversions: 120,
      conversionValue: 5000,
      costPerAddToCart: 1.5,
      costPerDetailPageView: 0.5,
      costPerMille: 10,
      detailPageViewRate: 4.5,
      detailPageViews: 300,
      facebookCostPerOutboundClick: 0.8,
      facebookInteractions: 200,
      facebookOutboundClickRate: 1.2,
      facebookOutboundClicks: 185,
      impressions: 10000,
      marketplaceClickRate: 2.1,
      marketplaceClicks: 210,
      marketplaceCostPerClick: 0.7,
      ntbConversionRate: 1.5,
      ntbConversions: 50,
      ntbRevenue: 2000,
      ntbRevenueRate: 1.8,
      ntbROAS: 2.5,
      ntbUnitsSold: 60,
      ntbUnitsSoldRate: 1.9,
      roas: 3.0,
      spend: 1500,
      unitsSold: 180,
      accountID: "1",
      adID: "",
      adSetID: "campaign-2-ad-set-1",
      campaignID: "campaign-2",
      name: "Ad Set 1 (Campaign 2)",
      retailer: Retailer.Option.AMAZON
    }
  },
  {
    key: "campaign-2-ad-set-1",
    addToCartRate: 2.1,
    addToCartClicks: 110,
    brandReferralBonus: 9,
    conversionRate: 2.9,
    conversions: 95,
    conversionValue: 4200,
    costPerAddToCart: 1.3,
    costPerDetailPageView: 0.5,
    costPerMille: 9,
    detailPageViewRate: 3.9,
    detailPageViews: 260,
    facebookCostPerOutboundClick: 0.8,
    facebookInteractions: 160,
    facebookOutboundClickRate: 1.1,
    facebookOutboundClicks: 150,
    impressions: 9000,
    marketplaceClickRate: 1.9,
    marketplaceClicks: 190,
    marketplaceCostPerClick: 0.7,
    ntbConversionRate: 1.4,
    ntbConversions: 45,
    ntbRevenue: 1700,
    ntbRevenueRate: 1.6,
    ntbROAS: 2.1,
    ntbUnitsSold: 55,
    ntbUnitsSoldRate: 1.7,
    roas: 2.6,
    spend: 1300,
    unitsSold: 160,
    accountID: "1",
    adID: "",
    adSetID: "campaign-2-ad-set-1",
    campaignID: "campaign-2",
    name: "Ad Set 1 (Campaign 2)",
    retailer: Retailer.Option.AMAZON,
    prev: {
      addToCartRate: 1.6,
      addToCartClicks: 70,
      brandReferralBonus: 6,
      conversionRate: 2.1,
      conversions: 60,
      conversionValue: 2800,
      costPerAddToCart: 0.9,
      costPerDetailPageView: 0.3,
      costPerMille: 6,
      detailPageViewRate: 3.0,
      detailPageViews: 180,
      facebookCostPerOutboundClick: 0.6,
      facebookInteractions: 120,
      facebookOutboundClickRate: 0.8,
      facebookOutboundClicks: 100,
      impressions: 6000,
      marketplaceClickRate: 1.3,
      marketplaceClicks: 130,
      marketplaceCostPerClick: 0.5,
      ntbConversionRate: 1.1,
      ntbConversions: 35,
      ntbRevenue: 1200,
      ntbRevenueRate: 1.3,
      ntbROAS: 1.6,
      ntbUnitsSold: 40,
      ntbUnitsSoldRate: 1.4,
      roas: 2.0,
      spend: 900,
      unitsSold: 110,
      accountID: "1",
      adID: "campaign-2-ad-set-1-ad-1",
      adSetID: "campaign-2-ad-set-1",
      campaignID: "campaign-2",
      name: "Ad 1 (Campaign 2, Ad Set 1)",
      retailer: Retailer.Option.AMAZON
    }
  },
  {
    key: "campaign-2-ad-set-1-ad-1",
    addToCartRate: 1.6,
    addToCartClicks: 70,
    brandReferralBonus: 6,
    conversionRate: 2.1,
    conversions: 60,
    conversionValue: 2800,
    costPerAddToCart: 0.9,
    costPerDetailPageView: 0.3,
    costPerMille: 6,
    detailPageViewRate: 3.0,
    detailPageViews: 180,
    facebookCostPerOutboundClick: 0.6,
    facebookInteractions: 120,
    facebookOutboundClickRate: 0.8,
    facebookOutboundClicks: 100,
    impressions: 6000,
    marketplaceClickRate: 1.3,
    marketplaceClicks: 130,
    marketplaceCostPerClick: 0.5,
    ntbConversionRate: 1.1,
    ntbConversions: 35,
    ntbRevenue: 1200,
    ntbRevenueRate: 1.3,
    ntbROAS: 1.6,
    ntbUnitsSold: 40,
    ntbUnitsSoldRate: 1.4,
    roas: 2.0,
    spend: 900,
    unitsSold: 110,
    accountID: "1",
    adID: "campaign-2-ad-set-1-ad-1",
    adSetID: "campaign-2-ad-set-1",
    campaignID: "campaign-2",
    name: "Ad 1 (Campaign 2, Ad Set 1)",
    retailer: Retailer.Option.AMAZON,
    prev: {
      addToCartRate: 1.3,
      addToCartClicks: 50,
      brandReferralBonus: 4,
      conversionRate: 1.8,
      conversions: 40,
      conversionValue: 2000,
      costPerAddToCart: 0.7,
      costPerDetailPageView: 0.2,
      costPerMille: 4,
      detailPageViewRate: 2.5,
      detailPageViews: 130,
      facebookCostPerOutboundClick: 0.4,
      facebookInteractions: 80,
      facebookOutboundClickRate: 0.6,
      facebookOutboundClicks: 70,
      impressions: 4000,
      marketplaceClickRate: 1.0,
      marketplaceClicks: 100,
      marketplaceCostPerClick: 0.3,
      ntbConversionRate: 0.9,
      ntbConversions: 25,
      ntbRevenue: 800,
      ntbRevenueRate: 1.1,
      ntbROAS: 1.3,
      ntbUnitsSold: 30,
      ntbUnitsSoldRate: 1.2,
      roas: 1.5,
      spend: 600,
      unitsSold: 80,
      accountID: "1",
      adID: "campaign-2-ad-set-2-ad-1",
      adSetID: "campaign-2-ad-set-2",
      campaignID: "campaign-2",
      name: "Ad 1 (Campaign 2, Ad Set 2)",
      retailer: Retailer.Option.AMAZON
    }
  }
];

// This is just here for now so we can mock the data into our tests...
// this will be replaced with a grpc call in the future
export const fetchRows = (
  rows: Array<FacebookTableData>,
  withPrev: boolean
): Array<FacebookTableData> => {
  if (withPrev) {
    return rows;
  }

  return rows.map(row => {
    const newRow = { ...row };
    delete newRow.prev;
    return newRow;
  });
};
