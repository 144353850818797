import React from "react";
import { Table } from "semantic-ui-react";
import styled from "styled-components";
import { MetricsTableCell } from "../columns";

const StyledSimpleCell = styled(Table.Cell)``;

export const SimpleCell: MetricsTableCell = ({
  uniqKey,
  val
}: {
  uniqKey: string;
  val: unknown;
}) => {
  return <StyledSimpleCell key={uniqKey}>{val}</StyledSimpleCell>;
};
