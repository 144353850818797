import React, { useMemo } from "react";

import {
  MetricsFilter,
  MetricsSort,
  MetricsTable,
  TMetricsTable
} from "ExtensionV2/components/metricsTable/MetricsTable";
import { Dropdown, Icon, Segment, Table } from "semantic-ui-react";
import {
  Column,
  COL_ATC,
  COL_ATC_RATE,
  COL_CONVERSIONS,
  COL_CONVERSION_RATE,
  COL_CONVERSION_VALUE,
  COL_COST_PER_ADD_TO_CART,
  COL_COST_PER_DETAIL_PAGE_VIEW,
  COL_COST_PER_MILLE,
  COL_DETAIL_PAGE_VIEW_RATE,
  COL_DETAIL_PAGE_VIEWS,
  COL_FACEBOOK_COST_PER_OUTBOUND_CLICK,
  COL_FACEBOOK_INTERACTIONS,
  COL_FACEBOOK_OUTBOUND_CLICK_RATE,
  COL_FACEBOOK_OUTBOUND_CLICKS,
  COL_IMPRESSIONS,
  COL_MARKETPLACE_CLICK_RATE,
  COL_MARKETPLACE_CLICKS,
  COL_MARKETPLACE_COST_PER_CLICK,
  COL_NTB_CONVERSION_RATE,
  COL_NTB_CONVERSIONS,
  COL_NTB_REVENUE,
  COL_NTB_REVENUE_RATE,
  COL_NTB_ROAS,
  COL_NTB_UNITS_SOLD,
  COL_NTB_UNITS_SOLD_RATE,
  COL_ROAS,
  COL_SPEND,
  COL_UNITS_SOLD,
  COL_ACCOUNT_ID,
  COL_AD_ID,
  COL_AD_SET_ID,
  COL_CAMPAIGN_ID,
  COL_NAME
} from "../../components/metricsTable/columns";
import { Retailer } from "Common/proto/common/retailer_pb";
import styled from "styled-components";

import { fetchRows, testRows } from "./fetchRows";
import { useURLParamOrLocalStorageObject } from "ExtensionV2/state/useURLParamOrLocalStorageObject";
import { None, removeNullAndUndefined } from "Common/utils/tsUtils";
import { FilterArguments } from "ExtensionV2/components/metricsTable/filters";
import { ampdRed, backgroundDark } from "ExtensionV2/styles/colors";
import {
  CompareMetricsLoadingContext,
  ShowFractionsContext
} from "ExtensionV2/components/metricsTable/contexts";

export type FacebookTableData = {
  key: string;
  // metrics
  addToCartRate: number;
  addToCartClicks: number;
  brandReferralBonus: number;
  conversionRate: number;
  conversions: number;
  conversionValue: number;
  costPerAddToCart: number;
  costPerDetailPageView: number;
  costPerMille: number;
  detailPageViewRate: number;
  detailPageViews: number;
  facebookCostPerOutboundClick: number;
  facebookInteractions: number;
  facebookOutboundClickRate: number;
  facebookOutboundClicks: number;
  impressions: number;
  marketplaceClickRate: number;
  marketplaceClicks: number;
  marketplaceCostPerClick: number;
  ntbConversionRate: number;
  ntbConversions: number;
  ntbRevenue: number;
  ntbRevenueRate: number;
  ntbROAS: number;
  ntbUnitsSold: number;
  ntbUnitsSoldRate: number;
  roas: number;
  spend: number;
  unitsSold: number;
  // meta data
  accountID: string;
  adID: string;
  adSetID: string;
  campaignID: string;
  name: string;
  retailer: Retailer.Option;

  prev?: Omit<FacebookTableData, "key">;
};

// A table options object should be JSON serializable
export type FacebookTableOptions = {
  level?: string;
  expandedRows?: Array<string>;
  filters?: Array<{ column: string; args: FilterArguments }>;
  sort?: {
    column: string;
    direction: string;
  };
};

// Default table configuration
const defaultOptions: FacebookTableOptions = {
  level: "campaign"
};

// Local storage key for the table options
const tableOptionsKey = "facebookTableConfig";

// URL parameter name for the table options
const tableOptionsParam = "fb";

// The FB asset to use for the top level row in the table
type FbTableViewLevel = "campaign" | "adSet" | "ad";

const StyledFacebookTableRow = styled.tr<{
  isExpanded?: boolean;
  depth?: number;
}>`
  td {
    padding: 0 !important;

    > div {
      height: 2.5rem;
      margin: 0.25rem 0.5rem;

      p {
        line-height: 1rem;
        margin: 0;
      }
    }
  }

  td:first-child {
    z-index: 1;
  }

  td.name {
    z-index: 1;

    div {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }

    :hover {
      i {
        display: block;
      }
    }

    p {
      margin: 0 0 0 ${({ depth }) => depth ?? 0}rem;
    }

    i {
      display: ${({ isExpanded }) => (isExpanded ? "block" : "none")};
      cursor: pointer;
    }
  }
`;

const StyledFacebookTableFooter = styled(StyledFacebookTableRow)`
  td {
    z-index: 1;
    box-shadow: 0px -2px 0px 0px ${ampdRed} !important;
    background-color: white;
    font-weight: bold;

    // Name Column
    :nth-child(2) {
      left: 3rem;
      position: sticky;
      border-left: none;
      border-right: none;
      box-shadow: inset 1px 0px 0px 0px ${backgroundDark},
        inset -1px 0px 0px 0px ${backgroundDark}, 0px -2px 0px 0px ${ampdRed} !important;
    }
  }
`;

const FacebookTableRow = ({
  columns,
  data,
  isExpanded,
  depth,
  toggleExpandedRow
}: {
  data: FacebookTableData;
  columns: Array<Column<FacebookTableData>>;
  isExpanded: boolean;
  depth: number;
  toggleExpandedRow?: (key: string) => void | undefined;
}) => {
  const columnCells = columns.map(c => {
    switch (c.dataName) {
      case "key":
        return null;
      case COL_NAME.dataName:
        return (
          <td className={c.dataName} key={String(c.dataName)}>
            <div>
              <p>{data.name}</p>
              {toggleExpandedRow && (
                <Icon
                  aria-label="Expand row"
                  name="bars"
                  color="blue"
                  onClick={() => toggleExpandedRow(data.key)}
                />
              )}
            </div>
          </td>
        );
      default:
        return c.renderCell({
          uniqKey: String(c.dataName),
          val: data[c.dataName],
          prev: data.prev?.[c.dataName]
        });
    }
  });

  return (
    <StyledFacebookTableRow isExpanded={isExpanded} depth={depth}>
      <td>
        <input type="checkbox" />{" "}
      </td>
      {columnCells}
    </StyledFacebookTableRow>
  );
};

export function FacebookPageV2(): JSX.Element {
  const [tableOptions, setTableOptions] = useURLParamOrLocalStorageObject({
    localStorageKey: tableOptionsKey,
    urlParam: tableOptionsParam,
    defaultValue: defaultOptions
  });

  const [viewLevel, setViewLevel] = React.useState<FbTableViewLevel>(() => {
    if (
      tableOptions.level === "campaign" ||
      tableOptions.level === "adSet" ||
      tableOptions.level === "ad"
    ) {
      return tableOptions.level;
    }
    return "campaign";
  });

  const handleUpdateViewLevel = (level: FbTableViewLevel) => {
    setViewLevel(level);
    setTableOptions({ ...tableOptions, level });
  };

  const handleUpdateExpandedRows = (rows: Set<string>) => {
    setTableOptions({ ...tableOptions, expandedRows: [...rows] });
  };

  const handleUpdateSort = (sort: MetricsSort<FacebookTableData>): void => {
    setTableOptions({
      ...tableOptions,
      sort: {
        column: String(sort.column.dataName),
        direction: sort.direction
      }
    });
  };

  const handleUpdateFilters = (
    filters: Array<MetricsFilter<FacebookTableData>>
  ) => {
    setTableOptions({
      ...tableOptions,
      filters: filters.map(f => ({
        column: f.column.dataName,
        args: f.args
      }))
    });
  };

  const tableConfig = useMemo(() => {
    const config: TMetricsTable<FacebookTableData> = {
      columns: [
        COL_NAME,
        COL_ATC,
        COL_ATC_RATE,
        COL_CONVERSIONS,
        COL_CONVERSION_RATE,
        COL_CONVERSION_VALUE,
        COL_COST_PER_ADD_TO_CART,
        COL_COST_PER_DETAIL_PAGE_VIEW,
        COL_COST_PER_MILLE,
        COL_DETAIL_PAGE_VIEW_RATE,
        COL_DETAIL_PAGE_VIEWS,
        COL_FACEBOOK_COST_PER_OUTBOUND_CLICK,
        COL_FACEBOOK_INTERACTIONS,
        COL_FACEBOOK_OUTBOUND_CLICK_RATE,
        COL_FACEBOOK_OUTBOUND_CLICKS,
        COL_IMPRESSIONS,
        COL_MARKETPLACE_CLICK_RATE,
        COL_MARKETPLACE_CLICKS,
        COL_MARKETPLACE_COST_PER_CLICK,
        COL_NTB_CONVERSION_RATE,
        COL_NTB_CONVERSIONS,
        COL_NTB_REVENUE,
        COL_NTB_REVENUE_RATE,
        COL_NTB_ROAS,
        COL_NTB_UNITS_SOLD,
        COL_NTB_UNITS_SOLD_RATE,
        COL_ROAS,
        COL_SPEND,
        COL_UNITS_SOLD,
        COL_ACCOUNT_ID,
        COL_AD_ID,
        COL_AD_SET_ID,
        COL_CAMPAIGN_ID
      ],
      rows: fetchRows(testRows, true),
      mapDataRowsToTableRows: getDataRowToTableRowMapper(viewLevel),
      mapDataRowsToFooter: getDataRowToFooterMapper(viewLevel),
      tableOptions: {
        storageKey: tableOptionsKey,
        urlParam: tableOptionsParam,
        defaultValue: defaultOptions
      }
    };
    return config;
  }, [viewLevel]);

  const initialSort: MetricsSort<FacebookTableData> = {
    column: COL_NAME,
    direction: "asc"
  };
  const sortColumn = findColumnByString(
    tableOptions?.sort?.column,
    tableConfig.columns
  );
  if (sortColumn) {
    initialSort.column = sortColumn;
    initialSort.direction =
      tableOptions.sort?.direction === "asc" ? "asc" : "desc";
  }

  const initialFilters = getFiltersFromTableOptions(
    tableOptions.filters,
    tableConfig.columns
  );

  return (
    <Segment
      style={{
        height: "100%",
        overflowY: "hidden"
      }}
    >
      <div>
        <p
          style={{
            display: "block",
            margin: "0 0 0.3em 0.5em",
            fontWeight: "bold",
            fontSize: "small"
          }}
        >
          Level
        </p>
        <Dropdown
          style={{ marginBottom: "1rem" }}
          selection
          options={[
            { key: "campaign", text: "Campaign", value: "campaign" },
            { key: "adSet", text: "Ad Set", value: "adSet" },
            { key: "ad", text: "Ad", value: "ad" }
          ]}
          onChange={(e, { value }) => {
            if (!isFbTableViewLevel(value)) {
              return;
            }
            handleUpdateViewLevel(value);
          }}
          value={viewLevel}
        />
      </div>
      <ShowFractionsContext.Provider value={false}>
        <CompareMetricsLoadingContext.Provider value={false}>
          <MetricsTable<FacebookTableData>
            tableConfig={tableConfig}
            initialFilters={initialFilters}
            initialSort={initialSort}
            initialExpandedRows={new Set(tableOptions.expandedRows || [])}
            onToggleExpandedRows={handleUpdateExpandedRows}
            onUpdateSort={handleUpdateSort}
            onUpdateFilters={handleUpdateFilters}
          />
        </CompareMetricsLoadingContext.Provider>
      </ShowFractionsContext.Provider>
    </Segment>
  );
}

function findColumnByString(
  columnName: string | None,
  columns: Array<Column<FacebookTableData>>
): Column<FacebookTableData> | undefined {
  if (!columnName) {
    return;
  }
  return columns.find(c => c.dataName === columnName);
}

function getFiltersFromTableOptions(
  filters: Array<{ column: string; args: FilterArguments }> | None,
  columns: Array<Column<FacebookTableData>>
): Array<MetricsFilter<FacebookTableData>> {
  if (!filters || !filters.length) {
    return [];
  }

  return filters
    .map(filter => {
      const { column: columnName, args } = filter;
      const column = findColumnByString(columnName, columns);
      if (!column) {
        return;
      }
      return {
        column,
        args
      };
    })
    .filter(removeNullAndUndefined);
}

function isFbTableViewLevel(level: unknown): level is FbTableViewLevel {
  return level === "campaign" || level === "adSet" || level === "ad";
}
// Gets the correct mapper function based on the view level. The mapping changes
// based on the view level by showing the selected view level as the primary row
// and the children of the selected view level as 2nd or 3rd nested level.
const getDataRowToTableRowMapper = (
  viewLevel: FbTableViewLevel
): TMetricsTable<FacebookTableData>["mapDataRowsToTableRows"] => {
  return (
    rows: Array<FacebookTableData>,
    columns: Array<Column<FacebookTableData>>,
    expandedRows: Set<string>,
    toggleExpandedRow: (key: string) => void
  ): Array<JSX.Element> => {
    const campaignToAdSets = new Map<
      string,
      { row: FacebookTableData; children: Array<FacebookTableData> }
    >();
    const adSetToAds = new Map<
      string,
      { row: FacebookTableData; children: Array<FacebookTableData> }
    >();
    const ads = new Map<
      string,
      { row: FacebookTableData; children: Array<FacebookTableData> }
    >();

    rows.forEach(row => {
      if (row.campaignID && !row.adSetID && !row.adID) {
        campaignToAdSets.set(row.campaignID, {
          row,
          children: []
        });
      }
    });

    rows.forEach(row => {
      if (row.adSetID && !row.adID) {
        adSetToAds.set(row.adSetID, {
          row,
          children: []
        });

        const parentRow = campaignToAdSets.get(row.campaignID);
        if (parentRow) {
          parentRow.children.push(row);
        }
      }
    });

    rows.forEach(row => {
      if (row.adID) {
        ads.set(row.adID, {
          row,
          children: []
        });
        const parentRow = adSetToAds.get(row.adSetID);
        if (parentRow) {
          parentRow.children.push(row);
        }
      }
    });

    let topLevel = campaignToAdSets;
    switch (viewLevel) {
      case "campaign":
        topLevel = campaignToAdSets;
        break;
      case "adSet":
        topLevel = adSetToAds;
        break;
      case "ad":
        topLevel = ads;
        break;
    }

    return [...topLevel.values()].map(
      ({ row: topLevelRow, children: secondLevelRows }) => {
        return (
          <Table.Body key={topLevelRow.key}>
            <FacebookTableRow
              depth={0}
              toggleExpandedRow={
                secondLevelRows.length ? toggleExpandedRow : undefined
              }
              data={topLevelRow}
              columns={columns}
              isExpanded={expandedRows.has(topLevelRow.key)}
            />
            {expandedRows.has(topLevelRow.key) &&
              secondLevelRows.map(secondLevelRow => {
                return (
                  <>
                    <FacebookTableRow
                      depth={1}
                      key={secondLevelRow.key}
                      toggleExpandedRow={
                        secondLevelRows.length ? toggleExpandedRow : undefined
                      }
                      data={secondLevelRow}
                      columns={columns}
                      isExpanded={expandedRows.has(secondLevelRow.key)}
                    />
                    {expandedRows.has(secondLevelRow.key) &&
                      adSetToAds
                        .get(secondLevelRow.adSetID)
                        ?.children.map(thirdLevelRow => {
                          return (
                            <FacebookTableRow
                              depth={2}
                              key={thirdLevelRow.key}
                              data={thirdLevelRow}
                              columns={columns}
                              isExpanded={false}
                            />
                          );
                        })}
                  </>
                );
              })}
          </Table.Body>
        );
      }
    );
  };
};

// Gets the correct footer based on the view level. We only want to display the
// totals of the selected view level. ex: If campaign is selected we may display
// all the ad sets under a campaign, but we only want to display the total of
// the campaigns.
const getDataRowToFooterMapper = (
  viewLevel: FbTableViewLevel
): TMetricsTable<FacebookTableData>["mapDataRowsToFooter"] => {
  return (
    rows: Array<FacebookTableData>,
    columns: Array<Column<FacebookTableData>>
  ): JSX.Element => {
    let title = "";
    if (viewLevel === "campaign") {
      rows = rows.filter(row => !row.adSetID && !row.adID);
      title = "Campaigns";
    } else if (viewLevel === "adSet") {
      rows = rows.filter(row => row.adSetID && !row.adID);
      title = "Ad Sets";
    } else {
      rows = rows.filter(row => row.adID);
      title = "Ads";
    }

    const prevRows = rows
      .map(row => {
        if (!row.prev) {
          return;
        }
        return { key: row.key, ...row.prev };
      })
      .filter(removeNullAndUndefined);

    return (
      <Table.Footer>
        <StyledFacebookTableFooter>
          <td>
            <input type="checkbox" />
          </td>
          {columns.map(column => {
            switch (column.dataName) {
              case "name":
                return (
                  <Table.Cell className="name" key={String(column.dataName)}>
                    <div>
                      Total ({rows.length} {title})
                    </div>
                  </Table.Cell>
                );
              default:
                return column.renderCell({
                  uniqKey: String(column.dataName),
                  val: column.totalFn ? column.totalFn(rows) : "",
                  prev: column.totalFn ? column.totalFn(prevRows) : ""
                });
            }
          })}
        </StyledFacebookTableFooter>
      </Table.Footer>
    );
  };
};
